<template>
  <div class="feedinfo">
    <loading :active.sync="modalAction"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal ref="image-editor-modal" size="lg" hide-footer title="Edit image">
      <ImageEdit ref='image-editor'
        :inputImage='imageEditor.image'
        :maxSize='imageEditor.maxSize'
        :thumb64='imageEditor.thumb64'
        :color='imageEditor.color'
        :stencil='imageEditor.stencil'
        @done='saveImage(imageEditor.tag, $event)'
        @cancel='$refs["image-editor-modal"].hide()'/>
    </b-modal>
    <b-modal ref="branch-io-war" hide-footer title="Action required">
      <div class="d-block text-center">
        In order to clear this link you have to
        manually find and delete your link at <a target='_blank' href='https://dashboard.branch.io/quick-links'>Branch.io Dashboard</a> first
      </div>
      <div class="d-block text-center">
        <b-button class="mt-2 text-center" variant="secondary" @click="$refs['branch-io-war'].hide()">Got it</b-button>
      </div>
    </b-modal>
    <b-modal ref="invite-args-edit" hide-footer title="Update Invite Args">
      <b-form-group
        label-cols="2"
        description="Invite Arguments"
        label="Args">
        <b-input-group>
           <b-form-input
                v-model="argsEditModel"
                placeholder="">
                </b-form-input>
           <b-input-group-append>
              <b-button variant="warning" size='sm'  @click.prevent='argsEditModel="podcasts"'>Podcasts</b-button>
              <b-button variant="secondary" size='sm'  @click.prevent='argsEditModel=""'>Clear</b-button>
            </b-input-group-append>
          </b-input-group>
      </b-form-group>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="success" @click="updateInviteArgs">Update</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click="$refs['invite-args-edit'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>

    <b-modal ref="feed-alias-confirm" hide-footer title="Confirm create alias">
      <b-form-group
        label-cols="2"
        description="It is short name for link (eg: http://example.com/alias)"
        label="Alias">
         <b-form-input
              v-model="aliasEditModel"
              placeholder="alias"></b-form-input>
      </b-form-group>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="success" :disabled='!aliasEditModel' @click="confirmAliasFeed">Create</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click="hideAliasFeed">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="feed-delete-confirm" hide-footer title="Confirm deletion">
      <div class="d-block text-center">
        <h4>Are you sure to delete the feed (this action cannot be undone)?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmDeleteFeed">Delete</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="hideDeleteFeed">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-card
        :header-bg-variant="isNew ? 'success' : edited ? 'warning' : 'primary'"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ isNew ? 'New Feed' : 'Feed' }}</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button v-if='edited' variant='danger' size='sm' @click='reset'>Reset changes</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-tabs v-if='feed && feed.id && !loading' card>
        <b-tab title="Info" active>
          <b-form>
            <b-form-group
              label-cols="2"
              label="Identifier"
              label-for="feed-id">
              <b-form-input
                id="feed-id"
                v-model="feed.id"
                :disabled='true'
                required
                placeholder="Enter feed id"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Admin Id"
              label-for="feed-id">
              <b-input-group>
                <b-form-input
                  id="feed-admin-id"
                  v-model="feed.adminId"
                  :disabled='true'
                  required
                  placeholder="Enter adminId"></b-form-input>
                <!--<b-input-group-append>
                  <b-button variant="warning" @click.prevent='feedTransfer'>Transfer</b-button>
                </b-input-group-append>-->
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Post, Following, Followers"
              label-for="feed-counters">
              <b-input-group id="feed-counters">
                <b-form-input
                  v-model="feed.posts"
                  :disabled='true'
                  required
                  placeholder="Posts"></b-form-input>
                <b-form-input
                  v-model="feed.following"
                  :disabled='true'
                  required
                  placeholder="Following"></b-form-input>
                <b-form-input
                  v-model="feed.followers"
                  :disabled='true'
                  required
                  placeholder="Followers"></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Forums: Categories, Forums, Members"
              label-for="feed-counters">
              <b-input-group id="feed-counters">
                <b-form-input
                  v-model="feed.forumCategories"
                  :disabled='true'
                  required
                  placeholder="Categories"></b-form-input>
                <b-form-input
                  v-model="feed.forumCount"
                  :disabled='true'
                  required
                  placeholder="Count"></b-form-input>
                <b-form-input
                  v-model="feed.forumMembers"
                  :disabled='true'
                  required
                  placeholder="Members"></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Created at"
              label-for="feed-id">
              <b-form-input
                id="feed-created"
                v-model="feed.createdAt"
                :disabled='true'
                placeholder="Created time"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Name"
              label-cols="2"
              label-for="feed-name">
              <b-form-input
                id="feed-name"
                v-model="feed.name"
                @input='editMode()'
                required
                placeholder="Enter feed name"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="feed-description">
              <b-form-textarea
                id="feed-description"
                v-model="feed.bio"
                @input='editMode()'
                placeholder="Description of feed"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              label="Feed Web Url"
              label-cols="2"
              label-for="feed-url">
              <b-form-input
                id="feed-url"
                @input='editMode()'
                v-model="feed.feedUrl"
                placeholder="Enter feed url"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Feed RSS link"
              label-cols="2"
              label-for="feed-rss">
              <b-form-input
                id="feed-rss"
                @input='editMode()'
                v-model="feed.rssLink"
                placeholder="Enter feed rss"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Feed Disclaimer link"
              label-cols="2"
              label-for="feed-disclaimer">
              <b-form-input
                id="feed-disclaimer"
                @input='editMode()'
                v-model="feed.disclaimer"
                placeholder="Enter feed disclaimer url"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Invite Link"
              label-for="feed-invite-link">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button v-if='feed.inviteLink' variant="success" @click.prevent='inviteUpdate()'>Update</b-button>
                </b-input-group-prepend>
                <b-form-input
                  id="feed-invite"
                  v-model="feed.inviteLink"
                  :disabled='true'
                  placeholder="Invite link is disabled">
                </b-form-input>
                <b-input-group-append>
                  <b-button v-if='feed.inviteLink' variant="warning" @click.prevent='inviteReset()'>Reset</b-button>
                  <b-button v-if='feed.inviteLink' variant="danger" @click.prevent='inviteDelete()'>Disable</b-button>
                  <b-button v-if='!feed.inviteLink' variant="success" @click.prevent='inviteCreate()'>Create</b-button>
                  <b-button v-if='feed.inviteId' variant="secondary" @click.prevent='inviteArgs()'>Args</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Alias Link"
              label-for="feed-alias-link">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button v-if='feed.aliasLink' variant="success" @click.prevent='aliasUpdate()'>Update</b-button>
                </b-input-group-prepend>
                <b-form-input
                  id="feed-alias"
                  v-model="feed.aliasLink"
                  :disabled='true'
                  placeholder="Alias link is disabled">
                </b-form-input>
                <b-input-group-append>
                  <b-button v-if='feed.aliasLink' variant="danger" @click.prevent='askUnAliasFeed()'>Clear</b-button>
                  <b-button v-if='!feed.aliasLink' variant="warning" @click.prevent='askAliasFeed()'>Create</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="Feed Options"
              label-cols="2">
              <b-form-checkbox
                id="feed-verified"
                @change='editMode()'
                v-model="feed.isVerified"
                name="feed-verified"
                :value="true"
                :unchecked-value="false" inline>
                Is Verified
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-claim"
                @change='editMode()'
                v-model="feed.allowClaim"
                name="feed-claim"
                :value="true"
                :unchecked-value="false" inline>
                Allow Claim
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-forums"
                @change='editMode()'
                v-model="feed.allowFeedForums"
                name="feed-forums"
                :value="true"
                :unchecked-value="false" inline>
                Allow Forums
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-direct"
                @change='editMode()'
                v-model="feed.allowDirect"
                name="feed-direct"
                :value="true"
                :unchecked-value="false" inline>
                Allow Direct
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-hide-empty"
                @change='editMode()'
                v-model="feed.algoliaHideEmpty"
                name="feed-hide-empty"
                :value="true"
                :unchecked-value="false" inline>
                Hide from empty search
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-notify-system"
                @change='editMode()'
                v-model="feed.notifySystem"
                name="feed-hide-empty"
                :value="true"
                :unchecked-value="false" inline>
                Notify if system/api posts
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-notify-dont-group"
                @change='editMode()'
                v-model="feed.notificationDontGroup"
                name="feed-hide-empty"
                :value="true"
                :unchecked-value="false" inline>
                Don't group notifications
              </b-form-checkbox>
              <b-form-checkbox
                id="feed-podcast-group"
                @change='editMode()'
                v-model="feed.allowPodcastCategories"
                name="feed-hide-empty"
                :value="true"
                :unchecked-value="false" inline>
                Allow podcast categories
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab title="Appearance">
          <b-card
            title="Group image"
            sub-title="Select group image for feed">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='image' blank-color="#eee" :blank='!image' blank-width='200' blank-height='200' width='200' height='200' rounded="circle" alt="Group image"></b-img>
            </div>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='image' size='sm' variant="danger" @click.prevent='removeImage("image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
          <b-card
            title="Header Background image"
            sub-title="Select header background image for feed">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='header_background_image' blank-color="#eee" :blank='!header_background_image' blank-width='250' width='250' alt="Header Background image"></b-img>
            </div>
            <b-form-group label="Text color" v-if='feed.palette'>
              <b-form-radio-group
                id="headerBackgroundText"
                @input='editMode()'
                v-model="feed.palette.headerTextColorDark"
                :options="textColorOptions"
                buttons
              ></b-form-radio-group>
            </b-form-group>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("header_background_image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='header_background_image' size='sm' variant="danger" @click.prevent='removeImage("header_background_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
          <b-card
            title="Background image"
            sub-title="Select background image for feed">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='background_image' blank-color="#eee" :blank='!background_image' blank-width='250' width='250' alt="Background image"></b-img>
            </div>
            <b-form-group label="Text color" v-if='feed.palette'>
              <b-form-radio-group
                id="backgroundText"
                @input='editMode()'
                v-model="feed.palette.textColorDark"
                :options="textColorOptions"
                buttons
                name="radios-btn-default"
              ></b-form-radio-group>
            </b-form-group>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("background_image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='background_image' size='sm' variant="danger" @click.prevent='removeImage("background_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>

        </b-tab>
      </b-tabs>
      <b-card-body v-else-if='!loading'>
        <b-alert variant="danger" v-if='feedId && !loadError' show>Feed does not exist</b-alert>
        <b-alert variant="danger" v-else-if='feedId && loadError' show>Error loading feed {{loadError}}</b-alert>
        <b-alert v-else show>Please, select the feed</b-alert>
      </b-card-body>
      <b-card-footer  v-if='feed && feed.id && !loading'>
        <b-row>
          <b-col cols="auto" class="mr-auto">
            <b-button v-if='isNew' :disabled='!canSave' size='sm' variant="success" @click.prevent='create'>Create</b-button>
            <b-button v-else-if='edited' :disabled='!canSave' size='sm' class='mr-1' variant="success" @click.prevent='save'>Save changes</b-button>
            <b-button if='!isNew' size='sm' variant="secondary" class='ml-1' target='_blank' :to="{path: `/messaging/feed/${feedId}`}">Show Messages</b-button>
          </b-col>
          <b-col cols="auto" align-self="end">

            <b-button v-if='!isNew' variant='danger' size='sm' @click='askConfirmDeleteFeed'>Delete the feed</b-button>
          </b-col>
        </b-row>

      </b-card-footer>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  import ImageEdit from '@/components/ImageEdit.vue'
  import EventBus from '@/event-bus.js'
  import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  const processUploads = async (id, uploads, save) => {
    let ps = Object.keys(uploads).map(async k => {
      const upload = uploads[k]
      if (upload.image) {
        const buff = Buffer.from(upload.image.replace(/^data:image\/\w+;base64,/, ""),'base64')
        const status = await api.uploadMedia(k, new Blob([buff]), upload.info.file.type)
        save[k] = status.id
      } else {
        save[k] = null
      }
      Object.assign(save, upload.updates)
    })
    await Promise.all(ps)
  }

  export default {
    name: 'FeedInfo',
    props: {
      feedId: String
    },
    data() {
      return {
        argsEditModel: '',
        aliasEditModel: '',
        modalAction:false,
        currentId:null,
        loadError:null,
        feed: {},
        uploads: {},
        imageEditor: {
          tag:null,
          image:null,
          stencil:'rectangle',
          maxSize: 1024,
          thumb64: 0,
          color:false
        },
        edited: false,
        loading: false,
        isNew: false,
        textColorOptions: [
          { text: 'Auto', value: null },
          { text: 'White text', value: false },
          { text: 'Black text', value: true }
        ]
      }
    },
    watch: {
      feedId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.edited = false
          this.isNew = false
          this.feed = {}
          this.bind(id)
        }
      }
    },
    methods: {
      feedTransfer() {

      },
      inviteDeletePrivate: async function() {
        // let invites = await firebase.database().ref('invites').orderByChild('channelId')
        //                                                         .equalTo(`${this.feedId}`).once('value')
        // let updates = {}
        // invites.forEach(snap => {
        //   updates[snap.key] = null
        // })
        // await firebase.database().ref('invites').update(updates)
        // await firebase.database().ref('feed').child(this.feedId).update({
        //   'inviteId': null,
        //   'inviteLink': null
        // })
      },
      inviteUpdatePrivate: async function() {
        // let inviteId = this.feed.inviteId
        // if (!inviteId) return
        // const inviteLink = await links.shortLink(`invite/${inviteId}`, this.socialInfo, true)
        // await firebase.database().ref('feed').child(this.feedId).update({
        //   'inviteId': inviteId,
        //   'inviteLink': inviteLink
        // })
      },

      updateInviteArgs: async function() {
        this.$refs['invite-args-edit'].hide()
        let inviteId = this.feed.inviteId
        if (!inviteId) return
        this.modalAction = true
        try {
          // await firebase.database().ref('invites').child(inviteId).child('args').set(String(this.argsEditModel))
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },

      inviteCreatePrivate: async function() {
        // let inviteId = uuid()
        // await firebase.database().ref('invites').child(inviteId)
        //     .set({
        //       'id': inviteId,
        //       'channelRef': 'feed',
        //       'ts': firebase.database.ServerValue.TIMESTAMP,
        //       'channelId': this.feedId
        //     })
        // const inviteLink = await links.shortLink(`invite/${inviteId}`, this.socialInfo, true)
        // await firebase.database().ref('feed').child(this.feedId).update({
        //   'inviteId': inviteId,
        //   'inviteLink': inviteLink
        // })
      },

      aliasUpdate: async function() {
        let aliasLink = this.feed.aliasLink
        if (!aliasLink) return
        this.modalAction = true
        try {
          await links.updateLinkBranch(aliasLink, `feed/${this.feedId}`, this.socialInfo, true)
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },

      askUnAliasFeed: async function() {
        let aliasLink = this.feed.aliasLink
        if (!aliasLink) return
        try {
          const exists = await links.checkLinkBranch(aliasLink)
          if (!exists) {
            // await firebase.database().ref('feed').child(this.feedId).update({
            //   'aliasLink': null
            // })
          } else {
            this.$refs['branch-io-war'].show()
          }
        } catch(e) {
          console.error(e)
        }
      },
      askAliasFeed() {
        let aliasLink = this.feed.aliasLink
        if (aliasLink) return

        this.aliasEditModel = ''
        this.$refs['feed-alias-confirm'].show()
      },
      hideAliasFeed() {
        this.$refs['feed-alias-confirm'].hide()
      },
      confirmAliasFeed: async function() {
        this.$refs['feed-alias-confirm'].hide()
        this.modalAction = true
        try {
          // await this.aliasCreatePrivate(this.aliasEditModel)
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false

      },

      // aliasCreatePrivate: async function(aliasName) {
      //   const newAliasLink = await links.shortLink(`feed/${this.feedId}`, this.socialInfo, true, aliasName)
      //   await firebase.database().ref('feed').child(this.feedId).update({
      //     'aliasLink': newAliasLink
      //   })
      // },

      inviteArgs: async function() {
        let inviteId = this.feed.inviteId
        if (!inviteId) return

        this.modalAction = true
        try {
          // this.argsEditModel = (await firebase.database().ref('invites').child(inviteId).child('args').once('value')).val() || ""
          this.$refs['invite-args-edit'].show()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },

      inviteDelete: async function() {
        this.modalAction = true
        try {
          await this.inviteDeletePrivate()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },
      inviteReset: async function() {
        this.modalAction = true
        try {
          await this.inviteDeletePrivate()
          await this.inviteCreatePrivate()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },
      inviteUpdate: async function() {
        this.modalAction = true
        try {
          await this.inviteUpdatePrivate()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },
      inviteCreate: async function() {
        await this.inviteReset()
      },
      async saveImage(tag, result) {
        if (tag === 'image') {
          this.$set(this.uploads, 'image', {
            image: result.image,
            updates: {
              thumb64: result.thumb64
            },
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          this.edited = !this.isNew
        } else if (tag === "background_image") {
          this.feed.palette = (this.feed.palette || {})
          this.feed.palette.background = {
            r: result.rgb[0],
            g: result.rgb[1],
            b: result.rgb[2]
          }
          this.feed.palette.textColorDark = result.xyz[1]/100.0 > 0.35
          this.$set(this.uploads, 'background_image', {
            image: result.image,
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          this.edited = !this.isNew
        } else if (tag === "header_background_image") {
          this.feed.palette = (this.feed.palette || {})
          this.feed.palette.headerBackground = {
            r: result.rgb[0],
            g: result.rgb[1],
            b: result.rgb[2]
          }
          this.feed.palette.headerTextColorDark = result.xyz[1]/100.0 > 0.35
          this.$set(this.uploads, 'header_background_image', {
            image: result.image,
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          this.edited = !this.isNew
        }
        this.$refs["image-editor-modal"].hide()
      },
      removeImage(tag) {
        if (tag === 'image') {
          this.$set(this.uploads, 'image', {
            image: null,
            updates: {
              thumb64: null
            },
            info: null
          })
          this.edited = !this.isNew && !!this.feed.image
        } else if (tag === 'background_image') {
          if (this.feed.palette) {
              this.feed.palette.background = null
              this.feed.palette.textColorDark = null
              if (!this.feed.palette.headerBackground) {
                this.feed.palette = null
              }
          }

          this.$set(this.uploads, 'background_image', {
            image: null,
            info: null
          })
          this.edited = !this.isNew && !!this.feed.background_image
        } else if (tag === 'header_background_image') {
          if (this.feed.palette) {
              this.feed.palette.headerBackground = null
              this.feed.palette.headerTextColorDark = null
              if (!this.feed.palette.background) {
                this.feed.palette = null
              }
          }
          this.$set(this.uploads, 'header_background_image', {
            image: null,
            info: null
          })
          this.edited = !this.isNew && !!this.feed.header_background_image
        }
        this.$refs["image-editor-modal"].hide()
      },
      editImage(tag) {
        this.imageEditor.tag = tag
        this.imageEditor.thumb64 = 0
        this.imageEditor.color = false
        if (tag === 'image') {
          this.imageEditor.stencil = 'circle'
          this.imageEditor.maxSize = 512
          this.imageEditor.thumb64 = 15
          this.imageEditor.image = this.image
          this.$refs["image-editor-modal"].show()
        } else if (tag === 'background_image') {
          this.imageEditor.stencil = 'rectangle'
          this.imageEditor.maxSize = 2048
          this.imageEditor.color = true
          this.imageEditor.image = this.background_image
          this.$refs["image-editor-modal"].show()
        } else if (tag === 'header_background_image') {
          this.imageEditor.stencil = 'rectangle'
          this.imageEditor.maxSize = 2048
          this.imageEditor.color = true
          this.imageEditor.image = this.header_background_image
          this.$refs["image-editor-modal"].show()
        }
      },
      askConfirmDeleteFeed() {
        this.$refs['feed-delete-confirm'].show()
      },
      hideDeleteFeed() {
        this.$refs['feed-delete-confirm'].hide()
      },
      confirmDeleteFeed: async function() {
        this.$refs['feed-delete-confirm'].hide()
        this.loading = true
        try {
          await api.call('api/feed/delete', {
            id: this.feedId,
          })
        } catch(e) {
          //Ok
        }
        this.loading = false
        EventBus.$emit('feedChanged', this.feedId)
        this.$emit('update:feedId', null)
      },
      editMode() {
        if (this.loading || this.isNew) {
          return
        }
        this.edited = true
        this.bind(null)
      },
      create: async function() {
        this.loading = true
        this.feed.createdAt = moment().utc().format(`YYYY-MM-DD[T]HH:mm:ss.SSSZZ`)
        if (!this.feed.disclaimer) {
          this.feed.disclaimer = null
        }
        try {
          await processUploads(this.feed.id, this.uploads, this.feed)
          const dataToCreate = {
            id: this.feed.id,
            name: this.feed.name,
            description: this.feed.bio,
            feed_url: this.feed.feedUrl,
            rss_link: this.feed.rssLink,
            disclaimer: this.feed,
            adminId: 0, // firebase.auth().currentUser.uid,
            platform: 'admin-panel'
          }
          const { data } = await api.call('api/feed/create', {
            data: dataToCreate
          })
          const savedFeed = data.data;
          this.setFeed(savedFeed);
        } catch(e) {
          //Ok
        }
        this.loading = false
        this.$emit('update:feedId', this.feed.id)
        EventBus.$emit('feedChanged', this.feed.id)
      },
      save: async function() {
        let updates = {}
        this.edited = false
        updates['name']    = this.feed.name || ''
        updates['description'] = this.feed.bio  || ''
        updates['feed_url'] = this.feed.feedUrl || ''
        updates['rss_link'] = this.feed.rssLink || ''
        updates['palette'] = this.feed.palette || null
        // cannot update
        // updates['disclaimer'] = this.feed.disclaimer || null
        // updates['isVerified'] = this.feed.isVerified || false
        // updates['allowClaim'] = this.feed.allowClaim || false
        // updates['allowFeedForums'] = this.feed.allowFeedForums || false
        // updates['allowDirect'] = this.feed.allowDirect || false
        // updates['algoliaHideEmpty'] = this.feed.algoliaHideEmpty || false
        // updates['notifySystem'] = this.feed.notifySystem || false
        // updates['notificationDontGroup'] = this.feed.notificationDontGroup || false
        // updates['allowPodcastCategories'] = this.feed.allowPodcastCategories || false
        this.loading = true
        //console.log(updates)
        try {
          const photos = {};
          await processUploads(this.feedId, this.uploads, photos)
          if (photos.image) {
            updates.image = photos.image
          }
          if (photos.background_image) {
            updates.background_image = photos.background_image
          }
          if (photos.header_background_image) {
            updates.header_background_image = photos.header_background_image
          }
          const { data } = await api.call('api/feed/update', {
            id: this.feedId,
            update_fields: updates
          })
          const savedFeed = data.data;
          this.setFeed(savedFeed)
        } catch(e) {
          console.log(e)
          //Ok
        }
        if (this.feed.adminId) {
          try {
            // await firebase.database().ref('users').child(this.feed.adminId).update({
            //   feedName: updates['name'],
            //   feedImage: updates['image'],
            //   feedThumb64: updates['thumb64']
            // })
          } catch(e) {
            console.log(e)
            //Ok
          }
        }
        this.loading = false
        EventBus.$emit('feedChanged', this.feedId)
        this.reset()
      },
      setFeed(savedFeed) {
        this.feed = {
          id: savedFeed ? savedFeed.id : uuid(),
          name: savedFeed ? savedFeed.name : '',
          bio: savedFeed ? savedFeed.description : '',
          feedUrl: savedFeed ? savedFeed.feed_url : '',
          rssLink: savedFeed ? savedFeed.rss_link : '',
          disclaimer: '',
          adminId: 0, // firebase.auth().currentUser.uid,
          platform: 'admin-panel',
          image: savedFeed ? savedFeed.image : '',
          background_image: savedFeed ? savedFeed.background_image : '',
          thumb64: '',
          followers: 0,
          following: 0,
          posts: 0,
          forumMembers: 0,
          forumCount: 0,
          forumCategories: 0,
          isVerified: false,
          allowClaim: true,
          allowFeedForums: true,
          allowDirect: false,
          algoliaHideEmpty: false,
          notifySystem: false,
          notificationDontGroup: false,
          allowPodcastCategories: false
        }
      },
      reset() {
        this.edited = false
        this.uploads = {}
        this.bind(this.feedId)
      },
      bind: async function(id) {
        this.loadError = null
        this.currentId = id
        if (id === '$//newFeed') {
          try {
            await this.$rtdbUnbind('feed', () => ({}))
          } catch(e) {
            //Ok
          }
          this.isNew = true
          this.setFeed()
          return
        }
        if (id) {
          if (this.loading) return
          this.loading = true
          try {
            // this.feed = await api.call('api/feed/get', { id });
            const { data } = await api.call('api/feed/get', { id });
            const savedFeed = data.data
            this.setFeed(savedFeed)
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          }
          this.loading = false
          if (this.currentId !== id) {
            this.bind(this.currentId)
          }
        } else {
          try {
            if (this.edited) {
              await this.$rtdbUnbind('feed', false)
            } else {
              await this.$rtdbUnbind('feed', () => ({}))
            }
          } catch(e) {
            //Ok
          }
        }
      }
    },
    computed: {
      socialInfo() {
        return this.feed && {
          title: this.feed.name,
          description: this.feed.bio,
          image: this.feed.image,
          background: this.feed.background_image,
          header_background: this.feed.header_background_image,
          channel_type: 'feed'
        }
      },
      canSave() {
        return this.feed && this.feed.name
      },
      image() {
        if (this.uploads['image']) {
          return this.uploads['image'].image
        }
        return this.feed.image ? api.imageURL(this.feed.image) : this.feed.image
      },
      background_image() {
        if (this.uploads['background_image']) {
          return this.uploads['background_image'].image
        }
        return this.feed.background_image ? api.imageURL(this.feed.background_image) : this.feed.background_image
      },
      header_background_image() {
        if (this.uploads['header_background_image']) {
          return this.uploads['header_background_image'].image
        }
        return this.feed.header_background_image ? api.imageURL(this.feed.header_background_image) : this.feed.header_background_image
      }
    },
    components: {
      Loading, ImageEdit
    }
  }
</script>
