import axios from 'axios'
import CryptoJS from "crypto-js"
import EventBus from "./event-bus"
import router from './router'

const TARGET_NAME = process.env.VUE_APP_TARGET_NAME || 'shmuzy';
const backendConfig = require(`./configs/${TARGET_NAME}.js`).default.backend
console.log('TARGET_NAME: ', TARGET_NAME)
//console.log('backendConfig: ', JSON.stringify(backendConfig))

const http = axios.create({
  baseURL: backendConfig.apiUrl
})

http.interceptors.request.use(
  function(config) {
    const token = EventBus.token
    if (typeof token === 'string') {
      config.headers["Authorization"] = `Bearer ${token}`
    } else if (typeof token === 'object' && token && token.token) {
      config.headers["Authorization"] = `Bearer ${token.token}`
    }

    config.headers["Content-Type"] = "application/json"

    return config
  },
  function(err) {
    return Promise.reject(err)
  }
);

http.interceptors.response.use(
  function(response) {

    // console.log('RESPONSE: ', response)

    if (response && response.status === 200) {
      return response
    }

    throw new Error(response.statusText)
  },
  function(err) {
    if (err.response === undefined) {
      return Promise.reject(err)
    }
    switch (err.response.status) {
      case 401:
      case 403:
        logout()
        break
    }
    err.code = err.response.status || 500
    return Promise.reject(err)
  }
)

const calculateMd5 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(blob);
    reader.onloadend = function () {
      var hash = CryptoJS.MD5(reader.result).toString();
      resolve(hash);
    };
    reader.onerror = function (error) {
      console.log(error)
      reject(error);
    }
  })
}

// EXPORTED FUNCTIONS

const login = async (email, password) => {
  const response = await call('admin/login', {
    email: email,
    password: password
  })
  //response = {token, user }
  
  //EventBus.user = response.data.user
  //EventBus.token = response.data.token
  const {user, token} = response.data;
  console.log('user', user)
  if (user) {
    if (!user.groups) {
      user.groups = []
    }
    try {
      const groups = token.split('.')[1]
      //const groupsJson = JSON.parse(Buffer.from(groups, 'base64').toString('ascii'))
      console.log('groups', groups)
      const groupsJson = JSON.parse(atob(groups))
      console.log('groupsJson', groupsJson)
      user.groups = groupsJson?.data?.groups ? groupsJson.data.groups.split(',') : []
      console.log('user.groups', user.groups)
    } catch (e) {
      console.log('error parsing jwt token', e)
      user.groups = []
    }
    if (user.superadmin && !user.groups.includes('superadmin')) {
      user.groups.push('superadmin')
    }
  }
  EventBus.user = user
  EventBus.token = token
  //console.log(`Logged in as ${response.data.user} and token is ${response.data.token}`)
  console.log(`Logged in as ${JSON.stringify(user)} and token is ${token}`)
  router.replace('/')
}

const logout = () => {
  EventBus.user = null
  EventBus.token = null
  if (router.currentRoute.name !== 'login') {
    router.replace('/login')
  }
}

const call = async(path, args) => {
  if (!args) {
    args = {}
  }
  return http.post(path, args)
}

const uploadMedia = async (target, blob, mimeType) => {
  const res = await http.post('api/upload/request', {
    content_length: blob.size,
    content_md5: await calculateMd5(blob),
    content_type: mimeType,
    target: target,
    accelerated: true
  });
  if (!res?.data?.upload_id) {
    throw new Error('Upload failed');
  }
  if (res.data.signed_url) {
    const res2 = await fetch(res.data.signed_url, {
      method: 'PUT',
      body: blob,
      headers: {
        'Content-Type': mimeType,
        'Content-Length': blob.size
      }
    });
    if (!res2.ok) {
      throw new Error(`Upload failed with status ${res2.status}`);
    }
    return await checkStatusWithRetry(res.data.upload_id);
  } else if (res.data.data) {
    return res.data.data;
  }
  throw new Error('Upload failed');
}

const checkStatusWithRetry = async (uploadId, retries = 15, interval = 1000) => {
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const status = await http.post('api/upload/status', { id: uploadId });

      if (status?.data.is_processed) {
        return status?.data;
      } else if (status?.data.error) {
        throw new Error(status?.data.error);
      }

      // Double the interval for the next attempt, using exponential backoff
      interval *= 2;
      await sleep(interval);

    } catch (err) {
      if (attempt === retries - 1) {
        // If this was the last attempt, reject the promise
        throw err;
      }

      // Double the interval for the next attempt, using exponential backoff
      interval *= 2;
      await sleep(interval);
    }
  }
  throw new Error("Max retries reached");
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}



const buildURL = (data, field) => {
  if (!data) {
    return null
  }
  //check if image is a string
  if (typeof data === 'string') {
    if (data.startsWith('http')) {
      return data
    }
    return null
  }
  //check if image is an object, and then build aws url, from bucket, region and image properties
  if (typeof data === 'object' && data[field] && data.bucket && data.region) {
    const val = data[field]
    return `https://${data.bucket}.s3.${data.region}.amazonaws.com/${val}`
  }
  return null
}

const imageURL = (image) => {
  return buildURL(image, 'image')
}

export default {
  login: login,
  logout: logout,
  call: call,
  uploadMedia: uploadMedia,
  imageURL: imageURL,
  buildURL: buildURL
}
