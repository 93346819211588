<template>
  <div class="stats">
    <loading
      :active.sync="modalLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <b-container class="mt-2" fluid>
      <div class="query-controller mb-3">
        <b-form inline>
          <label class="mr-2">Start Date:</label>
          <b-form-datepicker v-model="startDate" class="mb-2 mr-sm-2 datepicker"></b-form-datepicker>
          <label class="mr-2">End Date:</label>
          <b-form-datepicker v-model="endDate" class="mb-2 mr-sm-2 datepicker"></b-form-datepicker>
          <b-button @click="setStartDate(-1, 'day')" class="mb-2 mr-sm-2 secondary">Day</b-button>
          <b-button @click="setStartDate(-1, 'week')" class="mb-2 mr-sm-2 secondary">Week</b-button>
          <b-button @click="setStartDate(-1, 'month')" class="mb-2 mr-sm-2 secondary">Month</b-button>
          <label class="mr-2">Limit:</label>
          <b-form-input
            v-model="limit"
            type="number"
            class="mb-2 mr-sm-2 input-limit"
          ></b-form-input>
          <b-button @click="loadStats" class="mb-2 mr-sm-2 load-stats-btn">Load Stats</b-button>
          <b-button @click="clearFilters" class="mb-2 mr-sm-2 clear-btn">Clear</b-button>
        </b-form>
      </div>
      <b-tabs v-model="tabIndex" pills card>
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tab.title"
          :title-link-class="{'text-muted': false}"
          active-class="font-weight-bold"
        >
          <template #title>
            <span>{{ tab.title }}</span>
            <b-button
              size="sm"
              variant="danger"
              class="ml-2 remove-tab-btn"
              @click="removeTab(index)"
            >
              X
            </b-button>
          </template>
          <b-container fluid>
            <b-row>
              <b-col cols="4">
                <div class="select-fields mb-3">
                  <label class="mr-2">Fields:</label>
                  <b-form-select
                    v-model="tab.selectedFields"
                    :options="tab.availableFields"
                    @change="updateChart(index)"
                    multiple
                    class="custom-select-fields"
                  ></b-form-select>
                </div>
                <div class="select-fields mb-3">
                  <label class="mr-2">Group By:</label>
                  <!-- buttons -->
                  <div>
                    <b-button
                      v-for="field in tab.groupByFields"
                      :key="field"
                      @click.prevent="updateGroupBy(index, field)"
                      variant="outline-secondary">
                      {{ field }}
                    </b-button>
                  </div>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="select-interval mb-3">
                  <label class="mr-2">Interval:</label>
                  <b-form-select
                    v-model="tab.selectedInterval"
                    :options="availableIntervals"
                    @change="updateChart(index)"
                    class="custom-select-interval"
                  ></b-form-select>
                </div>
              </b-col>
              <b-col cols="4">
                <div class="export-csv mb-3">
                  <b-button @click="exportToCsv(index)" class="export-csv-btn">Export to CSV</b-button>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <line-chart v-if="tab.chartData" :chart-data="tab.chartData" :options="chartOptions" class="chart-container"></line-chart>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>
<style lang="sass">
.loader
  margin: 6px 6px 6px 6px
  width: 64px
  height: 64px
  left: 50%
  transform: translateX(-50%)

.stats
  .query-controller
    background-color: #f8f9fa
    padding: 10px
    border-radius: 5px

    .datepicker
      background-color: #ffffff
      border: 1px solid #ced4da
      border-radius: 4px

    .input-limit
      background-color: #ffffff
      border: 1px solid #ced4da
      border-radius: 4px

    .load-stats-btn
      background-color: #007bff
      border: 1px solid #007bff
      color: #ffffff
      border-radius: 4px

    .clear-btn
      background-color: #6c757d
      border: 1px solid #6c757d
      color: #ffffff
      border-radius: 4px

  .select-fields, .select-interval
    background-color: #f8f9fa
    padding: 10px
    border-radius: 5px

  .custom-select-fields, .custom-select-interval
    background-color: #ffffff
    border: 1px solid #ced4da
    border-radius: 4px

  label
    font-weight: bold

  .remove-tab-btn
    background-color: #dc3545
    border: 1px solid #dc3545
    color: #ffffff

  .line-chart
    max-height: 400px
    max-width: 100%
    overflow: auto

  .chart-container
    height: 400px
    width: 100%
    overflow: auto
  
  .export-csv
    margin-bottom: 15px

  .export-csv-btn
    background-color: #28a745
    border: 1px solid #28a745
    color: #ffffff
    border-radius: 4px
</style>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Line } from "vue-chartjs";
import moment from "moment-timezone";
import { getFlattenFieldsValues, getUniqueFlattenFields, hsvToRgb } from "@/utils";
import api from "@/api.js";

const LineChart = {
  extends: Line,
  props: ["chartData", "options"],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData() {
      this.renderChart(this.chartData, this.options);
    },
  },
};

export default {
  name: "Events",
  components: { Loading, LineChart },
  data() {
    return {
      loading: false,
      modalLoading: false,
      startDate: null,
      endDate: null,
      limit: null,
      tabIndex: 0,
      tabs: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              type: "time",
              distribution: "linear", // Change this from 'series' to 'linear'
              time: {
                tooltipFormat: 'YYYY-MM-DD HH:mm', // Optional, to format the tooltip time display
              },
              ticks: {
                source: 'auto', // Optional, ensure ticks are generated automatically
              },
            },
          ],
        },
      },
      availableIntervals: [
        { value: "all", text: "all" },
        { value: "1h", text: "1 hour" }, //1 2 4 6 12 24
        { value: "2h", text: "2 hours" },
        { value: "4h", text: "4 hours" },
        { value: "6h", text: "6 hours" },
        { value: "12h", text: "12 hours" },
        { value: "1d", text: "1 day" },
        { value: "7d", text: "1 week" },
        { value: "30d", text: "1 month" }
      ],
    };
  },
  watch: {
    tabIndex() {
      console.log("currentTab changed", this.tabIndex);
    },
  },
  methods: {
    setStartDate(amount, unit) {
      this.startDate = moment().add(amount, unit).format("YYYY-MM-DD");
    },
    async loadStats() {
      let result = [];
      this.modalLoading = true;
      try {
        const { data } = await api.call("api/stats/list", {
          start_date: this.startDate,
          end_date: this.endDate,
          limit: this.limit,
        });
        result = data.map((item) => item.data);
      } catch (err) {
        console.log(err);
        throw err;
      } finally {
        this.modalLoading = false;
      }

      const tabNameParts = [`Search #${this.tabs.length + 1}`];
      if (this.startDate) {
        tabNameParts.push(`After ${moment(this.startDate).format("YYYY-MM-DD")}`);
      }
      if (this.endDate) {
        tabNameParts.push(`Before ${moment(this.endDate).format("YYYY-MM-DD")}`);
      }
      if (this.limit) {
        tabNameParts.push(`Limit: ${this.limit}`);
      }

      const tabName = tabNameParts.join(" | ");

      const statArray = result.map((item) => item.stats);
      const uniqueFields = getUniqueFlattenFields(statArray);
      const timestampValues = result.map((item) => item.created_at);
      const groupByFields = new Set();
      for (const field of uniqueFields) {
        const parts = field.split(".");
        for (let i = 1; i < parts.length; i++) {
          groupByFields.add(parts.slice(0, i).join("."));
        }
      }
      const newTab = {
        title: tabName,
        selectedFields: uniqueFields,
        selectedInterval: "1h",
        availableFields: uniqueFields,
        statArray: statArray,
        timestampValues: timestampValues,
        groupByFields: Array.from(groupByFields),
        chartData: null,
      };
      const index = this.tabs.length;
      this.tabIndex = index;
      this.tabs.push(newTab);
      this.updateChart(this.tabIndex);
      //hack to fix tab not being active
      setTimeout(() => {
        this.tabIndex = index;
      }, 100);
    },

    updateGroupBy(index, group) {
      const tab = this.tabs[index];
      if (!tab) {
        return;
      }
      const selectedFields = tab.availableFields.filter((field) => field.startsWith(group));
      this.$set(tab, "selectedFields", selectedFields);
      this.updateChart(index);
    },

    updateChart(index) {
      const tab = this.tabs[index];
      const requestFields = {};
      for (const field of tab.selectedFields) {
        requestFields[field] = 0;
      }
    
      const flattenArray = getFlattenFieldsValues(tab.statArray, requestFields);
      console.log(flattenArray);
      let processedArray = {};
      let processedTimestamps = [];
      //process selected interval
      const interval = tab.selectedInterval || '1h';
      if (interval === 'all') {
        processedArray = flattenArray;
        processedTimestamps = tab.timestampValues;
      } else if (interval.endsWith('h')) {
        const hours = parseInt(interval.replace('h', ''));
        const res = this.adjustToHours(flattenArray, tab.timestampValues, hours);
        processedArray = res.array;
        processedTimestamps = res.timestamps;
      } else if (interval.endsWith('d')) {
        const days = parseInt(interval.replace('d', ''));
        const hours = days * 24;
        const res = this.adjustToHours(flattenArray, tab.timestampValues, hours);
        processedArray = res.array;
        processedTimestamps = res.timestamps;
      }
     
      const chartData = {
        labels: processedTimestamps.map((item) => moment(item).toDate()),
        datasets: [],
      };
      
      const randomColor = () => {
        const hue = Math.floor(Math.random() * 360);
        const saturation = 100;
        const value = 60;
        const rgb = hsvToRgb(hue, saturation, value);
        return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
      };
      for (const field of tab.selectedFields) {
        const color = randomColor();
        const y = processedArray[field];
        chartData.datasets.push({
          label: field,
          data: y,
          borderColor: color,
          backgroundColor: color,
          fill: false,
          lineTension: 0.1,
        });
      }
      console.log(chartData);
      tab.chartData = chartData;
      this.$set(this.tabs, index, tab);
    },
    exportToCsv(index) {
      const tab = this.tabs[index];
      const requestFields = {};
      for (const field of tab.selectedFields) {
        requestFields[field] = 0;
      }
      const flattenArray = getFlattenFieldsValues(tab.statArray, requestFields);
      let processedArray = {};
      let processedTimestamps = [];
      //process selected interval
      const interval = tab.selectedInterval || '1h';
      if (interval === 'all') {
        processedArray = flattenArray;
        processedTimestamps = tab.timestampValues;
      } else if (interval.endsWith('h')) {
        const hours = parseInt(interval.replace('h', ''));
        const res = this.adjustToHours(flattenArray, tab.timestampValues, hours);
        processedArray = res.array;
        processedTimestamps = res.timestamps;
      } else if (interval.endsWith('d')) {
        const days = parseInt(interval.replace('d', ''));
        const hours = days * 24;
        const res = this.adjustToHours(flattenArray, tab.timestampValues, hours);
        processedArray = res.array;
        processedTimestamps = res.timestamps;
      }
      const lines = [];
      const header = ["timestamp", ...tab.selectedFields];
      lines.push(header.join(","));
      for (let i = 0; i < processedTimestamps.length; i++) {
        //format timestamp to cvs compatible
        const timestamp = moment(processedTimestamps[i]).format("YYYY-MM-DD HH:mm:ss");
        const line = [timestamp];
        for (const field of tab.selectedFields) {
          const value = processedArray[field][i];
          line.push(value);
        }
        lines.push(line.join(","));
      }
      const csv = lines.join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const filename = `search-stats-${moment().format("YYYY-MM-DD")}.csv`;
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    adjustToHours(flattenArray, timestamps, hours) {
      if (timestamps.length < 1) {
        return {
          array: flattenArray,
          timestamps: timestamps,
        };
      }
      const result = {
        array: {},
        timestamps: [],
      };
      for (let field in flattenArray) {
        result.array[field] = [];
      }
      let startDate = moment(timestamps[0]);
      let currentDate = startDate;
      let currentValue = {}
      let hasLast = false;
      for (let i = 0; i < timestamps.length; i++) {
        const timestamp = timestamps[i];
        const diff = moment(timestamp).diff(currentDate, "hours")
        const diffAbs = Math.abs(diff);
        if (diffAbs < hours) {
          //add to current
          for (let field in flattenArray) {
            if (!currentValue[field]) {
              currentValue[field] = 0;
            }
            currentValue[field] += flattenArray[field][i];
          }
          hasLast = true;
        } else {
          //add current to result
          result.timestamps.push(currentDate.toDate());
          for (let field in flattenArray) {
            result.array[field].push(currentValue[field]);
            currentValue[field] = flattenArray[field][i];
          }
          //reset current
          //add hours to current
          if (diff < 0) {
            if (diffAbs === hours) {
              //subtract hours
              currentDate = moment(currentDate).subtract(hours, "hours");
            } else {
              //subtract diff and add 1
              currentDate = moment(currentDate).subtract(diffAbs, "hours").add(1, "hours");
            }
          } else {
            if (diffAbs === hours) {
              //add hours
              currentDate = moment(currentDate).add(hours, "hours");
            } else {
              //add diff and subtract 1
              currentDate = moment(currentDate).add(diffAbs, "hours").subtract(1, "hours");
            }
          }
          hasLast = false;
        }
      }
      //add last current to result
      if (hasLast) {
        result.timestamps.push(currentDate.toDate());
        for (let field in flattenArray) {
          result.array[field].push(currentValue[field]);
        }
      }
      console.log(result);
      return result;
    },

    removeTab(index) {
      this.tabs.splice(index, 1);
    },
    clearFilters() {
      this.startDate = null;
      this.endDate = null;
      this.limit = null;
    },
  }
};
</script>
