export default {
    algolia: {
        appId: 'CCBGJA0ZU3', // 'JEAGO1YSJN',
        appKey: 'e9ab4a3de3cf0a7047aa3a76a75448ec' // 'e446f4640ecd6d181959fee0947d1dda'
    },
    links: {
        'iOSBundleId': 'com.shmuzy.inc.app',
        'iOSStoreId': '1493026737',
        'androidAppId': 'com.shmuzy.core',
        'linkPrefix': 'https://shmuzy.com/app',
        'linkBase': 'https://shmuzy.com',
        'branch_key': 'key_live_goNXVUY3Uq48MxUGaqXdRmkpFBi8RgSY'
    },
    backend: {
        apiUrl: "https://5dwu6rrl45.execute-api.us-west-2.amazonaws.com/v1/",
        s3Prefix: "https://shmuzy-prod-media.s3.us-west-2.amazonaws.com/",
    }
}
