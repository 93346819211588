import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Users from '../views/Users.vue'
import Login from '../views/Login.vue'
import Feeds from '../views/Feeds.vue'
import Forums from '../views/Forums.vue'
import ForumsExtVue from '../views/ForumsExt.vue'
import Maintenance from '../views/Maintenance.vue'
import ReportMessages from '../views/ReportMessages.vue'
import ReportFeeds from '../views/ReportFeeds.vue'
import Messaging from '../views/Messaging.vue'
import Events from '../views/Events.vue'
import Filters from '@/views/Filters.vue'
import SamFilters from '@/views/SamFilters.vue'
import Stats from '@/views/Stats.vue'
import Finance from '@/views/Finance.vue'
import MdmReport from '@/views/MdmReport.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login Shmuzy Admin'
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: `Home [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/finance',
    name: 'finance',
    component: Finance,
    meta: {
      title: `Finance [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/users/:userId?',
    name: 'users',
    component: Users,
    meta: {
      title: `Users [${process.env.NODE_ENV}]`
    },
    props: true
  },
  {
    path: '/feeds',
    name: 'feeds',
    component: Feeds,
    meta: {
      title: `Feeds [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/forums/:forumId?',
    name: 'forums',
    component: Forums,
    meta: {
      title: `Forums [${process.env.NODE_ENV}]`
    },
    props: true
  },
  {
    path: '/forums-ext/:forumId?',
    name: 'forums-ext',
    component: ForumsExtVue,
    meta: {
      title: `Forums Ext [${process.env.NODE_ENV}]`
    },
    props: true
  },
  {
    path: '/stats',
    name: 'stats',
    component: Stats,
    meta: {
      title: `Stats [${process.env.NODE_ENV}]`
    }
  },
  //MdmReport
  {
    path: '/mdm-report',
    name: 'MdmReport',
    component: MdmReport,
    meta: {
      title: `MdmReport [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/events',
    name: 'events',
    component: Events,
    meta: {
      title: `Events [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/filters',
    name: 'samfilters',
    component: SamFilters,
    meta: {
      title: `Filters [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/advanced-filters',
    name: 'filters',
    component: Filters,
    meta: {
      title: `Advanced Filters [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/report_messages',
    name: 'report_messages',
    component: ReportMessages,
    meta: {
      title: `Reports Msg [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/report_feeds',
    name: 'report_feeds',
    component: ReportFeeds,
    meta: {
      title: `Reports Feed [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    meta: {
      title: `Maintenance [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/messaging/:channelType/:channelId',
    name: 'messaging',
    component: Messaging,
    props: true,
    meta: {
      title: `Messaging [${process.env.NODE_ENV}]`
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
