<template>
  <div class="forumlist">
    <b-card no-body class="forumlist-card">
      <ais-instant-search ref='instantsearch' :search-client="searchClient" index-name="sh_forum">
        <ais-configure :enable-rules.camel="false" :hits-per-page.camel="hitsPerPage"/>
        <b-list-group>
          <b-list-group-item variant="primary" class="search-item">
            <ais-search-box autofocus>
              <template slot-scope="{ currentRefinement, refine }">
                <b-input-group >
                  <b-form-input
                    :value="currentRefinement"
                    @input="refine($event)"
                    placeholder="Search forum"
                    class="search-input"/>
                    <b-input-group-append>
                      <b-button
                        @click.prevent="refine('')"
                        :disabled="!currentRefinement"
                        class="clear-btn">X</b-button>
                    </b-input-group-append>
                </b-input-group>
              </template>
            </ais-search-box>
          </b-list-group-item>
          <b-list-group-item>
            <ais-pagination />
          </b-list-group-item>
          <b-list-group-item v-if='allowNewForum' class='text-center' variant="success" :active='forumId==="$//newforum"' @click='selectForum("$//newforum")' button>
            <div>New forum</div>
          </b-list-group-item>
          <ais-hits :transform-items="transformItems">
            <div slot-scope="{ items }">
              <b-list-group-item v-for="item in items" :key="item.objectID" :active='forumId===item.objectID' @click='selectForum(item.objectID)' button class="forum-item">
                <b-row>
                  <b-col>
                    <b-avatar :src='item | getImage' :text='item.name | avatary' alt='avatar' class="avatar"></b-avatar>
                    <ais-highlight class='pl-2 forum-name' :hit="item" attribute="name" />
                    <div class="icon-wrapper">
                      <small>
                        <span v-if="item.is_verified" class="verified-icon">✔</span>
                        <span v-if="item.allow_claim" class="claim-icon">🏳️</span>
                        <span v-if="item.premium_id" class="claim-icon">💎</span>
                      </small>
                    </div>
                  </b-col>
                  <b-col cols=3 class='text-truncate text-center'>
                    <b-badge variant="success" v-if='parser(item.objectID).status === "ok"'>{{ parser(item.objectID).type }}</b-badge>
                    <b-badge variant="warning" v-if='parser(item.objectID).status === "warning"'>{{ parser(item.objectID).type }}</b-badge>
                    <b-badge variant="danger" v-if='parser(item.objectID).status === "error"'>{{ parser(item.objectID).type }}</b-badge>
                    <b-badge variant="dark" v-if='parser(item.objectID).status === "disabled"'>{{ parser(item.objectID).type }}</b-badge>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </div>
          </ais-hits>
        </b-list-group>
    </ais-instant-search>
    </b-card>
  </div>
</template>

<style scoped>
  .forumlist-card {
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .search-item {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .search-input {
    border-radius: 0.5rem;
  }

  .clear-btn {
    border-radius: 0.5rem;
    background-color: #007bff;
    border-color: #007bff;
  }

  .forum-item {
    padding: 0.75rem 1.25rem;
  }

  .forum-item b-row {
    align-items: center;
  }

  .forum-item .pl-2 {
    display: inline;
  }

  .forum-item small {
    font-weight: 600;
  }
  .avatar {
    margin-right: 0.5rem;
    background-color: #f8f9fa;
  }

  .forum-name {
    font-weight: 600;
    display: inline;
  }

  .icon-wrapper {
    display: inline;
  }

  .verified-icon,
  .claim-icon {
    font-weight: 600;
    margin-left: 0.5rem;
  }

  .verified-icon {
    color: #28a745;
  }

  .claim-icon {
    color: #ffc107;
  }
</style>

<script>
  import algoliaClient from '@/algolia.js'
  import 'instantsearch.css/themes/algolia-min.css';
  import api from '@/api.js'
  import EventBus from '@/event-bus.js'

  export default {
    name: 'ForumList',
    data() {
      return {
        searchClient: algoliaClient,
        timer: null,
        cacheTimeout: null,
        lastIds: [],
        parsers: {}
      }
    },
    props: {
      forumId: String,
      allowNewForum: {
        type: Boolean,
        default: true
      },
      hitsPerPage: {
        type: Number,
        default: 12
      }
    },
    mounted() {
      this.timer = setInterval(this.updateParsers, 10000)
      EventBus.$on('forumChanged', () => {
        if (this.cacheTimeout) {
          clearTimeout(this.cacheTimeout)
          this.cacheTimeout = null
        }
        this.cacheTimeout = setTimeout(() => {
           this.updateCache()
        }, 2000)
      })
    },
    beforeDestoy() {
      EventBus.$off('forumChanged')
      if (this.cacheTimeout) {
        clearTimeout(this.cacheTimeout)
        this.cacheTimeout = null
      }
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    methods: {
      updateCache() {
        let instantsearch = this.$refs.instantsearch || null
        let instantSearchInstance = instantsearch && instantsearch.instantSearchInstance || null
        if (instantSearchInstance) {
          instantSearchInstance.refresh()
        }
      },
      selectForum(forumId) {
        //this.selectedFeed = feedId
        this.$emit('update:forumId', forumId)
        //this.parserStatus([feedId])

      },
      transformItems(items) {
        const ids = items.map(item => item.objectID)
        this.lastIds = ids
        // this.parserStatus(ids)
        return items
      },
      parserStatus: async function (ids) {
        if (ids.length === 0) return
        try {
          let res = await api.call('parser/status', ids)
          for (let i = 0; i < ids.length; i++) {
            this.$set(this.parsers, ids[i], res.data[i])
          }
        } catch(e) {
          console.error(e)
        }
      },
      updateParsers() {
        // this.parserStatus(this.lastIds)
      },
      parser(id) {
        const p = this.parsers[id]
        if (!p) {
          return {
            status: 'unknown',
            type: ''
          }
        }
        let status = 'ok'
        if (p.error) {
          status = 'error'
        } else if (p.errors > 0) {
          status = 'warning'
        } else if (p.disabled) {
          status = 'disabled'
        }
        return Object.assign(p, {
          status: status
        })
      }
    },
    filters: {
      avatary(text) {
        if (!text) return ''
        const s = text.trim()
        const sp = s.split(' ')
        if (sp.length > 1) {
          const s1 = sp[0]
          const s2 = sp[1]
          const l1 = (s1.length > 2 ? s1.substring(0, 1) : s1).toUpperCase()
          const l2 = (s2.length > 2 ? s2.substring(0, 1) : s2).toUpperCase()
          return `${l1}${l2}`
        } else {
          return (s.length > 1 ? s.substring(0, 1) : s).toUpperCase()
        }
      },
      getImage(item) {
        return api.imageURL(item.image)
      }
    }
  }
</script>
