<template>
  <div class="filters">
    <b-container class='mt-2' fluid>
      <b-row>
        <b-col cols="4">
          <UserList :userId.sync='selectedUser'/>
        </b-col>
        <b-col cols="3">
          <!-- block containing FilterList for selected user -->
          <!--<FilterList :filterId.sync='selectedFilter' :userId="selectedUser"/>-->
          <!-- need to wrap component about into a card -->
          <b-card header-bg-variant="secondary"
                  header-text-variant="white" v-if="selectedUser" no-body>
            <template v-slot:header>
              <b-row>
                <b-col cols="auto" class="mr-auto"><span class='align-middle'>Filters</span></b-col>
                <b-col cols="auto" align-self="end">
                </b-col>
              </b-row>
            </template>
            <!-- user filters -->
            <b-container class='mt-2 mb-2 w-100' fluid>
              <b-row class="text-center w-100">
                <b-col cols="auto" class="mr-auto w-100">
                  <span class='align-middle'>User Filters:</span>
                </b-col>
              </b-row>
              <b-row class="w-100">
                <b-col cols="auto" class="mr-auto w-100">
                  <FilterList :filterId.sync='selectedFilter' :userId="selectedUser" :showNew="false" :showNewDefault="true" :showGlobalDefault="true"/>
                </b-col>
              </b-row>
            </b-container>
            <!-- all filters -->
            <b-container class='mt-2 mb-2 w-100' fluid>
              <b-row class="text-center w-100">
                <b-col cols="auto" class="mr-auto w-100">
                  <span class='align-middle'>All Filters:</span>
                </b-col>
              </b-row>
              <b-row class="w-100">
                <b-col cols="auto" class="mr-auto w-100">
                  <FilterList :filterId.sync='selectedFilter' :showNew="false"/>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
          <b-card v-else no-body>
            <b-card-header>Filters</b-card-header>
            <b-card-body>
              <p class="card-text">Please select a user to view their filters.</p>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="5">
          <!-- block containing FilterInfo for selected filter -->
          <FilterInfo v-if="selectedFilter" :filterId.sync='selectedFilter' :userId="selectedUser"/>
     
        </b-col>
      </b-row>
     
   </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FilterList from '@/components/FilterList.vue'
import FilterInfo from '@/components/FilterInfo.vue';
import UserList from '@/components/UserList.vue';
//event bus
//import EventBus from '@/event-bus.js';

export default {
  name: 'forums',
  data () {
    return {
      selectedFilter: '',
      selectedUser: ''
    }
  },
  watch: {
    selectedUser () {
      this.selectedFilter = '';
    }
  },
  methods: {
  },
  components: {
    FilterList,
    FilterInfo,
    UserList
  }
}
</script>
