<template>
  <div class="foruminfo">
    <loading :active.sync="modalAction"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal ref="image-editor-modal" size="lg" hide-footer title="Edit image">
      <ImageEdit ref='image-editor'
        :inputImage='imageEditor.image'
        :maxSize='imageEditor.maxSize'
        :thumb64='imageEditor.thumb64'
        :color='imageEditor.color'
        :stencil='imageEditor.stencil'
        @done='saveImage(imageEditor.tag, $event)'
        @cancel='$refs["image-editor-modal"].hide()'/>
    </b-modal>
    <b-modal ref="forum-categories-modal" size="lg" hide-footer title="Edit/Select Category">
      <CategorySelector ref='category-selector'
        @select='selectCategory'
        stream-type="forum"/>
    </b-modal>
    <b-modal ref="branch-io-war" hide-footer title="Action required">
      <div class="d-block text-center">
        In order to clear this link you have to
        manually find and delete your link at <a target='_blank' href='https://dashboard.branch.io/quick-links'>Branch.io Dashboard</a> first
      </div>
      <div class="d-block text-center">
        <b-button class="mt-2 text-center" variant="secondary" @click="$refs['branch-io-war'].hide()">Got it</b-button>
      </div>
    </b-modal>
    <b-modal ref="invite-args-edit" hide-footer title="Update Invite Args">
      <b-form-group
        label-cols="2"
        description="Invite Arguments"
        label="Args">
        <b-input-group>
           <b-form-input
                v-model="argsEditModel"
                placeholder="">
                </b-form-input>
           <b-input-group-append>
              <b-button variant="warning" size='sm'  @click.prevent='argsEditModel="podcasts"'>Podcasts</b-button>
              <b-button variant="secondary" size='sm'  @click.prevent='argsEditModel=""'>Clear</b-button>
            </b-input-group-append>
          </b-input-group>
      </b-form-group>
      <b-row>
        <!--<b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="success" @click="updateInviteArgs">Update</b-button>
        </b-col>-->
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click="$refs['invite-args-edit'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>

    <b-modal ref="forum-alias-confirm" hide-footer title="Confirm create alias">
      <b-form-group
        label-cols="2"
        description="It is short name for link (eg: http://example.com/alias)"
        label="Alias">
         <b-form-input
              v-model="aliasEditModel"
              placeholder="alias"></b-form-input>
      </b-form-group>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="success" :disabled='!aliasEditModel' @click="confirmAliasforum">Create</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click="hideAliasforum">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="forum-delete-confirm" hide-footer title="Confirm deletion">
      <div class="d-block text-center">
        <h4>Are you sure to delete the forum (this action cannot be undone)?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmDeleteforum">Delete</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="hideDeleteforum">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-card
        :header-bg-variant="isNew ? 'success' : edited ? 'warning' : 'primary'"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ isNew ? 'New forum' : 'Forum' }}</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button v-if='edited' variant='danger' size='sm' @click='reset'>Reset changes</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-tabs v-if='forum && forum.id && !loading' card>
        <b-tab title="Info" active>
          <b-form>
            <b-form-group
              label-cols="2"
              label="Identifier"
              label-for="forum-id">
              <b-form-input
                id="forum-id"
                v-model="forum.id"
                :disabled='true'
                required
                placeholder="Enter forum id"></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Post, Members"
              label-for="forum-counters">
              <b-input-group id="forum-counters">
                <b-form-input
                  v-model="forum.message_count"
                  :disabled='true'
                  required
                  placeholder="Posts"></b-form-input>
                <b-form-input
                  v-model="forum.member_count"
                  :disabled='true'
                  required
                  placeholder="Members"></b-form-input>
                
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Created at"
              label-for="forum-created">
              <div class="form-control-plaintext">{{ forum.created_at | formatDate }}</div>
            
            </b-form-group>
            <b-form-group
              v-if='!isNew && forum.updated_at'
              label-cols="2"
              label="Updated at"
              label-for="forum-updated">
              <div class="form-control-plaintext">{{ forum.updated_at | formatDate }}</div>
            </b-form-group>

            
            <b-form-group
              v-if='!isNew && forum.admins'
              label-cols="2"
              label="Admins"
              label-for="forum-admins">
              <div class="form-control-plaintext">
                <span v-for="(admin, index) in Object.keys(forum.admins)" :key="index">
                  <router-link :to="`users/${admin }`" target="_blank">{{ admin }} ({{ forum.admins[admin]  }})</router-link>
                  <span v-if="index !== Object.keys(forum.admins).length - 1">, </span>
                </span>
              </div>
            </b-form-group>
        
            <b-form-group
              label-cols="2"
              label="Categories"
              label-for="forum-categories">
              <div class="form-control-plaintext">
                <!-- forum.category_id, forum.category_name or No category -->
                <span v-if="forum.category_id">
                  {{ forum.category_name }} ({{ forum.category_id }})
                </span>
                <span v-else>
                  No category
                </span>
                <b-button @click='$refs["forum-categories-modal"].show()' variant='link' size='sm'>Edit</b-button>
                <b-button v-if="forum.category_id" @click='selectCategory(null)' variant='link' size='sm'>Clear</b-button>
              </div>  
            </b-form-group> 

            <b-form-group
              label="Name"
              label-cols="2"
              label-for="forum-name">
              <b-form-input
                id="forum-name"
                v-model="forum.name"
                @input='editMode()'
                required
                placeholder="Enter forum name"></b-form-input>
            </b-form-group>
            <b-form-group
              label="Description"
              label-for="forum-description">
              <b-form-textarea
                id="forum-description"
                v-model="forum.description"
                @input='editMode()'
                placeholder="Description of a forum"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
            <b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Invite Link"
              label-for="forum-invite-link">
              <b-input-group>
                <!--<b-input-group-prepend>
                  <b-button v-if='forum.invite_link' variant="success" @click.prevent='inviteUpdate()'>Update</b-button>
                </b-input-group-prepend>-->
                <b-form-input
                  id="forum-invite"
                  v-model="forum.invite_link"
                  :disabled='true'
                  placeholder="Invite link is disabled">
                </b-form-input>
                <b-input-group-append>
                  <b-button v-if='forum.invite_link' variant="warning" @click.prevent='inviteReset()'>Reset</b-button>
                  <b-button v-if='forum.invite_link' variant="danger" @click.prevent='inviteDelete()'>Disable</b-button>
                  <b-button v-if='!forum.invite_link' variant="success" @click.prevent='inviteCreate()'>Create</b-button>
                  <!--<b-button v-if='forum.invite_link' variant="secondary" @click.prevent='inviteArgs()'>Args</b-button>-->
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!--<b-form-group
              v-if='!isNew'
              label-cols="2"
              label="Alias Link"
              label-for="forum-alias-link">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button v-if='forum.alias_link' variant="success" @click.prevent='aliasUpdate()'>Update</b-button>
                </b-input-group-prepend>
                <b-form-input
                  id="forum-alias"
                  v-model="forum.alias_link"
                  :disabled='true'
                  placeholder="Alias link is disabled">
                </b-form-input>
                <b-input-group-append>
                  <b-button v-if='forum.alias_link' variant="danger" @click.prevent='askUnAliasforum()'>Clear</b-button>
                  <b-button v-if='!forum.alias_link' variant="warning" @click.prevent='askAliasforum()'>Create</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>-->
            <b-form-group
              label="Forum Options"
              label-cols="2">
              <!--is_verified-->
              <b-form-checkbox
                id="forum-verified"
                @change='editMode()'
                v-model="forum.is_verified"
                name="forum-verified"
                :value="true"
                :unchecked-value="false" inline>
                Is Verified
              </b-form-checkbox>
              <!--allow_claim-->
              <b-form-checkbox
                id="forum-claim"
                @change='editMode()'
                v-model="forum.allow_claim"
                name="forum-claim"
                :value="true"
                :unchecked-value="false" inline>
                Allow Claim
              </b-form-checkbox>
              <!--only_admin_protect-->
               <!--save_forward_protect-->
              <b-form-checkbox
                id="forum-save-forward"
                @change='editMode()'
                v-model="forum.save_forward_protect"
                name="forum-save-forward"
                :value="1"
                :unchecked-value="0" inline>
                Save Forward Protect
              </b-form-checkbox>
              <!--is_private-->
              <b-form-checkbox
                id="forum-private"
                @change='editMode()'
                v-model="forum.is_private"
                name="forum-private"
                :value="true"
                :unchecked-value="false" inline>
                Is Private
              </b-form-checkbox>
            </b-form-group>
            <b-form-group  label="Only Admin Can Post" label-cols="2">
              <b-form-select 
                  id="forum-protect" 
                  @change='editMode()' 
                  v-model="forum.only_admin_protect" 
                  name="forum-protect">
                  <option value="0">Anyone can post</option>
                  <option value="1">Chat Mode</option>
                  <option value="2">Feed Mode</option>
              </b-form-select>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-tab title="Appearance">
          <b-card
            title="Group image"
            sub-title="Select group image for forum">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='image' blank-color="#eee" :blank='!image' blank-width='200' blank-height='200' width='200' height='200' rounded="circle" alt="Group image"></b-img>
            </div>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='image' size='sm' variant="danger" @click.prevent='removeImage("image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
          <b-card
            title="Background image"
            sub-title="Select background image for forum">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='background_image' blank-color="#eee" :blank='!background_image' blank-width='250' width='250' alt="Background image"></b-img>
            </div>
            <b-form-group label="Text color" v-if='forum.palette'>
              <b-form-radio-group
                id="backgroundText"
                @input='editMode()'
                v-model="forum.palette.textColorDark"
                :options="textColorOptions"
                buttons
                name="radios-btn-default"
              ></b-form-radio-group>
            </b-form-group>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("background_image")'>Edit image</b-button>
                <b-button size='sm' variant="warning" @click.prevent='whiteDefImage("background_image")'>White image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='background_image' size='sm' variant="danger" @click.prevent='removeImage("background_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>

        </b-tab>
      </b-tabs>
      <b-card-body v-else-if='!loading'>
        <b-alert variant="danger" v-if='forumId && !loadError' show>Forum does not exist</b-alert>
        <b-alert variant="danger" v-else-if='forumId && loadError' show>Error loading forum {{loadError}}</b-alert>
        <b-alert v-else show>Please, select the forum</b-alert>
      </b-card-body>
      <b-card-footer  v-if='forum && forum.id && !loading'>
        <b-row>
          <b-col cols="auto" class="mr-auto">
            <b-button v-if='isNew' :disabled='!canSave' size='sm' variant="success" @click.prevent='create'>Create</b-button>
            <b-button v-else-if='edited' :disabled='!canSave' size='sm' class='mr-1' variant="success" @click.prevent='save'>Save changes</b-button>
            <b-button if='!isNew' size='sm' variant="secondary" class='ml-1' target='_blank' :to="{path: `/messaging/forum/${forumId}`}">Show Messages</b-button>
          </b-col>
          <b-col cols="auto" align-self="end">

            <b-button v-if='!isNew' variant='danger' size='sm' @click='askConfirmDeleteforum'>Delete the forum</b-button>
          </b-col>
        </b-row>

      </b-card-footer>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  import ImageEdit from '@/components/ImageEdit.vue'
  import EventBus from '@/event-bus.js'
  import CategorySelector from '@/components/CategorySelector.vue'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  import { processUploads } from '@/utils.js'

  export default {
    name: 'ForumInfo',
    props: {
      forumId: String
    },
    data() {
      return {
        argsEditModel: '',
        aliasEditModel: '',
        modalAction:false,
        currentId:null,
        loadError:null,
        forum: {},
        uploads: {},
        imageEditor: {
          tag:null,
          image:null,
          stencil:'rectangle',
          maxSize: 1024,
          thumb64: 0,
          color:false
        },
        edited: false,
        loading: false,
        isNew: false,
        textColorOptions: [
          { text: 'Auto', value: null },
          { text: 'White text', value: false },
          { text: 'Black text', value: true }
        ]
      }
    },
    watch: {
      forumId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.edited = false
          this.isNew = false
          this.forum = {}
          this.bind(id)
        }
      }
    },
    methods: {
      selectCategory(category) {
        if (!category) {
          if (this.forum.category_id) {
            this.forum.category_id = null
            this.forum.category_name = null
            this.editMode()
          }
        } else {
          if (this.forum.category_id != category.id) {
            this.forum.category_id = category.id
            this.forum.category_name = category.name
            this.editMode()
          }
        }
        this.$refs['forum-categories-modal'].hide()
      },
      forumTransfer() {

      },
      inviteDeletePrivate: async function() {
        const { data } = await api.call('api/invite/remove', { id: this.forumId, stream_type: 'forum' })
        const savedforum = data.data
        this.setForum(savedforum)
      },
      
      
      inviteCreatePrivate: async function() {
        const { data } = await api.call('api/invite/create', { id: this.forumId, stream_type: 'forum' })
        console.log(data)
        const savedforum = data.data
        this.setForum(savedforum)
      },

      aliasUpdate: async function() {
        let aliasLink = this.forum.aliasLink
        if (!aliasLink) return
        this.modalAction = true
        try {
          await links.updateLinkBranch(aliasLink, `forum/${this.forumId}`, this.socialInfo, true)
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },

      askUnAliasforum: async function() {
        let aliasLink = this.forum.aliasLink
        if (!aliasLink) return
        try {
          const exists = await links.checkLinkBranch(aliasLink)
          if (!exists) {
            // await firebase.database().ref('forum').child(this.forumId).update({
            //   'aliasLink': null
            // })
          } else {
            this.$refs['branch-io-war'].show()
          }
        } catch(e) {
          console.error(e)
        }
      },
      askAliasforum() {
        let aliasLink = this.forum.aliasLink
        if (aliasLink) return

        this.aliasEditModel = ''
        this.$refs['forum-alias-confirm'].show()
      },
      hideAliasforum() {
        this.$refs['forum-alias-confirm'].hide()
      },
      confirmAliasforum: async function() {
        this.$refs['forum-alias-confirm'].hide()
        this.modalAction = true
        try {
          // await this.aliasCreatePrivate(this.aliasEditModel)
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false

      },

      inviteDelete: async function() {
        this.modalAction = true
        try {
          await this.inviteDeletePrivate()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },
      inviteReset: async function() {
        this.modalAction = true
        try {
          await this.inviteDeletePrivate()
          await this.inviteCreatePrivate()
        } catch(e) {
          console.error(e)
        }
        this.modalAction = false
      },
      
      inviteCreate: async function() {
        await this.inviteReset()
      },
      async saveImage(tag, result) {
        if (tag === 'image') {
          this.$set(this.uploads, 'forum_image', {
            image: result.image,
            updates: {
              thumb64: result.thumb64
            },
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          this.edited = !this.isNew
        } else if (tag === "background_image") {
          this.forum.palette = (this.forum.palette || {})
          this.forum.palette.background = {
            r: result.rgb[0],
            g: result.rgb[1],
            b: result.rgb[2]
          }
          this.forum.palette.textColorDark = result.xyz[1]/100.0 > 0.35
          this.$set(this.uploads, 'background_image', {
            image: result.image,
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          this.edited = !this.isNew
        }
        this.$refs["image-editor-modal"].hide()
      },
      removeImage(tag) {
        if (tag === 'image') {
          this.$set(this.uploads, 'forum_image', {
            image: null,
            updates: {
              thumb64: null
            },
            info: null
          })
          this.edited = !this.isNew && !!this.forum.image
        } else if (tag === 'background_image') {
          if (this.forum.palette) {
              this.forum.palette.background = null
              this.forum.palette.textColorDark = null
              if (!this.forum.palette.headerBackground) {
                this.forum.palette = null
              }
          }
          this.$set(this.uploads, 'background_image', {
            image: null,
            info: null
          })
          this.edited = !this.isNew && !!this.forum.background_image
        }
        this.$refs["image-editor-modal"].hide()
      },
      whiteDefImage(tag) {
        if (tag === 'background_image') {
          if (this.forum.palette) {
              this.forum.palette.background = null
              this.forum.palette.textColorDark = null
              if (!this.forum.palette.headerBackground) {
                this.forum.palette = null
              }
          }
          this.$set(this.uploads, 'background_image', {
            image: null,
            info: null,
            white: true
          })
          this.edited = !this.isNew && !!this.forum.background_image
        }
      },
      editImage(tag) {
        this.imageEditor.tag = tag
        this.imageEditor.thumb64 = 0
        this.imageEditor.color = false
        if (tag === 'image') {
          this.imageEditor.stencil = 'circle'
          this.imageEditor.maxSize = 512
          this.imageEditor.thumb64 = 15
          this.imageEditor.image = this.image
          this.$refs["image-editor-modal"].show()
        } else if (tag === 'background_image') {
          this.imageEditor.stencil = 'rectangle'
          this.imageEditor.maxSize = 2048
          this.imageEditor.color = true
          this.imageEditor.image = this.background_image
          this.$refs["image-editor-modal"].show()
        } else if (tag === 'header_background_image') {
          this.imageEditor.stencil = 'rectangle'
          this.imageEditor.maxSize = 2048
          this.imageEditor.color = true
          this.imageEditor.image = this.header_background_image
          this.$refs["image-editor-modal"].show()
        }
      },
      askConfirmDeleteforum() {
        this.$refs['forum-delete-confirm'].show()
      },
      hideDeleteforum() {
        this.$refs['forum-delete-confirm'].hide()
      },
      confirmDeleteforum: async function() {
        this.$refs['forum-delete-confirm'].hide()
        this.loading = true
        try {
          await api.call('api/forum/delete', {
            id: this.forumId,
          })
        } catch(e) {
          //Ok
        }
        this.loading = false
        EventBus.$emit('forumChanged', this.forumId)
        this.$emit('update:forumId', null)
      },
      editMode() {
        if (this.loading || this.isNew) {
          return
        }
        this.edited = true
        this.bind(null)
      },
      create: async function() {
        this.modalAction = true
        this.forum.created_at = moment().utc().format(`YYYY-MM-DD[T]HH:mm:ss.SSSZZ`)
        if (!this.forum.disclaimer) {
          this.forum.disclaimer = null
        }
        try {
          const photos = {};
          await processUploads(this.forum.id, this.uploads, photos)
          const dataToCreate = {
            id: this.forum.id,
            name: this.forum.name,
            description: this.forum.description,
            admins: { [EventBus.user.id]: "owner" }, // firebase.auth().currentUser.uid,
            platform: 'admin-panel',
            is_verified: this.forum.is_verified,
            is_private: this.forum.is_private,
            allow_claim: this.forum.allow_claim,
            only_admin_protect: this.forum.only_admin_protect,
            save_forward_protect: this.forum.save_forward_protect,
            category_id: this.forum.category_id || null,
          }
          if (photos.forum_image) {
            dataToCreate.image = photos.forum_image
          } 
          if (photos.background_image_white) {
            dataToCreate.background_image = null
          } else if (photos.background_image) {
            dataToCreate.background_image = photos.background_image
          } else {
            dataToCreate.background_image = "%default_inset%"
            dataToCreate.palette = {
              background: {
                r: 0,
                g: 0,
                b: 0
              },
              textColorDark: true
            }
          }
          const { data } = await api.call('api/forum/create', {
            data: dataToCreate
          })
          const savedforum = data.data;
          this.setForum(savedforum);
        } catch(e) {
          //Ok
          console.log(e)
        } finally {
          this.modalAction = false
        }
        this.$emit('update:forumId', this.forum.id)
        EventBus.$emit('forumChanged', this.forum.id)
      },
      save: async function() {
        let update_fields = {}
        let delete_fields = {}
        this.edited = false
        if (this.forum.name) {
          update_fields.name = this.forum.name
        } else {
          delete_fields.name = true
        }
        if (this.forum.description) {
          update_fields.description = this.forum.description
        } else {
          delete_fields.description = true
        }
        if (this.forum.category_id) {
          update_fields.category_id = this.forum.category_id
          //update_fields.category_name = this.forum.category_name
        } else {
          delete_fields.category_id = true
          //delete_fields.category_name = true
        }
        if (this.forum.palette) {
          update_fields.palette = this.forum.palette
        } else {
          delete_fields.palette = true
        }
        if (this.forum.allow_claim) {
          update_fields.allow_claim = this.forum.allow_claim
        } else {
          delete_fields.allow_claim = true
        }
        if (this.forum.is_verified) {
          update_fields.is_verified = this.forum.is_verified
        } else {
          delete_fields.is_verified = true
        }
        if (this.forum.only_admin_protect) {
          update_fields.only_admin_protect = this.forum.only_admin_protect
        } else {
          delete_fields.only_admin_protect = true
        }
        if (this.forum.save_forward_protect) {
          update_fields.save_forward_protect = this.forum.save_forward_protect
        } else {
          delete_fields.save_forward_protect = true
        }
        if (this.forum.is_private) {
          update_fields.is_private = this.forum.is_private
        } else {
          delete_fields.is_private = true
        }
        // cannot update
        // updates['disclaimer'] = this.forum.disclaimer || null
        // updates['isVerified'] = this.forum.isVerified || false
        // updates['allowClaim'] = this.forum.allowClaim || false
        // updates['allowforumForums'] = this.forum.allowforumForums || false
        // updates['allowDirect'] = this.forum.allowDirect || false
        // updates['algoliaHideEmpty'] = this.forum.algoliaHideEmpty || false
        // updates['notifySystem'] = this.forum.notifySystem || false
        // updates['notificationDontGroup'] = this.forum.notificationDontGroup || false
        // updates['allowPodcastCategories'] = this.forum.allowPodcastCategories || false
        this.modalAction = true
        //console.log(updates)
        try {
          const photos = {};
          await processUploads(this.forumId, this.uploads, photos)
          if (photos.forum_image !== undefined) {
            if (photos.forum_image) {
              update_fields.image = photos.forum_image
            } else {
              delete_fields.image = true
            }
          }        
          if (photos.background_image !== undefined) {
            if (photos.background_image_white) {
              delete_fields.background_image = true
              update_fields.palette = {
                background: null,
                textColorDark: null
              }
            } else if (photos.background_image) {
              update_fields.background_image = photos.background_image
            } else {
              update_fields.background_image = "%default_inset%"
              update_fields.palette = {
                background: {
                  r: 0,
                  g: 0,
                  b: 0
                },
                textColorDark: true
              }
            }
          }
          const { data } = await api.call('api/forum/update', {
            id: this.forumId,
            update_fields: update_fields,
            delete_fields: delete_fields
          })
          const savedforum = data.data;
          this.setForum(savedforum)
        } catch(e) {
          console.log(e)
          //Ok
        } finally {
          this.modalAction = false
        }
        //this.loading = false
        EventBus.$emit('forumChanged', this.forumId)
        this.reset()
      },
      setForum(savedforum) {
        this.forum = {
          id: savedforum ? savedforum.id : uuid(),
          name: savedforum ? savedforum.name : '',
          description: savedforum ? savedforum.description : '',
          admins: savedforum ? savedforum.admins : {},
          platform: savedforum ? savedforum.platform : 'admin-panel',
          image: savedforum ? savedforum.image : '',
          background_image: savedforum ? savedforum.background_image : '',
          message_count: savedforum ? savedforum.message_count : 0,
          member_count: savedforum ? savedforum.member_count : 0,
          created_at: savedforum ? savedforum.created_at : moment().utc().format(`YYYY-MM-DD[T]HH:mm:ss.SSSZZ`),
          updated_at: savedforum ? savedforum.updated_at : moment().utc().format(`YYYY-MM-DD[T]HH:mm:ss.SSSZZ`),
          palette: savedforum ? savedforum.palette : null,
          invite_link: savedforum ? savedforum.invite_link : null,
          invite_id: savedforum ? savedforum.invite_id : null,
          allow_claim: savedforum ? savedforum.allow_claim : false,
          is_verified: savedforum ? savedforum.is_verified : false,
          only_admin_protect: savedforum ? savedforum.only_admin_protect : 0,
          save_forward_protect: savedforum ? savedforum.save_forward_protect : false,
          is_private: savedforum ? savedforum.is_private : false,
          category_id: savedforum ? savedforum.category_id : null,
          category_name: savedforum ? savedforum.category_name : null,
        }
      },
      reset() {
        this.edited = false
        this.uploads = {}
        this.bind(this.forumId)
      },
      bind: async function(id) {
        this.loadError = null
        this.currentId = id
        if (id === '$//newforum') {
          this.isNew = true
          this.setForum()
          return
        }
        if (id) {
          if (this.loading) return
          this.loading = true
          try {
            const operation = this.$operationTracker.registerOperation(this, `api/forum/get`)
            const { data } = await api.call('api/forum/get', { id });
            if (operation.isCanceled()) return
            const savedforum = data.data
            this.setForum(savedforum)
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          }
          this.loading = false
          if (this.currentId !== id) {
            this.bind(this.currentId)
          }
        } else {
          //not bind
        }
      }
    },
    computed: {
      socialInfo() {
        return this.forum && {
          title: this.forum.name,
          description: this.forum.bio,
          image: this.forum.image,
          background: this.forum.background_image,
          header_background: this.forum.header_background_image,
          channel_type: 'forum'
        }
      },
      canSave() {
        return this.forum && this.forum.name
      },
      image() {
        if (this.uploads['forum_image']) {
          return this.uploads['forum_image'].image
        }
        return this.forum.image ? api.imageURL(this.forum.image) : this.forum.image
      },
      background_image() {
        if (this.uploads['background_image']) {
          return this.uploads['background_image'].image
        }
        return this.forum.background_image ? api.imageURL(this.forum.background_image) : this.forum.background_image
      },
      header_background_image() {
        if (this.uploads['header_background_image']) {
          return this.uploads['header_background_image'].image
        }
        return this.forum.header_background_image ? api.imageURL(this.forum.header_background_image) : this.forum.header_background_image
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        const date = moment(value).format('DD.MM.YYYY HH:mm')
        const timeAgo = moment(value).fromNow()
        return `${date} (${timeAgo})`
      }
    },
    components: {
      Loading, ImageEdit, CategorySelector
    }
  }
</script>
