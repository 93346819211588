import api from "./api";
import { Buffer } from 'buffer'
export const processUploads = async (id, uploads, save) => {
  let ps = Object.keys(uploads).map(async k => {
    const upload = uploads[k]
    if (upload.image) {
      const buff = Buffer.from(upload.image.replace(/^data:image\/\w+;base64,/, ""),'base64')
      const status = await api.uploadMedia(k, new Blob([buff]), upload.info?.file?.type || 'image/jpeg')
      save[k] = status.id
    } else {
      save[k] = null
    }
    if (upload.white) {
      save[`${k}_white`] = upload.white
    }
    Object.assign(save, upload.updates)
  })
  await Promise.all(ps)
}


export function flattenObject(obj, prefix = '', res = {}, includeGroup = false) {
  for (const key in obj) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === 'object' && value !== null) {
      flattenObject(value, newKey, res, includeGroup);
      if (includeGroup) {
        res[newKey] = { group: true, key: key };
      }
    } else {
      res[newKey] = value;
    }
  }
  return res;
}


//get unique flatten fields names from object array
export function getUniqueFlattenFields(objArray) {
  const res = new Set();
  for (const obj of objArray) {
    const flattenObj = flattenObject(obj);
    for (const key in flattenObj) {
      res.add(key);
    }
  }
  return Array.from(res);
}

export function accessObjectByPath(obj, path) {
  const keys = path.split('.');
  let res = obj;
  for (const key of keys) {
    if (res[key] === undefined) {
      return undefined;
    }
    res = res[key];
  }
  return res;
}


export function getFlattenFieldsValues(objArray, fields) {
  const res = {};
  for (const obj of objArray) {
    const flattenObj = flattenObject(obj);
    for (const field in fields) {
      const defaultValue = fields[field];
      if (res[field] === undefined) {
        res[field] = [];
      }
      const value = flattenObj[field];
      if (value === undefined) {
        res[field].push(typeof defaultValue === 'function' ? defaultValue(obj) : defaultValue);
      } else {
        res[field].push(value);
      }
    }
  }
  return res;
}



export function generateProductNames(FIELDS_MAP, prefix='ios_premium_product') {
  const revisions = 'abcd';
  const types = ['montly', 'one_time'];
  const minAmount = 100;
  const maxAmount = 2000;
  const step = 100;
  const maxN = 4;
  const keyPrefix = prefix ? `${prefix}.` : '';
  for (const revision of revisions) {
    for (let n = 1; n <= maxN; n++) {
      for (const type of types) {
        for (let amount = minAmount; amount <= maxAmount; amount += step) {
          const fieldName = `${keyPrefix}sh_${revision}_${type}_${n}_${amount}`;
          const displayName = `iOS Premium Product (SH ${revision.toUpperCase()} ${type.replace('_', ' ').toUpperCase()} ${n} ${amount})`;
          FIELDS_MAP[fieldName] = displayName;
        }
      }
    }
  }
  return FIELDS_MAP;
}



export function hsvToRgb (hue, saturation, value) {
  var chroma = (value * saturation) / 100 / 100;
  var hue1 = hue / 60;
  var x = chroma * (1 - Math.abs((hue1 % 2) - 1));
  var r1, g1, b1;
  if (hue1 >= 0 && hue1 <= 1) {
    [r1, g1, b1] = [chroma, x, 0];
  } else if (hue1 >= 1 && hue1 <= 2) {
    [r1, g1, b1] = [x, chroma, 0];
  } else if (hue1 >= 2 && hue1 <= 3) {
    [r1, g1, b1] = [0, chroma, x];
  } else if (hue1 >= 3 && hue1 <= 4) {
    [r1, g1, b1] = [0, x, chroma];
  } else if (hue1 >= 4 && hue1 <= 5) {
    [r1, g1, b1] = [x, 0, chroma];
  } else if (hue1 >= 5 && hue1 <= 6) {
    [r1, g1, b1] = [chroma, 0, x];
  }
  var m = value / 100 - chroma;
  var [r, g, b] = [r1 + m, g1 + m, b1 + m];
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}