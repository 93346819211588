<template>
  <div class="feedparser">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal ref="approve-confirm" hide-footer title="Confirm Approve">
      <div class="d-block text-center">
        <h4>Confirm feed claim approve</h4>
        <b-form-checkbox
                v-model="forceApprove"
                name="feed-claim-force"
                :value="true"
                :unchecked-value="false" inline>
                Remove existing user feed if required
              </b-form-checkbox>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="success" @click="confirmApproveClaim">Approve</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="$refs['approve-confirm'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>

    <b-modal ref="reject-confirm" hide-footer title="Confirm rejection">
      <div class="d-block text-center">
        <h4>Are you sure to reject the claim?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmRejectClaim">Reject</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="$refs['reject-confirm'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="remove-confirm" hide-footer title="Confirm remove">
      <div class="d-block text-center">
        <h4>Are you sure to remove the claim?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmRemoveClaim">Remove</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="$refs['remove-confirm'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-card v-if='!isNew && feedId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ 'Feed claims' }}</span></b-col>
          <b-col cols="auto" align-self="end">
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-list-group v-else-if="claimsComputed.length>0">
        <b-list-group-item v-for="claim in claimsComputed" :key="claim.id">
          <b-row class='mb-2'>
            <b-col cols="auto" class="mr-auto">User Identifier:</b-col>
            <b-col cols="auto" align-self="end">
              {{claim.userId}}
            </b-col>
          </b-row>
          <b-row class='mb-2'>
            <b-col cols="auto" class="mr-auto">E-mail:</b-col>
            <b-col cols="auto" align-self="end">
              <b-link :href="'mailto:'+claim.email">{{claim.email}}</b-link>
            </b-col>
          </b-row>
          <b-row class='mb-2'>
            <b-col cols="auto" class="mr-auto">Status:</b-col>
            <b-col cols="auto" align-self="end">
              {{claim.status}}
            </b-col>
          </b-row>
          <b-row class='mb-2'>
            <b-col cols="auto" class="mr-auto">Date:</b-col>
            <b-col cols="auto" align-self="end">
              {{claim.timestamp | timefy }}
            </b-col>
          </b-row>
          <b-row v-if='claim.update' class='mb-2'>
            <b-col cols="auto" class="mr-auto">Updated:</b-col>
            <b-col cols="auto" align-self="end">
              {{claim.update | timefy }}
            </b-col>
          </b-row>
          <b-row v-if='claim.status=="pending"' class='mb-2'>
            <b-col cols="auto">
              <b-button size='sm' variant="success" @click.prevent='approve(claim)'>Approve</b-button>
            </b-col>
            <b-col cols="auto" class="mr-auto">
              <b-button variant='danger' size='sm' @click.prevent='reject(claim)'>Reject</b-button>
            </b-col>
            <b-col cols="auto" align-self="end">
              <b-button variant='danger' size='sm' @click.prevent='remove(claim)'>Remove</b-button>
            </b-col>
          </b-row>
          <b-row v-else class='mb-2'>
            <b-col cols="auto" class="mr-auto">
              <b-button variant='danger' size='sm' @click.prevent='remove(claim)'>Remove</b-button>
            </b-col>
            <b-col cols="auto" align-self="end">
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
        No claims
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  import moment from 'moment-timezone'
  import Loading from 'vue-loading-overlay'
  import api from '@/api.js'

  export default {
    name: 'FeedClaim',
    props: {
      feedId: String
    },
    data() {
      return {
        modalLoading:false,
        loading: false,
        isNew: false,
        ref: null,
        modalClaim: null,
        forceApprove: false,
        claims: []
      }
    },
    watch: {
      feedId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.isNew = false
          this.claims = []
          this.bind(id)
        }
      }
    },
    methods: {
      bind: async function(id) {
        if (id === '$//newFeed') {
          try {
            await this.$rtdbUnbind('claims', () => ([]))
          } catch(e) {
            //Ok
          }
          this.ref = null
          this.isNew = true
          this.claims = []
          return
        }
        if (id) {
          // this.ref = firebase.database().ref('feed_claim').orderByChild('feedId').equalTo(id)
          this.loading = true
          try {
            await this.$rtdbBind('claims', this.ref)
          } catch(e) {
            //Ok
          }
          this.loading = false
        } else {
          try {
            await this.$rtdbUnbind('claims', () => ([]))
          } catch(e) {
            //Ok
          }
        }
      },
      approve: function(claim) {
        this.forceApprove = false
        this.modalClaim = claim
        this.$refs['approve-confirm'].show()
      },
      remove: function(claim) {
        this.forceApprove = false
        this.modalClaim = claim
        this.$refs['remove-confirm'].show()
      },
      confirmRemoveClaim: async function() {
        this.$refs['remove-confirm'].hide()
        this.modalLoading = true
        try {
          // await firebase.database().ref('feed_claim').child(this.modalClaim.id).set(null)
        } catch(e) {
          alert(e)
        }
        this.modalLoading = false
      },
      confirmApproveClaim: async function() {
        this.$refs['approve-confirm'].hide()
        this.modalLoading = true
        try {
          await api.call('feed/approve_claim', {claimId: this.modalClaim.id, force:this.forceApprove})
        } catch(e) {
          if (e && e.response && e.response.status === 501) {
            this.$bvModal.msgBoxOk(`Can't approve claim because of existance of user feed`)
          } else {
            alert(e)
          }
        }
        this.modalLoading = false
      },
      reject: function(claim) {
        this.modalClaim = claim
        this.$refs['reject-confirm'].show()
      },
      confirmRejectClaim: async function() {
        this.$refs['reject-confirm'].hide()
        this.modalLoading = true
        try {
          // await firebase.database().ref('feed_claim').child(this.modalClaim.id).update({
          //   status: 'rejected',
          //   update: firebase.database.ServerValue.TIMESTAMP
          // })
        } catch(e) {
          alert(e)
        }
        this.modalLoading = false
      },
    },
    computed: {
      claimsComputed() {
        if (!this.claims) {
          return []
        }
        return this.claims.filter(c => c.status!=='rejected').map((a) => a ).sort((a,b) => b.timestamp - a.timestamp)
      }
    },
    filters: {
      timefy(ts) {
        return moment(ts).format('dddd, MMMM Do YYYY, h:mm:ss a')
      }
    },
    components: {Loading}
  }
</script>
