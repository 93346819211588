<template>
  <div class="stats">
    <loading
      :active.sync="modalLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <b-container class="mt-2" v-if="loading" fluid>
      <div class="loader vld-parent text-center" >
        <loading :active.sync="loading" :height="64" :width="64" :is-full-page="false"></loading>
      </div>
    </b-container>
    <b-container class="mt-2" v-else fluid>
      <div class="top-controls-card mb-3">
        <div class="card">
          <div class="card-body">
            <b-button @click="loadStats" class="mb-2 mr-sm-2">Reload</b-button>
            <!-- Add additional controls here -->
          </div>
        </div>
      </div>
      <div class="stats-card-container mb-3">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="(group, groupName) in stats" :key="groupName">
            <div class="card mb-4">
              <div class="card-header">{{ groupName | formatKey }}</div>
              <div class="card-body">
                <div v-for="(metrics, metricName) in group" :key="metricName">
                  <h5 class="card-title">{{ metricName | formatKey }}</h5>
                  <div class="card-text">
                    <template v-if="typeof metrics === 'object' && KEY_TO_LABEL[Object.keys(metrics)[0]]">
                      <div v-for="(subgroup, subgroupName) in metrics" :key="subgroupName">
                        <h6 class="card-subtitle mt-1 mb-1 text-muted">{{ subgroupName | formatKey }}</h6>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item" v-for="(value, valueKey) in subgroup" :key="valueKey">
                            <strong>{{ valueKey | formatValueKey }}:</strong> {{ [subgroupName, value] | formatValue }}
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item" v-for="(value, valueKey) in metrics" :key="valueKey">
                          <strong>{{ valueKey | formatValueKey }}:</strong> {{ [metricName, value] | formatValue }}
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)

  .top-controls-card
    .card
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
      transition: box-shadow 0.3s ease
      &:hover
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2)

  .stats-card-container
    .card
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
      transition: box-shadow 0.3s ease
      &:hover
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2)

  .card-header
    font-weight: bold
    background-color: #f8f9fa

  .list-group-item
    border-top: none
    &:first-child
      border-top: 1px solid rgba(0, 0, 0, 0.125)
</style>



<script>
// @ is an alias to /src


import Loading from 'vue-loading-overlay'

import api from '@/api.js'
//import moment from 'moment-timezone'

const KEY_TO_LABEL = {
  channel_messages: 'Channel Messages',
  forum_messages: 'Forum Messages',
  dialog_messages: 'Dialog Messages',
  forum_comments: 'Forum Comments',
  posts_stats: 'Posts Stats',
  stream_stats: 'Stream Stats',
  users_stats: 'Users Stats',
  finance_stats: 'Finance Stats',
  channels: 'Channels',
  forums: 'Forums',
  dialogs: 'Dialogs',
  total: 'Total',
  premium: 'Premium',
  active: 'Active',
  deleted: 'Deleted',
  live: 'Live',
  activity: 'Activity',
  ios: 'iOS',
  android: 'Android',
  payout: 'Payout',
  platform_fee: 'Platform Fee',
  tx_count: 'Tx Count',
  tx_amount: 'Tx Amount',
}

const VALUE_TO_LABEL = {
  connections: 'Connections',
  online: 'Online',
  total: 'Total',
}

const KEY_FORMAT = {
  'tx_amount': (value) => `$${value/100}`,
}

export default {
  name: 'Stats',
  data() {
    return {
      modalLoading: false,
      loading: false,
      stats: {},
      KEY_TO_LABEL,
      VALUE_TO_LABEL,
    }
  },
  methods: {
    async loadStats() {
      this.loading = true
      try {
        const { data } = await api.call('api/stats/report', {})
        this.stats = data.result
        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.loadStats()
  },
  filters: {
    formatKey (value) {
      return KEY_TO_LABEL[value]
    },
    formatValueKey (value) {
      return VALUE_TO_LABEL[value] || value
    },
    formatValue (v) {
      if (!Array.isArray(v)) return v
      const [key, value] = v
      return KEY_FORMAT[key] ? KEY_FORMAT[key](value) : value
    },
  },
  components: {
    Loading
  },
}
</script>