<template>
  <div class="messaging-container">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal size='lg' ref="message-editor-modal" hide-footer :title="modalMessage.id ? 'Edit Message' : 'New Message'">
      <div class="d-block text-center">
        <b-row class='mb-2' v-if='modalMessage.id'>
          <b-col offset=3 cols=6 class="col-md-offset-3 col-6 text-center">
            <b-button size='sm' variant="danger" @click="onMessageDelete">Delete</b-button>
          </b-col>
        </b-row>
        <b-input-group class='mb-2'>
            <b-input-group-prepend is-text>
              Type:
            </b-input-group-prepend>
            <b-form-select :disabled='!!modalMessage.id' v-model="modalMessage.type" @input="update(modalMessage, 'type', $event)" :options="availMessageTypes">
              <template v-slot:first>
                <option :value="undefined" disabled>-- Please select a message type --</option>
              </template>
            </b-form-select>
          </b-input-group>
          <b-form-group
              label-cols="2"
              label="Date and time">
              <b-input-group>
                <datetime v-model="modalMessageTs" type='datetime'></datetime>
              </b-input-group>
          </b-form-group>
          <div v-if="modalMessage.type === 'text'">
          <b-form-group
              label-cols="2"
              label="Message text">
              <b-form-textarea
              v-model="modalMessage.content"
              rows="3"
              max-rows="6"
              placeholder=""></b-form-textarea>
            </b-form-group>
          </div>
          <div v-else-if='modalMessage.type'>
            <b-form-group
              label-cols="2"
              label="Message title">
              <b-form-textarea
              v-model="modalMessage.title"
              rows="3"
              max-rows="6"
              placeholder=""></b-form-textarea>
            </b-form-group>
            <b-form-group
              label-cols="2"
              label="Message caption">
              <b-form-textarea
              v-model="modalMessage.caption"
              rows="3"
              max-rows="6"
              placeholder=""></b-form-textarea>
            </b-form-group>
            <div v-if="modalMessage.type === 'link'">
              <b-form-group
                label-cols="2"
                label="Link URL">
                <b-form-input
                v-model="modalMessage.url"
                placeholder="http://example.com/"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Link Title">
                <b-form-input
                v-model="modalMessage.url_title"
                placeholder="Link Title"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Link Description">
                <b-form-textarea
                rows="3"
                max-rows="6"
                v-model="modalMessage.url_description"
                placeholder="Link Description"></b-form-textarea>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="Base64-encoded thumbnail image (15x15)"
                label="Thumbnail Base64">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumb64"
                  placeholder="data:image/png;base64,iVBOR.....">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to image of link preview"
                label="Link Image URL">
                <b-input-group>
                  <b-form-input
                  :disabled='modalMessage.id'
                  v-model="modalMessage.url_image"
                  placeholder="http://example.com/image.png">
                  </b-form-input>
                  <!--<b-input-group-append>
                    <b-button variant="success" @click.prevent="upload(modalMessage, 'imageUrl', 'image/*')">Upload</b-button>
                  </b-input-group-append>
                  -->
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Links Options"
                label-cols="2">
                <b-form-checkbox
                  id="video"
                  v-model="modalMessageFlagVideo"
                  name="video"
                  :value="true"
                  :unchecked-value="false" inline>
                  Is Video
                </b-form-checkbox>
                <b-form-checkbox
                  id="video-extract"
                  v-model="modalMessageFlagVideoExtract"
                  name="video-extract"
                  :value="true"
                  :unchecked-value="false" inline>
                  Can Extract Video
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div v-else-if="modalMessage.type === 'photo'">
              <b-form-group
                label-cols="2"
                description="Base64-encoded thumbnail image (15x15)"
                label="Thumbnail Base64">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumb64"
                  placeholder="data:image/png;base64,iVBOR.....">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to thumbnail image file"
                label="Thumbnail URL">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumbNailUrl"
                  placeholder="http://example.com/thumb.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to image file"
                label="Image URL">
                <b-input-group>
                  <b-form-input
                  :disabled='modalMessage.id'
                  v-model="modalMessage.url_image"
                  placeholder="http://example.com/image.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="Upload Image"
                :disabled='modalMessage.id'
                label-cols="2">
                <b-form-file v-model="file" ref="message_image" @change="uploadImageFile($event, 'message_image')" accept="image/*"></b-form-file>
              </b-form-group>
            </div>
            <div v-else-if="modalMessage.type === 'video'">
              <b-form-group
                label-cols="2"
                description="Base64-encoded thumbnail image (15x15)"
                label="Thumbnail Base64">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumb64"
                  placeholder="data:image/png;base64,iVBOR.....">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to thumbnail image file"
                label="Thumbnail URL">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumbNailUrl"
                  placeholder="http://example.com/thumb.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to video file"
                label="Video URL">
                <b-input-group>
                  <b-form-input
                  v-model="modalMessage.content"
                  placeholder="http://example.com/video.mp4">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Duration">
                <b-form-input
                type="number"
                v-model="modalMessage.duration"
                placeholder=""></b-form-input>
              </b-form-group>
            </div>
            <div v-else-if="modalMessage.type === 'audio'">
              <b-form-group
                label-cols="2"
                description="URL to audio file"
                label="Audio URL">
                <b-input-group>
                  <b-form-input
                  v-model="modalMessage.content"
                  placeholder="http://example.com/podcast.mp3">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Duration">
                <b-form-input
                type="number"
                v-model="modalMessage.duration"
                placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group
                label="Audio Options"
                label-cols="2">
                <b-button variant="outline-primary" @click.prevent="message2podcast(modalMessage)">Turn into podcast</b-button>
              </b-form-group>
            </div>
            <div v-else-if="modalMessage.type === 'audioPhoto'">
              <b-form-group
                label-cols="2"
                description="Base64-encoded thumbnail image (15x15)"
                label="Thumbnail Base64">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumb64"
                  placeholder="data:image/png;base64,iVBOR.....">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to thumbnail image file"
                label="Thumbnail URL">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumbNailUrl"
                  placeholder="http://example.com/thumb.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to image file"
                label="Image URL">
                <b-input-group>
                  <b-form-input
                  :disabled='modalMessage.id'
                  v-model="modalMessage.url_image"
                  placeholder="http://example.com/image.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to audio file"
                label="Audio URL">
                <b-input-group>
                  <b-form-input
                  :disabled='modalMessage.id'
                  v-model="modalMessage.additionalContent"
                  placeholder="http://example.com/podcast.mp3">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Duration">
                <b-form-input
                type="number"
                v-model="modalMessage.duration"
                placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group
                label="Actions"
                label-cols="2">
                <b-button variant="outline-primary" @click.prevent="message2podcast(modalMessage)">Turn into podcast</b-button>
              </b-form-group>
              <b-form-group
                label="Upload Image"
                :disabled='modalMessage.id'
                label-cols="2">
                <b-form-file v-model="file" ref="visphoto-image-input" @change="uploadImageFile($event, 'message_image')" accept="image/*"></b-form-file>
              </b-form-group>
            </div>
            <div v-else-if="modalMessage.type === 'podcast'">
              <b-form-group
                label-cols="2"
                description="Base64-encoded thumbnail image (15x15)"
                label="Thumbnail Base64">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumb64"
                  placeholder="data:image/png;base64,iVBOR.....">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to thumbnail image file"
                label="Thumbnail URL">
                <b-input-group>
                  <b-form-input
                  :disabled='true'
                  v-model="modalMessage.thumbNailUrl"
                  placeholder="http://example.com/thumb.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to image file"
                label="Image URL">
                <b-input-group>
                  <b-form-input
                  :disabled='modalMessage.id'
                  v-model="modalMessage.url_image"
                  placeholder="http://example.com/image.png">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                description="URL to audio file"
                label="Audio URL">
                <b-input-group>
                  <b-form-input
                  v-model="modalMessage.additionalContent"
                  placeholder="http://example.com/podcast.mp3">
                  </b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label-cols="2"
                label="Duration">
                <b-form-input
                type="number"
                v-model="modalMessage.duration"
                placeholder=""></b-form-input>
              </b-form-group>
              <b-form-group
                label="Actions"
                label-cols="2">
                <b-button variant="outline-primary" @click.prevent="podcast2message(modalMessage)">Turn into regular message</b-button>
              </b-form-group>
              <b-form-group
                label="Upload Image"
                :disabled='modalMessage.id'
                label-cols="2">
                <b-form-file v-model="file" ref="podcast-image-input" @change="uploadImageFile($event, 'message_image')" accept="image/*"></b-form-file>
              </b-form-group>
            </div>
          </div>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <div>
            <b-button :disabled='!isMessageValid' variant="success"  @click="onMessageSave">Save</b-button>
           </div>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click='$refs["message-editor-modal"].hide()'>Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-container class='mt-2' >
      <b-row class='mb-2'>
          <b-col offset=3 cols=6 class="col-md-offset-3 col-6 text-center">
            <b-button size='sm' variant="success" @click="onNewMessage" :disabled="true">New Post</b-button>
          </b-col>
      </b-row>
      <b-card v-for="message in messagesOrdered" :key="message.uid" class="mb-2" no-body>
        <Message :message='message' :base-ref='ref' :editable="true" @edit="onMessageEdit"/>
      </b-card>
      <b-container class='mt-2 mb-2' >
        <div class="loader vld-parent text-center" v-if="loading">
            <loading :active.sync="loading"
            :height='64'
            :width='64'
            :is-full-page="false"></loading>
        </div>
        <b-row v-else>
            <b-col offset=3 cols=6 class="col-md-offset-3 col-6 text-center">
              <b-button size='sm' variant="primary" @click="limit+=100">Load More</b-button>
            </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
//import api from '@/api.js'
import uuid from 'uuid/v4'
import moment from 'moment-timezone'
import { Datetime } from 'vue-datetime'
import Loading from 'vue-loading-overlay'
import Message from '@/components/Message'
import api from '@/api.js'
import CryptoJS from "crypto-js";
import { Buffer } from 'buffer'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

const mapping = {
  'feed': 'feed_tweet',
  'channel': 'conversations',
  'forum': 'fourmconver'
}

const loadImage = async (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", err => reject(err));
    img.src = src;
  });
}

const getInfoFromUrl = async (mediaUrl) => {
  if(!mediaUrl) {
    return
  }
  let response = await fetch(mediaUrl)
  let data = await response.blob()
  return { blob: data, mimeType: response.headers.get("Content-Type") }
}

export default {
  name: 'messaging',
  props: {
    channelType: String,
    channelId: String,
  },
  data () {
    return {
      file: null,
      loading: false,
      ref: null,
      shadowMessages: [],
      messages: [],
      limit: 100,
      modalLoading: false,
      modalMessage: {},
      availMessageTypes: [
        {text: "Text", value: "text"},
        {text: "Link Preview", value: "link"},
        {text: "Photo", value: "photo"},
        {text: "Video", value: "video"},
        {text: "Audio", value: "audio"},
        {text: "Audio Photo", value: "audioPhoto"},
        {text: "Podcast", value: "podcast"},
        {text: "Merge", value: "merge"}
      ]
    }
  },
  watch: {
    limit: async function (){
      await this.bindSelf(this.limit)
    }
  },
  mounted: async function (){
    const node = mapping[this.channelType]
    if (!node) {
      return
    }
    // this.ref = firebase.database().ref(`${node}/${this.channelId}`)
    await this.bindSelf(this.limit)
  },
  methods: {
    /*upload: function(obj, prop, type) {
      //this.$set(obj, prop, event)
    },*/
    uploadImage: async function(imageSrc, file, ref, hash) {
      console.log(ref)
      const image = await loadImage(imageSrc)
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      try {
        canvas.getContext('2d').drawImage(image, 0, 0, image.width, image.height)
      } catch(e) {
        console.log(e)
      }
      const dataUrl = canvas.toDataURL('image/jpeg', 0.95)
      const imageInfo = {
        image: dataUrl,
        info: {
          original: {
            width: canvas.width,
            height: canvas.height
          },
          imageSize: {
            width: canvas.width,
            height: canvas.height
          },
          file: file,
          hash: hash
        }
      }
      const buff = Buffer.from(imageInfo.image.replace(/^data:image\/\w+;base64,/, ""),'base64')
      const status = await api.uploadMedia(ref, new Blob([buff]), 'image/jpeg')

      if (this.modalMessage != null) {
        this.modalMessage.content = status.id
      }
    },
    uploadImageFile: function(event, ref) {
      let input = event.target;
      if (input.files && input.files[0]) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            this.modalLoading = true
            try {
              const src = URL.createObjectURL(input.files[0])
              await this.uploadImage(src, input.files[0], ref, CryptoJS.MD5(e.target.result).toString())
            } catch (e) {
              console.log(JSON.stringify(e))
            }
            this.modalLoading = false
            // this.$refs[ref].reset()
          }
          // Start the reader job - read file as a data url (base64 format)
          reader.readAsDataURL(input.files[0]);
      } else {
        this.$refs[ref].reset()
      }
    },
    getActiveTargetForUrlImage() {
      switch (this.modalMessage.type) {
        case 'link':
        case 'audioPhoto':
        case 'podcast':
          return 'message_link_image';
        case 'photo':
          return 'message_image';
        case 'video':
          return 'message_video';
        case 'audio':
          return 'message_audio'
        default:
          return
      }
    },
    uploadMediaByURL: async function(target, mediaSrc) {
      const info = await getInfoFromUrl(mediaSrc);
      if (info.blob && info.mimeType) {
        return api.uploadMedia(target, info.blob, info.mimeType)
      }
    },

    update: function(obj, prop, event) {
      this.$set(obj, prop, event)
    },
    bindSelf: async function(limit) {
      const { data } = await api.call('api/message/list', {
        stream_id: this.channelId,
        stream_type: this.channelType,
        page_limit: limit
      })
      this.shadowMessages = this.messages.slice()
      this.loading = true
      try {
        this.messages = []
      } catch(e) {
        //Ok
      }
      try {
        this.messages = data.map(v => {
          const res = {
            ...v.data,
            ...v.data.data
          };
          delete res.data;
          return res
        })
      } catch(e) {
        //Ok
      }
      this.loading = false
    },
    onMessageEdit(message) {
      this.modalMessage = Object.assign({}, message)
      this.modalMessage.flags = Object.assign({}, message.flags)
      this.$refs["message-editor-modal"].show()
    },
    onNewMessage() {
      this.modalMessage = {}
      this.$refs["message-editor-modal"].show()
    },
    onMessageSave: async function() {
      let id = this.modalMessage && this.modalMessage.id
      let isNew = !id
      let post = {}
      if (isNew) {
        id = uuid()
        if (this.modalMessage != null && this.modalMessage.url_image) {
          const status = await this.uploadMediaByURL(this.getActiveTargetForUrlImage(), this.modalMessage.url_image)
          this.modalMessage[this.modalMessage.type === 'link' ? 'url_image' : 'content'] = status.id
        }

        if (this.modalMessage != null && this.modalMessage.content && this.modalMessage.type !== 'text' && !this.modalMessage.content.includes('upload-')) {
          const status = await this.uploadMediaByURL(this.getActiveTargetForUrlImage(), this.modalMessage.content)
          this.modalMessage['content'] = status.id
        }

        if (this.modalMessage != null && this.modalMessage.additionalContent) {
          const status = await this.uploadMediaByURL('message_audio', this.modalMessage.additionalContent)
          this.modalMessage['additionalContent'] = status.id
        }
        
        post = Object.assign({}, this.modalMessage)

        if (post.type === 'podcast') {
          delete post.url_image
        }
        
        post.id = id
      } else {
        post['ts'] = this.modalMessage.ts
        if (!this.modalMessage.type) return
        if (this.modalMessage.type === 'link') {
          post['url'] = this.modalMessage.url || ""
          post['url_title'] = this.modalMessage.url_title || ""
          post['url_description'] = this.modalMessage.url_description || null
        }
        if (this.modalMessage.flags?.length) {
          post['flags'] = {}
          post['flags']['video'] = this.modalMessage.flags['video'] === true
          post['flags']['video_extract'] = this.modalMessage.flags['video_extract'] === true
          post['flags']['podcast'] = this.modalMessage.type === 'podcast'
        }
        post['type'] = this.modalMessage.type
        post['title'] = this.modalMessage.title || null
        post['caption'] = this.modalMessage.caption || ""
        post['content'] = this.modalMessage.content || ""
        post['additionalContent'] = this.modalMessage.additionalContent || null
        post['duration'] = this.modalMessage.duration || null
      }
      this.modalLoading = true
      try {
        if (isNew) {
          await api.call('api/message/post', {
            stream_id: this.channelId,
            stream_type: this.channelType,
            data: post
          })
        } else {
          // array of non updated fields
          const nonUpdatedFields = ['type', 'url', 'url_title', 'url_description', 'url_image']
          if (post.type != 'text') {
            nonUpdatedFields.push('content')
          } 
          await api.call('api/message/update', {
            id: id,
            stream_type: this.channelType,
            update_fields: Object.fromEntries(Object.entries(post).filter(([_, v]) => v && !nonUpdatedFields.some(key => _ == key)))
          })
        }
        this.$refs["message-editor-modal"].hide()
        this.bindSelf(this.limit)
      } catch(e) {
        //Ok
      }
      this.modalLoading = false
    },
    onMessageDelete: async function() {
      this.modalLoading = true
      const id = this.modalMessage && this.modalMessage.id
      if (!id) {
        return
      }
      try {
        await api.call('api/message/delete', {
          id: id,
          stream_type: this.channelType,
        })
        this.$refs["message-editor-modal"].hide()
      } catch(e) {
        //Ok
      }
      this.modalLoading = false

    },
    podcast2message: async function() {
      if (!this.modalMessage || this.modalMessage.type !== 'podcast') {
        return
      }
      if (this.modalMessage.content) {
        this.modalMessage.type = "audioPhoto"
      } else {
        this.modalMessage.type = "audio"
        this.modalMessage.content = this.modalMessage.additionalContent
        this.modalMessage.additionalContent = null
      }
    },
    message2podcast: async function() {
      if (!this.modalMessage || (this.modalMessage.type !== 'audio' && this.modalMessage.type !== 'audioPhoto')) {
        return
      }
      if (this.modalMessage.type === 'audio') {
        this.modalMessage.additionalContent = this.modalMessage.content
        this.modalMessage.content = null
      }
      this.modalMessage.type = "podcast"
    }
  },
  computed: {
    messagesOrdered() {
      let msgs = this.messages
      if (this.loading) {
        msgs = this.shadowMessages
      }
      return msgs.filter(message => !message.mergeId).slice().reverse()
      /*
      if (this.channelType === 'feed') {
        return this.messages.filter(message => !message.mergeId).slice().reverse()
      }
      return this.messages.filter(message => !message.mergeId)
      */
    },
    modalMessageFlagVideo: {
      get() {
        return this.modalMessage && this.modalMessage.flags && this.modalMessage.flags["video"]
      },
      set(v) {
        if (!this.modalMessage) {
          return
        }
        if (!this.modalMessage.flags) {
          this.$set(this.modalMessage, 'flags', {})
        }
        this.$set(this.modalMessage.flags, 'video', v === true)
      }
    },
    modalMessageFlagVideoExtract: {
      get() {
        return this.modalMessage && this.modalMessage.flags && this.modalMessage.flags["video_extract"]
      },
      set(v) {
        if (!this.modalMessage) {
          return
        }
        if (!this.modalMessage.flags) {
          this.$set(this.modalMessage, 'flags', {})
        }
        this.$set(this.modalMessage.flags, 'video_extract', v === true)
      }
    },
    /*modalMessageFlagPodcast: {
      get() {
        return this.modalMessage && this.modalMessage.flags && this.modalMessage.flags["podcast"]
      },
      set(v) {
        if (!this.modalMessage) {
          return
        }
        if (!this.modalMessage.flags) {
          this.$set(this.modalMessage, 'flags', {})
        }
        this.$set(this.modalMessage.flags, 'podcast', v === true)
      }
    },*/
    modalMessageTs: {
      get() {
        let d = this.modalMessage && this.modalMessage.ts
        return d && moment(d).utc().toISOString() || null
      },
      set(v) {
        this.modalMessage.ts = v && moment(v).valueOf() || null
      }
    },
    isMessageValid() {
      if (!this.modalMessage) {
        return false
      }
      if (this.modalMessage.type === 'text') {
        return this.modalMessage.content && this.modalMessage.content.trim().length > 0
      } else if (this.modalMessage.type === 'link') {
        return this.modalMessage.url_image && this.modalMessage.url && this.modalMessage.url.trim().length > 0
      } else if (this.modalMessage.type === 'audioPhoto') {
        return (this.modalMessage.content || this.modalMessage.url_image) && this.modalMessage.additionalContent
      } else if (this.modalMessage.type === 'podcast') {
        return this.modalMessage.additionalContent
      } else if (this.modalMessage.type === 'merge') {
        return true
      } else {
        return this.modalMessage.content || this.modalMessage.url_image
      }
    }
  },
  components: {Loading, Message, Datetime}
}
</script>















