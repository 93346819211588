<template>
  <div class="feeds">
    <b-container class='mt-2' fluid>
      <b-row>
        <b-col cols="4">
          <FeedList :feedId.sync='selectedFeed'/>
        </b-col>
        <b-col cols="8">
          <FeedInfo :feedId.sync='selectedFeed'/>
          <FeedParser class='mt-2' :feedId.sync='selectedFeed'/>
          <FeedClaim class='mt-2' :feedId.sync='selectedFeed'/>
        </b-col>
      </b-row>
     
   </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FeedList from '@/components/FeedList'
import FeedInfo from '@/components/FeedInfo'
import FeedParser from '@/components/FeedParser'
import FeedClaim from '@/components/FeedClaim'
export default {
  name: 'feeds',
  data () {
    return {
      selectedFeed: ''
    }
  },
  components: {
    FeedList,
    FeedInfo,
    FeedParser,
    FeedClaim
  }
}
</script>
