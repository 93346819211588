<template>
  <div class="feeds">
    <b-container class='mt-2' fluid>
      <b-row>
        <b-col cols="4">
          <ForumListExt :forumId.sync='selectedForum'/>
        </b-col>
        <b-col cols="8">
          <ForumInfo :forumId.sync='selectedForum'/>
          <PremiumInfo class='mt-2' :premiumId='selectedForum ? `premium_${selectedForum}` : ""'/>
          <ForumParser class='mt-2' :forumId.sync='selectedForum'/>
          <ForumClaim class='mt-2' :forumId.sync='selectedForum'/>
        </b-col>
      </b-row>
     
   </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import ForumListExt from '@/components/ForumListExt'
import ForumInfo from '@/components/ForumInfo'
import ForumParser from '@/components/ForumParser'
import ForumClaim from '@/components/ForumClaim'
import PremiumInfo from '@/components/PremiumInfo.vue'

export default {
  name: 'forums',
  data () {
    return {
      selectedForum: ''
    }
  },
  props: {
    forumId: {
      type: String,
      default: ''
    }
  },
  watch: {
    selectedForum () {
      //EventBus.$emit('forumChanged', this.selectedForum)
    },
    forumId () {
      if (this.forumId) {
        this.selectedForum = this.forumId
      }
    }
  },
  mounted () {
    if (this.forumId) {
      this.selectedForum = this.forumId
    }
  },
  components: {
    ForumListExt,
    ForumInfo,
    ForumParser,
    ForumClaim,
    PremiumInfo
  }
}
</script>
