<template>
  <div class="filters">
    <b-container class='mt-2' fluid>
      <b-row>
        <b-col cols="4">
          <FilterList :filterId.sync='selectedFilter' :showGlobalDefault="true"/>
        </b-col>
        <b-col cols="8">
          <FilterInfo :filterId.sync='selectedFilter'/>
        </b-col>
      </b-row>
     
   </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import FilterList from '@/components/FilterList.vue'
import FilterInfo from '@/components/FilterInfo.vue';

export default {
  name: 'forums',
  data () {
    return {
      selectedFilter: ''
    }
  },
  components: {
    FilterList,
    FilterInfo
  }
}
</script>
