<template>
  <div class="forumlist">
    <b-card no-body class="forumlist-card">
      <b-list-group>
        <b-list-group-item variant="primary" class="search-item">
          <!-- search input, not algolia -->
          <b-input-group>
            <b-form-input v-model="search" placeholder="Search" class="search-input"></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="updateSearch" class="search-btn">Search</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-list-group-item>
        <b-list-group-item v-for="item in forums" :key="item.id" :active='forumId===item.id' @click='selectForum(item.id)' button class="forum-item">
          <b-row>
            <b-col>
              <b-avatar :src='item | getImage' :text='item.name | avatary' alt='avatar' class="avatar"></b-avatar>
              <div class='pl-2 forum-name' attribute="name">
                {{ item.name }}
              </div>
              <div class="icon-wrapper">
                <small>
                  <span v-if="item.is_verified" class="verified-icon">✔</span>
                  <span v-if="item.allow_claim" class="claim-icon">🏳️</span>
                  <span v-if="item.is_private" class="claim-icon">🔒</span>
                  <span v-if="item.premium_id" class="claim-icon">💎</span>
                </small>
              </div>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="loading" class="forum-item">
          <b-row>
            <b-col class="text-center">
              <b-spinner></b-spinner>
            </b-col>
          </b-row>
        </b-list-group-item>
        <!--item to load more -->
        <b-list-group-item v-if="forums.length > 0" @click="loadMore" button>
          <b-row>
            <b-col>
              <b-button variant="primary" block>Load more</b-button>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<style scoped>
  .forumlist-card {
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .search-item {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .search-input {
    border-radius: 0.5rem;
  }

  .search-btn {
    border-radius: 0.5rem;
    background-color: #007bff;
    border-color: #007bff;
  }

  .forum-item {
    padding: 0.75rem 1.25rem;
  }

  .forum-item b-row {
    align-items: center;
  }

  .forum-item .pl-2 {
    display: inline;
  }

  .forum-item small {
    font-weight: 600;
  }
  .avatar {
    margin-right: 0.5rem;
    background-color: #f8f9fa;
  }

  .forum-name {
    font-weight: 600;
    display: inline;
  }

  .icon-wrapper {
    display: inline;
  }

  .verified-icon,
  .claim-icon {
    font-weight: 600;
    margin-left: 0.5rem;
  }

  .verified-icon {
    color: #28a745;
  }

  .claim-icon {
    color: #ffc107;
  }
</style>

<script>
  import api from '@/api.js'
  import EventBus from '@/event-bus.js'

  export default {
    name: 'ForumListExt',
    data() {
      return {
        timer: null,
        cacheTimeout: null,
        loading: false,
        forums: [],
        search: '',
        saved_search: ''
      }
    },
    props: {
      forumId: String
    },
    mounted() {
      //this.timer = setInterval(this.updateSearch, 10000)
      EventBus.$on('forumChanged', () => {
        this.updateSearch()
      })
      this.updateSearch()
    },
    beforeDestoy() {
      EventBus.$off('forumChanged')
      
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    watch: {
      search() {
        //debounce
        if (this.cacheTimeout) {
          clearTimeout(this.cacheTimeout)
        }
        this.cacheTimeout = setTimeout(() => {
          this.updateSearch()
          this.cacheTimeout = null
        }, 500)
      }
    },
    methods: {
      async updateSearch() {
        try {
          this.loading = true
          const operation = this.$operationTracker.registerOperation(this, 'api/forum/search')
          const { data } = await api.call('api/forum/search', { needle: this.search, limit: 20 });
          if (operation.isCanceled()) {
            return
          }
          this.forums = data.map(entry => {
            return entry.data 
          }).filter(e => e)
          this.saved_search = this.search
          //console.log(this.forums)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      async loadMore() {
        try {
          this.loading = true

          const timestamp_offset = this.forums[this.forums.length - 1]?.created_at

          const { data } = await api.call('api/forum/search', { needle: this.saved_search, timestamp_offset: timestamp_offset, limit:20 });
          this.forums = this.forums.concat(data.map(entry => {
            return entry.data 
          }).filter(e => e))
          //console.log(this.forums)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      selectForum(forumId) {
        //this.selectedFeed = feedId
        this.$emit('update:forumId', forumId)
        //this.parserStatus([feedId])

      }
    },
    filters: {
      avatary(text) {
        if (!text) return ''
        const s = text.trim()
        const sp = s.split(' ')
        if (sp.length > 1) {
          const s1 = sp[0]
          const s2 = sp[1]
          const l1 = (s1.length > 2 ? s1.substring(0, 1) : s1).toUpperCase()
          const l2 = (s2.length > 2 ? s2.substring(0, 1) : s2).toUpperCase()
          return `${l1}${l2}`
        } else {
          return (s.length > 1 ? s.substring(0, 1) : s).toUpperCase()
        }
      },
      getImage(item) {
        return api.imageURL(item.image)
      }
    }
  }
</script>
