<template>
  <div class="filterlist w-100">
    <div class="loader vld-parent text-center" v-if="loading">
      <loading :active.sync="loading"
      :height='64'
      :width='64'
      :is-full-page="false"></loading>
    </div>
    <b-card v-else no-body>
      <b-list-group>
        <b-list-group-item v-if='showNew' class='text-center' variant="success" :active='filterId==="$//newFilter"' @click='selectFilter("$//newFilter")' button>
          <div>New filter</div>
        </b-list-group-item>
        <b-list-group-item class='text-center' v-for="filter in filters" :key="filter.id" :active='filterId===filter.id' @click='selectFilter(filter.id)' button>
          <div>{{filter.name}}</div>
          <span v-if="filter.is_global_default" class="badge badge-pill badge-primary">global</span>
        </b-list-group-item>
        <!--for empty list -->
        <b-list-group-item v-if='!hasUserFilter && userId && showNewDefault' class='text-center' variant="success" :active='filterId==="$//newDefFilter"' @click='selectFilter("$//newDefFilter")' button>
          <div>Create default filter</div>
        </b-list-group-item>
        <b-list-group-item v-if='filters.length===0' class='text-center'>
          <div>No filters</div>
        </b-list-group-item>  
      </b-list-group>
    </b-card>
  </div>
</template>


<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>



<script>
  import api from '@/api.js'
  import EventBus from '@/event-bus.js'
  import Loading from 'vue-loading-overlay'

  export default {
    name: 'FilterList',
    data() {
      return {
        timer: null,
        cacheTimeout: null,
        filters: [],
        loading: false,
        updatedFiltersFn: null,
        createdFilterFn: null,
        deletedFilterFn: null,
        attachedFilterFn: null,
        detachedFilterFn: null

      }
    },
    props: {
      filterId: String,
      userId: {
        type: String,
        default: null
      },
      showNew: {
        type: Boolean,
        default: true
      },
      showNewDefault: {
        type: Boolean,
        default: false
      },
      showGlobalDefault: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      //this.timer = setInterval(this.updateFilter, 10000)
      this.createdFilterFn = (id) => {
        console.log('createdFilterFn', id)
        //if (this.userId) {
          this.updateFilters()
        //}
      }
      this.updatedFiltersFn = (id) => {
        console.log('updatedFiltersFn', id)
        if (this.filters.find(e => e.id === id)) {
          this.updateFilters()
        }
      }
      this.deletedFilterFn = (id) => {
        console.log('deletedFilterFn', id)
        this.filters = this.filters.filter(e => e.id !== id)
      }
      this.attachedFilterFn = (arg) => {
        let { filter_id, user_id } = arg
        console.log('attachedFilterFn', filter_id, user_id)
        if (user_id === this.userId) {
          this.updateFilters()
        }
      }
      this.detachedFilterFn = (arg) => {
        let { filter_id, user_id } = arg
        console.log('detachedFilterFn', filter_id, user_id)
        if (user_id === this.userId) {
          this.updateFilters()
        }
      }
      EventBus.$on('filter-updated', this.updatedFiltersFn)
      EventBus.$on('filter-deleted', this.deletedFilterFn)
      EventBus.$on('filter-created', this.createdFilterFn)
      EventBus.$on('filter-attached', this.attachedFilterFn)
      EventBus.$on('filter-detached', this.detachedFilterFn)
   
      this.updateFilters()
    },
    beforeDestoy() {
      EventBus.$off('filter-updated', this.updatedFiltersFn)
      EventBus.$off('filter-deleted', this.deletedFilterFn)
      EventBus.$off('filter-created', this.createdFilterFn)
      EventBus.$off('filter-attached', this.attachedFilterFn)
      EventBus.$off('filter-detached', this.detachedFilterFn)
      
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    watch: {
      userId() {
        this.updateFilters()
      }
    },
    methods: {
      filterUpdated(id) {
        console.log('filterUpdated', id)
        if (this.filters.find(e => e.id === id)) {
          this.updateFilters()
        }
      },
      filterDeleted(id) {
        console.log('filterDeleted', id)
        this.filters = this.filters.filter(e => e.id !== id)
      },
      filterCreated(id) {
        console.log('filterCreated', id)
        this.updateFilters()
      },
      filterAttached(arg) {
        let { filter_id, user_id } = arg
        console.log('filterAttached', filter_id, user_id)
        if (user_id === this.userId) {
          this.updateFilters()
        }
      },
      filterDetached(arg) {
        let { filter_id, user_id } = arg
        console.log('filterDetached', filter_id, user_id)
        if (user_id === this.userId) {
          this.updateFilters()
        }
      },

      async updateFilters() {
        try {
          
          this.filters = []
          const args = {}
          if (this.userId) {
            args.user_id = this.userId
          } else {
            args.except_default = true
          }
          if (!this.showGlobalDefault) {
            args.except_global_default = true
          }
          this.loading = true
          const { data } = await api.call('api/filtering/list', args);
          this.filters = data.map(entry => {
            return entry.data 
          }).filter(e => e)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      selectFilter(filterId) {
        //this.selectedFeed = feedId
        this.$emit('update:filterId', filterId)
        console.log('selectFilter', filterId)
        //this.parserStatus([feedId])

      }
    },
    computed: {
      //has user specific filter or not (user_id is set)
      hasUserFilter() {
        return this.filters.some(f => f.user_id)
      }
    },
    components: {
      Loading
    }
  }
</script>
