<template>
<b-card title="Edit/Select Category">
    <!-- Categories List Section -->
    <div class="mb-4">
        <h5>Categories</h5>
        <ul class="list-group">
            <li v-if="is_loading" class="list-group-item text-muted">
                <b-spinner small></b-spinner>
            </li>
            <li v-for="category in categories" :key="category.id" 
                @click="setActiveCategory(category)" 
                :class="['list-group-item', { 'bg-primary text-white': activeCategory && category.id === activeCategory.id }, { 'list-group-item-action': !is_loading }]"
                style="cursor: pointer; padding: 10px;"> <!-- Adjust padding as needed -->
                <div class="d-flex justify-content-between align-items-center">
                    <span class="font-weight-bold">{{ category.name }}</span>
                    <span class="badge badge-secondary">{{ category.order_index || 0 }}</span>
                </div>
                <div :class="['small', 
                        { 'text-white': activeCategory && category.id === activeCategory.id },
                        { 'text-muted': !(activeCategory && category.id === activeCategory.id) }
                    ]" 
                    style="max-height: 1.2em; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                    {{ category.description || 'No description' }}
                </div>
            </li>
            <li v-if="categories.length === 0" class="list-group-item text-muted">No categories found</li>
        </ul>
        <div class="d-flex justify-content-between mt-3">
            <b-button variant="success" @click="selectCategory(activeCategory)" :disabled="!activeCategory">Select</b-button>
            <b-button v-b-toggle.add-category-section variant="outline-primary" @click="hideEditSection" >New Category</b-button>
            <b-button v-b-toggle.edit-category-section variant="outline-secondary" @click='hideAddSection' :disabled="!activeCategory">Edit Category</b-button>
        </div>
    </div>


    <!-- Toggle button -->  
    <!-- Expandable 'Add Category' Section -->
    <b-collapse id="add-category-section" :visible="false" class="mt-3" ref="addCategorySection">
        <div>
            <h5>New Category</h5>
            <b-form @submit.prevent="createCategory(categoryName, categoryDescription)">
                <b-form-group label="Name">
                    <b-form-input v-model="categoryName" placeholder="Enter category name" />
                </b-form-group>
                <b-form-group label="Description">
                    <b-form-textarea v-model="categoryDescription" placeholder="Enter category description"></b-form-textarea>
                </b-form-group>
                <b-form-group label="Priority">
                    <b-form-input type="number" v-model.number="categoryPriority" placeholder="Priority" />
                </b-form-group>
                <b-button type="submit" variant="primary" :disabled="!categoryName">Create Category
                    <b-spinner v-if="is_create_category_loading" small class="ml-2"></b-spinner>
                </b-button>
            </b-form>
        </div>
    </b-collapse>


    <!-- Toggle button for Edit Category Section -->
    
    <!-- Expandable 'Edit Category' Section -->
    <b-collapse id="edit-category-section" :visible="false" class="mt-3" ref="editCategorySection">
        <div v-if="activeCategory">
            <h5>Edit Category</h5>
            <b-form @submit.prevent="updateCategory(activeCategory)">
                <b-form-group label="Name">
                    <b-form-input v-model="activeCategory.name" placeholder="Enter category name" />
                </b-form-group>
                <b-form-group label="Description">
                    <b-form-textarea v-model="activeCategory.description" placeholder="Enter category description"></b-form-textarea>
                </b-form-group>
                <b-form-group label="Priority">
                    <b-form-input type="number" v-model.number="activeCategory.order_index" placeholder="Priority" />
                </b-form-group>
                <div class="d-flex justify-content-between">
                    <b-button type="submit" variant="success" :disabled="!activeCategory.name">Save
                        <b-spinner v-if="is_update_category_loading" small class="ml-2"></b-spinner>
                    </b-button>
                    <b-button size="sm" variant="danger" :disabled="!activeCategory" @click="deleteCategory(activeCategory)">
                        Delete
                        <b-spinner v-if="is_delete_category_loading" small class="ml-2"></b-spinner>
                    </b-button>
                </div>
            </b-form>
        </div>
    </b-collapse>
    <!-- Error Display -->
    <!--dismissible -->
    <b-alert class='mt-2' variant="danger" :show="!!errorLabel" dismissible @dismissed="clearError">
        {{ errorLabel }}
    </b-alert>
</b-card>
</template>
  

<script>

import api from '@/api.js'

let category_cache = {};
export default {
    name: 'CategorySelector',
    data: () => ({
        categories: [],
        is_loading: false,
        errorLabel: '',
        activeCategory: null,
        categoryName: '',
        categoryDescription: '',
        categoryPriority: 0,
        is_create_category_loading: false,
        is_update_category_loading: false,
        is_delete_category_loading: false
    }),
    props: {
        streamType: {
            type: String,
            required: true
        }
    },
    beforeMount() {
        if (category_cache[this.streamType]) {
            this.categories = category_cache[this.streamType];
        }
        this.fetchCategories();
    },

    methods: {
        hideAddSection() {
            if (this.$refs.addCategorySection.show) {
                this.$refs.addCategorySection.toggle()
            }
        },
        hideEditSection() {
            if (this.$refs.editCategorySection.show) {
                this.$refs.editCategorySection.toggle()
            }
        },
        getCategoryById(categoryId) {
            return this.categories.find(c => c.id === categoryId);
        },
        async fetchCategories() {
            try {
                this.is_loading = true;
                const { data } = await api.call('api/category/list', { 
                    stream_type: this.streamType
                });
                const res = data.map(record => {
                    const data = record.data;
                    return {
                        id: data.id,
                        name: data.name,
                        description: data.description,
                        order_index: data.order_index || 0
                    }
                }).filter(r => r)
                this.categories = res;
                this.categories.sort((a, b) => b.order_index - a.order_index);
                category_cache[this.streamType] = this.categories.map(c => Object.assign({}, c));
            } catch (e) {
                console.error(e);
            } finally {
                this.is_loading = false;
            }
        },
        async createCategory(name, description, order_index) {
            if (!name) {
                this.errorLabel = 'Name is required';
                return;
            }
            
            try {
                this.is_create_category_loading = true;
                const { data } = await api.call('api/category/create', {
                    stream_type: this.streamType,
                    data: {
                        name,
                        description,
                        order_index: order_index || 0
                    }
                });

                const res = data.data;
                if (res) {
                    this.categories.push(res);
                    //sort by order_index from max to min
                    this.categories.sort((a, b) => b.order_index - a.order_index);
                    console.log(`Created category ${res.name} with id ${res.id} for stream type ${this.streamType}`);
                    this.categoryName = '';
                    this.categoryDescription = '';
                    this.categoryPriority = 0;
                    category_cache[this.streamType] = this.categories.map(c => Object.assign({}, c));
                    this.setActiveCategory(res);
                } else {
                    this.errorLabel = 'Failed to create category';
                }
            } catch (e) {
                console.error(e);
                this.errorLabel = 'Failed to create category';
            } finally {
                this.is_create_category_loading = false;
            }
        },
        async deleteCategory(category) {
            //if category is string, then find in categories.id
            if (typeof category === 'string') {
                category = this.categories.find(c => c.id === category);
            }
            if (!category) {
                return;
            }
            try {
                this.is_delete_category_loading = true;
                const { data } = await api.call('api/category/delete', {
                    stream_type: this.streamType,
                    id: category.id
                });
                if (data.id) {
                    this.categories = this.categories.filter(c => c.id !== category.id);
                    category_cache[this.streamType] = this.categories.map(c => Object.assign({}, c));
                } else {
                    this.errorLabel = 'Failed to delete category';
                }
                if (this.activeCategory && this.activeCategory.id === category.id) {
                    this.setActiveCategory(null);
                }
            } catch (e) {
                console.error(e);
                //if code 409 - then say Category in use
                if (e.response && e.response.status === 409) {
                    this.errorLabel = 'Category in use';
                } else {
                    this.errorLabel = 'Failed to delete category';
                }
            } finally {
                this.is_delete_category_loading = false;
            }
        },
        async updateCategory(category) {
            if (!category) {
                return;
            }
            try {
                this.is_update_category_loading = true;
                const update_fields = {
                    order_index: category.order_index || 0
                }
                const delete_fields = {}
                if (category.name) {
                    update_fields.name = category.name;
                } else {
                    this.errorLabel = 'Name is required';
                    throw new Error('Name is required');
                }
                if (category.description) {
                    update_fields.description = category.description;
                } else {
                    delete_fields.description = true;
                }
                const { data } = await api.call('api/category/update', {
                    stream_type: this.streamType,
                    id: category.id,
                    update_fields: update_fields,
                    delete_fields: delete_fields
                });
                if (data.id) {
                    if (data.data) {
                        this.categories = this.categories.map(c => {
                            if (c.id === category.id) {
                                return data.data
                            }
                            return c;
                        });
                    }
                    //sort by order index, start from max to min
                    this.categories.sort((a, b) => {
                        return b.order_index - a.order_index;
                    });

                    category_cache[this.streamType] = this.categories;
                } else {
                    this.errorLabel = 'Failed to update category';
                }
            } catch (e) {
                console.error(e);
                this.errorLabel = 'Failed to update category';
            } finally {
                this.is_update_category_loading = false;
            }
        },
        setActiveCategory(category) {
            if (!category) {
                this.activeCategory = null;
                return;
            }
            if (typeof category === 'string') {
                category = this.getCategoryById(category);
            }
            this.activeCategory = {
                ...category
            }
        },
        selectCategory(category) {
            if (typeof category === 'string') {
                category = this.getCategoryById(category);
            }
            if (!category) {
                return;
            }
            this.$emit('select', category);
        },
        clearError() {
            this.errorLabel = '';
        }
    },
}
</script>