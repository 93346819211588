import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import EventBus from './event-bus.js'
import InstantSearch from 'vue-instantsearch'
import VuePageTitle from 'vue-page-title'

import OperationTracker from './operation_tracker.js'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(InstantSearch)
Vue.use(OperationTracker)

Vue.prototype.$appName = `Shmuzy Admin ${process.env.NODE_ENV}`
Vue.config.productionTip = false
Vue.prototype.$eventBus = EventBus

Vue.use(VuePageTitle, { router })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')



// const onAuthStateChanged = user => {
//   EventBus.user = user
//   if (user) {
//     if (router.currentRoute.name === 'login') {
//       router.replace('/')
//     }
//   } else {
//     if (router.currentRoute.name !== 'login') {
//       router.replace('/login')
//     }
//   }
// }
