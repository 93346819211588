export default {
    algolia: {
        appId: '0IZ4KKLBSR', // 'JEAGO1YSJN',
        appKey: 'f03e098db011fba9afc18970e559c44e' // 'e446f4640ecd6d181959fee0947d1dda'
    },
    links: {
        'iOSBundleId': 'com.yeshivagram.app',
        'iOSStoreId': '6446692209',
        'androidAppId': 'com.yeshivagram.app',
        'linkPrefix': 'https://duvchat.com/app',
        'linkBase': 'https://duvchat.com',
        'branch_key': 'key_live_bAbkp0stlnIV59ERf4FMFnjavzkf0Lbz'
    },
    backend: {
        apiUrl: "https://cxkyffcgle.execute-api.us-west-2.amazonaws.com/v1/",
        s3Prefix: "https://yeshiva-yeshiva-media.s3.us-west-2.amazonaws.com/",
    }
}
