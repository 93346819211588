<template>
  <div class="finance">
    <!-- Modal for Forum Selection -->
    <b-modal ref="searchForumModal" title="Select Forum" size="lg">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between mb-2">
          <b-button variant="primary" @click="setForumToFilter">Select Forum</b-button>
          <b-button variant="secondary" @click="addForumToFilter">Add Forum</b-button>
        </div>
        <ForumList :forumId.sync='modalForumId' :allowNewForum="false" :hitsPerPage="6" class="mb-2"/>
        <div class="d-flex justify-content-between">
          <b-button variant="primary" @click="setForumToFilter">Select Forum</b-button>
          <b-button variant="secondary" @click="addForumToFilter">Add Forum</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Model for user selection -->
    <b-modal ref="searchUserModal" title="Select User" size="lg">
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between mb-2" v-if="modalUserIdType == 'primary'">
          <b-button variant="primary" @click="setUserToFilter('primary')">Select user</b-button>
          <b-button variant="secondary" @click="addUserToFilter('primary')">Add user</b-button>
        </div>
        <div class="d-flex justify-content-between mb-2" v-else-if="modalUserIdType == 'funding'">
          <b-button variant="primary" @click="setUserToFilter('funding')">Select user</b-button>
          <b-button variant="secondary" @click="addUserToFilter('funding')">Add user</b-button>
        </div>
        <div class="d-flex justify-content-between mb-2" v-else>
          <b-button variant="primary" @click="addUserToFilter('primary')">Add Primary Account</b-button>
          <b-button variant="primary" @click="addUserToFilter('funding')">Add Funding Account</b-button>
          <b-button variant="secondary" @click="$refs['searchUserModal'].hide()">Close</b-button>
        </div>
        <UserList :userId.sync='modalUserId' :userData.sync="modalUserData" :allowNewUser="false" :hitsPerPage="6" class="mb-2"/>
        <div class="d-flex justify-content-between mb-2" v-if="modalUserIdType == 'primary'">
          <b-button variant="primary" @click="setUserToFilter('primary')">Select user</b-button>
          <b-button variant="secondary" @click="addUserToFilter('primary')">Add user</b-button>
        </div>
        <div class="d-flex justify-content-between mb-2" v-else-if="modalUserIdType == 'funding'">
          <b-button variant="primary" @click="setUserToFilter('funding')">Select user</b-button>
          <b-button variant="secondary" @click="addUserToFilter('funding')">Add user</b-button>
        </div>
        <div class="d-flex justify-content-between mb-2" v-else>
          <b-button variant="primary" @click="addUserToFilter('primary')">Add Primary Account</b-button>
          <b-button variant="primary" @click="addUserToFilter('funding')">Add Funding Account</b-button>
          <b-button variant="secondary" @click="$refs['searchUserModal'].hide()">Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Modal for Transactions Details -->
    <!--displays a list of transaction this.transactions -->
    <b-modal ref="transactionDetailsModal" title="Transaction Details" size="lg">
      <div class="d-flex flex-column h-100">
        <div v-if="txLoading" class="text-center">
          <b-spinner></b-spinner>
        </div>
        <div v-else class="d-flex flex-column h-100">
          <b-pagination v-if="transactions.length > txPerPage" v-model="txCurrentPage" :total-rows="transactions.length" :per-page="txPerPage" aria-controls="tx-table"></b-pagination>
          <div class="flex-grow-1">
            <b-table  :items="transactions" :fields="txFields" :current-page="txCurrentPage" :per-page="txPerPage" striped hover responsive>
              <template v-slot:cell(timestamp)="data">
                {{ data.item.timestamp | formateDateToTime }}
              </template>
              <template v-slot:cell(from)="data">
                <a v-if='userLink(data.item.from)' :href="userLink(data.item.from)" target="_blank" :aria-label="data.item.from"> {{ userInfo(data.item, 'from') }} </a>
                <span v-else> {{ userInfo(data.item, 'from') }} </span>
              </template>
              <template v-slot:cell(to)="data">
                <a v-if='userLink(data.item.to)' :href="userLink(data.item.to)" target="_blank" :aria-label="data.item.to"> {{ userInfo(data.item, 'to') }} </a>
                <span v-else> {{ userInfo(data.item, 'to') }} </span>
              </template>
              <template v-slot:cell(amount)="data">
                {{ data.item.amount }} ({{ data.item.amount | toBucks }})
              </template>
              <template v-slot:cell(metadata)="data">
                {{ data.item.metadata?.type }}
                <b-button-group>
                  <b-button v-if='stripeTransfer(data.item)' :href="stripeTransfer(data.item)" target="_blank" variant="primary">Transfer</b-button>
                  <b-button v-if='stripeAccount(data.item)' :href="stripeAccount(data.item)" target="_blank" variant="secondary">Account</b-button>
                  <b-button v-if='forumLink(data.item)' :href="forumLink(data.item)" target="_blank" variant="info">{{ forumName(data.item) }}
                  </b-button>
                  <span v-if="data.item.channel"> {{ channelName(data.item) }}</span>
                </b-button-group>
              </template>
              
              <template v-slot:cell(id)="data">
                <b-button @click="copyToClipboard(data.item.id)" variant="secondary" size="sm">Copy</b-button>
              </template>
            </b-table>
          </div>
          <b-pagination v-if="transactions.length > txPerPage" v-model="txCurrentPage" :total-rows="transactions.length" :per-page="txPerPage" class="my-3" />
        </div>
      </div>
    </b-modal>



    <!-- Transaction Controls -->
    <b-container fluid class="mt-2">
      <b-card class="mb-3">
        <!--<b-card-title>Transaction Controls</b-card-title>-->
        <b-card-text>
          <b-form>
            <!-- Date Range and Step Controls -->
            <!-- Date Range and Step Controls -->
            <div class="mb-4">
                <h4>Date Range and Step Controls</h4>
                <hr>
              <b-form-row>
                  <!-- Start Date Control -->
                  <b-form-group label="Start Date" label-for="start">
                      <b-form-input id="start" v-model="start" type="date" required></b-form-input>
                  </b-form-group>
                  
                  <!-- End Date Control -->
                  <b-form-group label="End Date" label-for="end">
                      <b-form-input id="end" v-model="end" type="date" required></b-form-input>
                  </b-form-group>
                  
                  <!-- Step Interval Control -->
                  <b-form-group label="Step Interval" label-for="step">
                      <b-form-select id="step" v-model="step" :options="steps" required></b-form-select>
                  </b-form-group>
                  
                  <!-- Quick Selection Controls -->
                  <b-form-group label="Quick Start Date Selection" class="ml-2">
                      <b-button-group>
                          <b-button @click="setStartNumAgo(1, 'week')" variant="outline-primary" class="mr-2">1 Week Ago</b-button>
                          <b-button @click="setStartNumAgo(1, 'month')" variant="outline-primary" class="mr-2">1 Month Ago</b-button>
                          <b-button @click="setStartNumAgo(3, 'month')" variant="outline-primary" class="mr-2">3 Months Ago</b-button>
                          <b-button @click="setStartNumAgo(6, 'month')" variant="outline-primary" class="mr-2">6 Months Ago</b-button>
                          <b-button @click="setStartNumAgo(1, 'year')" variant="outline-primary" class="mr-2">1 Year Ago</b-button>
                          <b-button @click="resetStartEnd" variant="outline-secondary">Clear</b-button>
                      </b-button-group>
                  </b-form-group>
              </b-form-row>
            </div>

            
            <!-- From and To Account Controls -->
            <!--<b-form-row>
              <b-form-group label="From Account" label-for="from">
                <b-form-select id="from" v-model="from" :options="accounts_from" required></b-form-select>
              </b-form-group>
              <b-form-group label="To Account" label-for="to">
                <b-form-select id="to" v-model="to" :options="accounts_to" required></b-form-select>
              </b-form-group>
            </b-form-row>
            -->

            <!-- Premium Account Control -->
            <div class="mb-4">
              <h4>Premium Forum Control</h4>
              <hr>
              <b-form-row>
                <b-form-group label="Premium Forum" label-for="premium" class="col-md-12">
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-button @click="$refs['searchForumModal'].show()">Search Forum</b-button>
                    </b-input-group-prepend>
                    <b-form-input id="premium" v-model="premium_id" type="text" required></b-form-input>
                    <b-input-group-append>
                      <b-button @click="clearPremiumId" variant="outline-secondary">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-form-row>
            </div>
            <!-- Source Group -->
            <div class="mb-4">
                <h4>Source Control</h4>
                <hr>
                <!-- From Account Control -->
                <b-form-row>
                    <b-form-group label="User Withdrawal account" label-for="from_up" class="col-md-12">
                        <b-input-group>
                            <!-- Input Field -->
                            <b-form-input id="from_up" v-model="from_up" type="text" required></b-form-input>
                            <b-input-group-append>
                                <b-button @click="clearFromId" variant="outline-secondary">Reset Source</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <!-- Button Row -->
                        <div class="mt-2 d-flex">
                            <div class="mr-2">
                                <b-button @click="showUserModal('from_up', 'primary')" variant="primary">Search by User Payment</b-button>
                            </div>
                        </div>
                    </b-form-group>
                </b-form-row>

                <!-- From Account Control -->
                <b-form-row>
                    <b-form-group label="Creator Comissions/Fees Withdrawals" label-for="from_cw" class="col-md-12">
                        <b-input-group>
                            <!-- Input Field -->
                            <b-form-input id="from_cw" v-model="from_cw" type="text" required></b-form-input>
                            <b-input-group-append>
                                <b-button @click="clearFromId" variant="outline-secondary">Reset Source</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <!-- Button Row -->
                        <div class="mt-2 d-flex">
                            <div class="mr-2">
                                <b-button @click="showUserModal('from_cw', 'funding')" variant="secondary">Search by Content Creator</b-button>
                            </div>
                        </div>
                    </b-form-group>
                </b-form-row>
            

                <!-- From Account Control -->
                <b-form-row>
                    <b-form-group label="Apple/iOS Account" label-for="from_ai" class="col-md-12">
                        <b-input-group>
                            <!-- Input Field -->
                            <b-form-input id="from_ai" v-model="from_ai" type="text" required></b-form-input>
                            <b-input-group-append>
                                <b-button @click="clearFromId" variant="outline-secondary">Reset Source</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <!-- Button Row -->
                        <div class="mt-2 d-flex">
                            <div>
                                <b-button @click="from_ai='system(primary:IOS_IAP)'" variant="danger" class="mr-2">Apple iOS Payment Source</b-button>
                                <b-button @click="from_ai='system(primary:ANDROID_IAP)'" variant="info" class="mr-2">Android Payment Source</b-button>
                                <!-- both -->
                                <b-button @click="from_ai='system(primary:IOS_IAP),system(primary:ANDROID_IAP)'" variant="warning" class="mr-2">Both</b-button>
                            </div>
                        </div>
                    </b-form-group>
                </b-form-row>
            </div>



            <!-- Destination Group -->
            <div class="mb-4">
              <h4>Destination Control</h4>
              <hr>

              <!-- To Account Control -->
              <b-form-row>
                  <b-form-group label="Creator Recipient Account" label-for="to_ic" class="col-md-12">
                      <b-input-group>
                          <!-- Input Field -->
                          <b-form-input id="to_ic" v-model="to_ic" type="text" required></b-form-input>
                          <b-input-group-append>
                              <b-button @click="clearToId" variant="outline-secondary">Reset Destination</b-button>
                          </b-input-group-append>
                      </b-input-group>
                      <!-- Button Row -->
                      <div class="mt-2 d-flex">
                        <div class="mr-2">
                            <!--<b-button @click="showUserModal('to', 'primary')" variant="primary">Search by User Payment</b-button>-->
                            <b-button @click="showUserModal('to_ic', 'funding')" variant="secondary">Search by Content Creator</b-button>
                        </div>
                    </div>
                  </b-form-group>
              </b-form-row>

              <b-form-row>
                  <b-form-group label="Payout/Fee Account" label-for="to_pf" class="col-md-12">
                      <b-input-group>
                          <!-- Input Field -->
                          <b-form-input id="to_pf" v-model="to_pf" type="text" required></b-form-input>
                          <b-input-group-append>
                              <b-button @click="clearToId" variant="outline-secondary">Reset Destination</b-button>
                          </b-input-group-append>
                      </b-input-group>
                      <!-- Button Row -->
                      <div class="mt-2 d-flex">
                        <div>
                            <b-button @click="to_pf='system(primary:STRIPE_PAYOUT)'" variant="danger" class="mr-2">Stripe Payout Destination</b-button>
                            <b-button @click="to_pf='system(primary:PLATFORM_FEE)'" variant="info" class="mr-2">Platform Fee Destination</b-button>
                            <!--both, make comma separated-->
                            <b-button @click="to_pf='system(primary:STRIPE_PAYOUT),system(primary:PLATFORM_FEE)'" variant="warning" class="mr-2">Both</b-button>

                        </div>
                    </div>
                  </b-form-group>
              </b-form-row>
            </div>

          </b-form>
        </b-card-text>

        <!-- Reload Button -->
        <b-card-footer>
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="text-muted hint-text">{{ hintOfAction }}</span>
                    <div class="example-hints mt-2">
                      <small>Examples and explanations:</small>
                      <ul>
                          <li>
                              <small>
                                  <b>iOS/Android IAP → Payment Account (Primary):</b>
                                  This will select all money coming from Apple or Android to the user who made the purchase.
                              </small>
                          </li>
                          <li>
                              <small>
                                  <b>Payment Account (Primary) → Content Creator (Funding):</b>
                                  This traces funds as they move from the user's payment account to the forum's content creator or admin.
                              </small>
                          </li>
                          <li>
                              <small>
                                  <b>Content Creator (Funding) → Payouts/Platform Fee:</b>
                                  This helps to track how many payouts were made and the associated platform fees from the content creator's funding account.
                              </small>
                          </li>
                          <li>
                              <small>
                                  <b>Select only iOS/Android IAP:</b>
                                  To focus on all transactions originating from Apple or Android, irrespective of their destination.
                              </small>
                          </li>
                          <li>
                              <small>
                                  <b>Select only Payouts:</b>
                                  To trace all transactions that result in payouts, regardless of their source.
                              </small>
                          </li>
                      </ul>
                  </div>
                </div>
                <b-button @click="loadStats" variant="primary">
                    {{ stats ? 'Reload Data' : 'Load Data' }}
                </b-button>
            </div>
        </b-card-footer>
      </b-card>

      <!-- Transaction Data Table -->
      <b-card v-if="loading" class="text-center">
        <Loading :active.sync="loading" :can-cancel="false"></Loading>
      </b-card>
      <b-card v-else>
        <b-pagination v-if="stats" v-model="currentPage" :total-rows="stats.count" :per-page="perPage" aria-controls="my-table"></b-pagination>
        <b-table v-if="stats" striped hover :items="stats.steps" :fields="fields" @row-clicked="rowClicked" :currentPage="currentPage" :perPage="perPage" show-foot foot-clone>
          <!-- Column Templates -->
          <template v-slot:cell(start)="row">{{ row.item.start | formatDate }}</template>
          <template v-slot:cell(end)="row">{{ row.item.end | formatDate }}</template>
          <template v-slot:cell(count)="row">{{ row.item.count }}</template>
          <template v-slot:cell(totalAmount)="row">{{ row.item.totalAmount }} ({{ row.item.totalAmount | toBucks }})</template>
          
          <!-- Footer Templates -->
          <template v-slot:foot(count)>Total Count: {{ stats.count }}</template>
          <template v-slot:foot(totalAmount)>Total Amount: {{ stats.totalAmount }} ({{ stats.totalAmount | toBucks }})</template>
        </b-table>
        <b-pagination v-if="stats" v-model="currentPage" :total-rows="stats.steps.length" :per-page="perPage" aria-controls="my-table"></b-pagination>
      </b-card>
    </b-container>  
  </div>
</template>

<style>
.hint-text {
    font-style: italic;
    padding-right: 10px;  /* Some space between the text and the button */
}

.example-hints {
    font-size: 0.85em;
    margin-top: 0.5em;
}

.example-hints ul {
    margin: 0;
    padding-left: 1.5em;
}

.example-hints li {
    list-style-type: circle;
}
</style>

<script>
// @ is an alias to /src
import api from '@/api.js'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import ForumList from '@/components/ForumList.vue'
import UserList from '@/components/UserList.vue';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'; 

/*
const aggregateTransactions = async ({
  from = null,
  to = null,
  start = null,
  end = null,
  step = 24 * 60 * 60 * 1000, // 1 day
  premium_id = null,
  is_combined = false,
} = {}) => {
  ........
      const stepResult = result[0] || { count: 0, totalAmount: 0 };
        return { ...stepResult, start: startPeriod, end: endPeriod };
  }));

  const count = steps.reduce((acc, cur) => acc + cur.count, 0);
  const totalAmount = steps.reduce((acc, cur) => acc + cur.totalAmount, 0);

  return { steps, count, totalAmount };

  const ACCOUNT_ID_STRIPE_PAYOUT = getAccountIdForSystem("STRIPE_PAYOUT", "primary")
const ACCOUNT_ID_PLATFORM_FEE  = getAccountIdForSystem("PLATFORM_FEE", "primary")
const ACCOUNT_ID_IOS_IAP       = getAccountIdForSystem("IOS_IAP", "primary")
const ACCOUNT_ID_ANDROID_IAP   = getAccountIdForSystem("ANDROID_IAP", "primary")
 */
/*
tx:
{
  "_id": {
    "$oid": "63365e49e860f1ff7539a6db"
  },
  "id": "c803946c-d457-41d5-804f-07b231814bb3",
  "from": "user(funding:986ad1b1-2542-41a8-b75e-a7d5e841218b)",
  "to": "system(primary:STRIPE_PAYOUT)",
  "amount": 100,
  "metadata": {
    "transfer": {
      "id": "tr_1LnZcyDlmxbzvIfOeVcBsVT1",
      "object": "transfer",
      "amount": 100,
      "amount_reversed": 0,
      "balance_transaction": "txn_1LnZczDlmxbzvIfOPbYAdMCI",
      "created": 1664507464,
      "currency": "usd",
      "description": null,
      "destination": "acct_1LbFUCDFJ9mmsjjI",
      "destination_payment": "py_1LnZcyDFJ9mmsjjIezhmWu9f",
      "livemode": true,
      "metadata": {
        "account_id": "user(funding:986ad1b1-2542-41a8-b75e-a7d5e841218b)",
        "amount": "100",
        "transaction_id": "c803946c-d457-41d5-804f-07b231814bb3"
      },
      "reversals": {
        "object": "list",
        "data": [],
        "has_more": false,
        "total_count": 0,
        "url": "/v1/transfers/tr_1LnZcyDlmxbzvIfOeVcBsVT1/reversals"
      },
      "reversed": false,
      "source_transaction": null,
      "source_type": "card",
      "transfer_group": null
    },
    "transfer_id": "tr_1LnZcyDlmxbzvIfOeVcBsVT1",
    "stripe_id": "acct_1LbFUCDFJ9mmsjjI",
    "type": "payout"
  },
  "timestamp": 1664507465639
}
*/

//const matches = val.match(/(user|system)\((primary|funding):(.*)\)/)
function getAccountParts(val) {
  const matches = val.match(/(user|system)\((primary|funding):(.*)\)/)
  if (!matches) {
    return {
      type: null,
      tag: null,
      id: null
    }
  }
  return {
    type: matches[1],
    tag: matches[2],
    id: matches[3],
  }
}

export default {
  name: 'Finance',
  data () {
    return {
      loading: false,
      txLoading: false,
      stats: null,
      from_up: null,
      from_cw: null,
      from_ai: null,
      from: null,
      to_ic: null,
      to_pf: null,
      to: null,
      in_update: false,
      start: null,
      end: null,
      step: 24 * 60 * 60 * 1000,
      premium_id: null,
      is_combined: false,
      on_hold: false,
      user_names: {},
      fields: [
        { key: 'start', label: 'Start' },
        { key: 'end', label: 'End' },
        { key: 'count', label: 'Count' },
        { key: 'totalAmount', label: 'Total Amount' },
      ],
      currentPage: 1,
      perPage: 10,
      steps: [
        { text: '1 hour', value: 60 * 60 * 1000},
        { text: '1 day', value: 24 * 60 * 60 * 1000 },
        { text: '1 week', value: 7 * 24 * 60 * 60 * 1000 },
        { text: '1 month', value: 30 * 24 * 60 * 60 * 1000 },
        { text: '1 year', value: 365 * 24 * 60 * 60 * 1000 },
      ],
      accounts_from: [
        { text: 'Any', value: null },
        { text: 'IOS_IAP', value: 'system(primary:IOS_IAP)' },
        { text: 'ANDROID_IAP', value: 'system(primary:ANDROID_IAP)' },
      ],
      accounts_to: [
        { text: 'Any', value: null },
        { text: 'STRIPE_PAYOUT', value: 'system(primary:STRIPE_PAYOUT)' },
        { text: 'PLATFORM_FEE', value: 'system(primary:PLATFORM_FEE)' },
      ],
      modalForumId: null,
      modalUserId: null,
      modalUserData: null,
      modalUserIdTarget: null, // 'from' or 'to'
      modalUserIdType: null, // 'primary' or 'funding'
      transactions: [],
      txFields: [
        { key: 'id', label: 'ID' },
        { key: 'from', label: 'From' },
        { key: 'to', label: 'To' },
        { key: 'amount', label: 'Amount' },
        { key: 'metadata', label: 'Metadata' },
        { key: 'timestamp', label: 'Timestamp' },
      ],
      txCurrentPage: 1,
      txPerPage: 10,

    }
  },
  watch: {
    //when from_up, from_cw, from_ai change, update from and clear other from fields
    //make sure there wont be any recursion
    from_up() {
      if (this.in_update) {
        return
      }
      if (this.from === this.from_up) {
        return
      }
      this.in_update = true
      this.from_cw = null
      this.from_ai = null
      this.from = this.from_up
      //nextTick is needed to avoid recursion
      this.$nextTick(() => {
        this.in_update = false
      })
    },
    from_cw() {
      if (this.in_update) {
        return
      }
      if (this.from === this.from_cw) {
        return
      }
      this.in_update = true
      this.from_up = null
      this.from_ai = null
      this.from = this.from_cw
      this.$nextTick(() => {
        this.in_update = false
      })
    },
    from_ai() {
      if (this.in_update) {
        return
      }
      if (this.from === this.from_ai) {
        return
      }
      this.in_update = true
      this.from_up = null
      this.from_cw = null
      this.from = this.from_ai
      this.$nextTick(() => {
        this.in_update = false
      })
    },
    //when to_ic, to_pf change, update to and clear other to fields
    //make sure there wont be any recursion
    to_ic() {
      if (this.in_update) {
        return
      }
      if (this.to === this.to_ic) {
        return
      }
      this.in_update = true
      this.to_pf = null
      this.to = this.to_ic
      this.$nextTick(() => {
        this.in_update = false
      })
    },
    to_pf() {
      if (this.in_update) {
        return
      }
      if (this.to === this.to_pf) {
        return
      }
      this.in_update = true
      this.to_ic = null
      this.to = this.to_pf
      this.$nextTick(() => {
        this.in_update = false
      })
    },
  },
  methods: {
    async loadStats() {
      this.loading = true
      try {
        let premiums_ids = this.premium_id ? this.premium_id.split(',') : []
        let to_accounts = this.to ? this.to.split(',') : []
        let from_accounts = this.from ? this.from.split(',') : []
        if (premiums_ids.length === 0) {
          premiums_ids = null
        }
        if (to_accounts.length === 0) {
          to_accounts = null
        }
        if (from_accounts.length === 0) {
          from_accounts = null
        }
        const { data } = await api.call('api/stats/finance', {
          from: from_accounts,
          to: to_accounts,
          start: this.start,
          end: this.end,
          step: this.step,
          premium_id: premiums_ids,
          is_combined: this.is_combined,
          on_hold: this.on_hold,
        })
        this.stats = data
        this.currentPage = 1
        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    setStartNumAgo(num, value) {
      this.start = moment().subtract(num, value).startOf('day').format('YYYY-MM-DD')
    },
    resetStartEnd() {
      this.start = null
      this.end = null
    },
    rowClicked(row) {
      this.showTransactions(row.start, row.end)
    },
    copyToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    async showTransactions(start, end) {
      this.$refs['transactionDetailsModal'].show()
      this.transactions = []
      this.txLoading = true
      try {
        let premiums_ids = this.premium_id ? this.premium_id.split(',') : []
        let to_accounts = this.to ? this.to.split(',') : []
        let from_accounts = this.from ? this.from.split(',') : []
        if (premiums_ids.length === 0) {
          premiums_ids = null
        }
        if (to_accounts.length === 0) {
          to_accounts = null
        }
        if (from_accounts.length === 0) {
          from_accounts = null
        }
        const { data } = await api.call('api/stats/transactions', {
          from: from_accounts,
          to: to_accounts,
          start: start,
          end: end,
          step: this.step,
          premium_id: premiums_ids,
          is_combined: this.is_combined,
          on_hold: this.on_hold,
        })
        this.transactions = data
        this.txCurrentPage = 1
        
      } catch (error) {
        console.log(error)
      } finally {
        this.txLoading = false
      }
    },
    addForumToFilter() {
      if (!this.modalForumId) {
        return
      }
      const premiumId = `premium_${this.modalForumId}`
      if (this.premium_id && this.premium_id.includes(premiumId)) {
        this.$refs['searchForumModal'].hide()
        this.modalForumId = null
        return
      }
      if (!this.premium_id) {
        this.premium_id = premiumId
      } else {
        this.premium_id += `,${premiumId}`
      }
      this.$refs['searchForumModal'].hide()
      this.modalForumId = null
    },

    setForumToFilter() {
      if (!this.modalForumId) {
        return
      }
      const premiumId = `premium_${this.modalForumId}`
      this.premium_id = premiumId
      this.$refs['searchForumModal'].hide()
      this.modalForumId = null
    },

    showUserModal(target, type) {
      this.modalUserIdTarget = target
      this.modalUserIdType = type
      this.$refs['searchUserModal'].show()
    },

    addUserToFilter(type) {
      if (!this.modalUserId) {
        return
      }
      if (this.modalUserData) {
        const phone = this.modalUserData.old_phone || this.modalUserData.phone
        this.user_names[this.modalUserId] = phone ? `${this.modalUserData.profile_name} (${phone})` : this.modalUserData.profile_name
      }
      const target = this.modalUserIdTarget;
      const accountId = `user(${type}:${this.modalUserId})`
      if (this[target] && this[target].includes(accountId)) {
        this.$refs['searchUserModal'].hide()
        this.modalUserId = null
        this.modalUserData = null
        this.modalUserIdTarget = null
        return
      }
      if (!this[target]) {
        this[target] = accountId
      } else {
        this[target] += `,${accountId}`
      }
      this.$refs['searchUserModal'].hide()
      this.modalUserId = null
      this.modalUserData = null
      this.modalUserIdTarget = null
    },
    setUserToFilter(type) {
      if (!this.modalUserId) {
        return
      }
      if (this.modalUserData) {
        const phone = this.modalUserData.old_phone || this.modalUserData.phone
        this.user_names[this.modalUserId] = phone ? `${this.modalUserData.profile_name} (${phone})` : this.modalUserData.profile_name
      }
      const target = this.modalUserIdTarget;
      const accountId = `user(${type}:${this.modalUserId})`
      //this[target] = accountId
      //use reactivity
      this.$set(this, target, accountId)
      
      this.$refs['searchUserModal'].hide()
      this.modalUserId = null
      this.modalUserData = null
      this.modalUserIdTarget = null
    },
    clearPremiumId() {
      this.premium_id = null
    },
    clearFromId() {
      this.from = null
      this.from_up = null
      this.from_cw = null
      this.from_ai = null
    },
    clearToId() {
      this.to = null
      this.to_ic = null
      this.to_up = null
    },

    generateAccountMessage(accountsString, type) {
        const accounts = accountsString.split(',');
        const prefix = accounts.length > 1 
            ? `Selected ${type} accounts: `
            : `Selected ${type} account: `;

        const accountsDescription = accounts.map(account => {
            const { type, tag, id } = getAccountParts(account);
            if (type === 'user') {
                const name = this.user_names[id];
                if (tag === 'primary') {
                    return name 
                        ? `Payment account (${name})`
                        : `Payment account (ID: ${id})`;
                } else if (tag === 'funding') {
                    return name 
                        ? `Creator account (${name})`
                        : `Creator account (ID: ${id})`;
                }
            } else {
                return `Virtual account (ID: ${id})`;
            }
        }).join(', ');

        return `${prefix}${accountsDescription}`;
    },

    generatePremiumMessage(premiumsString) {
        const premiums = premiumsString.split(',');
        const prefix = premiums.length > 1 
            ? 'Selected premium forums: ' 
            : 'Selected premium forum: ';

        const premiumsDescription = premiums.map(premium => {
            return `Forum (ID: ${premium})`;
        }).join(', ');

        return `${prefix}${premiumsDescription}`;
    },
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(value).format('MM/DD/YYYY')
      }
    },
    formateDateToTime: function (value) {
      if (value) {
        return moment(value).format('MM/DD/YYYY HH:mm:ss')
      }
    },
    toBucks: function (value) {
      value = (value / 100)
      return '$' + value
    },
  },
  computed: {
    stripeTransfer() {
      return (data) => {
        if (data.metadata && data.metadata.transfer_id) {
          return `https://dashboard.stripe.com/connect/transfers/${data.metadata.transfer_id}`
        }
        return null
      }
    },
    stripeAccount() {
      return (data) => {
        if (data.metadata && data.metadata.stripe_id) {
          return `https://dashboard.stripe.com/connect/accounts/${data.metadata.stripe_id}`
        }
        return null
      }
    },
    userInfo() {
      return (data, opt) => {
        //opt can be 'from' or 'to'
        const val = data[opt]
        
        if (!val) return null
        //const matches = val.match(/(user|system)\((primary|funding):(.*)\)/)
        //const type = matches && matches[1] || null
       //const tag = matches && matches[2] || null
        const { type, tag, id } = getAccountParts(val)
        if (type === 'system') {
          return id 
        }

        const user = data[`${opt}_user`]
        if (!user) return val
        const stream_type = data.metadata && data.metadata.stream_type
        const phone = user.old_phone || user.phone || null
        let name = stream_type == 'forum' ? user.forum_name : user.profile_name
        if (phone) {
          name = `[${phone}] ${name}`
        }
        if (tag === 'funding') {
          name = `${name} (funding)`
        }
        const is_deleted = !!user.deleted_at
        if (is_deleted) {
          name = `${name} [deleted]`
        }
        return name
      }
    },
    forumName() {
      return (data) => {
        if (data.metadata && data.metadata.stream_type === 'forum') {
          if (data.forum && data.forum.name) {
            return data.forum.name
          }
          return 'Forum'
        }
        return null
      }
    },
    channelName() {
      return (data) => {
        if (data.metadata && data.metadata.stream_type === 'channel') {
          if (data.channel && data.channel.name) {
            return data.channel.name
          }
          return 'Channel'
        }
        return null
      }
    },
    userLink() {
      return (account_id) => {
        //account looks like user(primary:uuid) or system(primary:STRIPE_PAYOUT) or system(funding:uuid)
        //detect if it's a user or system account and extract the id
        const { type, id } = getAccountParts(account_id)
        
        if (type === 'user') {
          return `users/${id}`
        } else {
          return null
        }
      }
    },
    forumLink() {
      return (data) => {
        if (data.metadata && data.metadata.stream_id && data.metadata.stream_type === 'forum') {
          return `forums/${data.metadata.stream_id}`
        }
        return null
      }
    },
    hintOfAction() {
      if (!this.from && !this.to && !this.premium_id) {
            return "Select a source or destination account, and/or filter by a premium forum.";
        }

        let messages = [];
        if (this.from) {
            messages.push(this.generateAccountMessage(this.from, 'source'));
        }
        if (this.to) {
            messages.push(this.generateAccountMessage(this.to, 'destination'));
        }
        if (this.premium_id) {
            messages.push(this.generatePremiumMessage(this.premium_id));
        }

        return messages.join(' ');
      },
  },
  components: { Loading, ForumList, UserList },
}
</script>