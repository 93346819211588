<template>
  <div class="stats-info">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-card v-if='statsId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ title }}</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button variant="secondary" class="ml-2" @click="reset">↻</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-container  v-if='stats && stats.id && !loading' fluid>
        <b-row v-for="field in generateFields" :key="field.name">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'><b>{{ field.label }}:</b></span></b-col>
          <b-col :cols="8" align-self="end">
            <div :class="field.sub ? 'text-danger' : ''">{{ field.value }}</div>
          </b-col>
        </b-row>
        <b-row v-if="generateFields.length === 0">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'><b>Stats:</b></span></b-col>
          <b-col :cols="8" align-self="end">
            <div class="text-danger">No stats found</div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card v-else no-body>
      <b-card-header>Stats Info</b-card-header>
      <b-card-body>
        <p class="card-text">Please select a stats to view and edit their info.</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  //import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  //import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  //import EventBus from '@/event-bus.js'
  //import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { flattenObject, generateProductNames } from '@/utils';

  

  const FIELDS_MAP = {
  };
  generateProductNames(FIELDS_MAP, "ios_premium_product")



  export default {
    name: 'StatsInfo',
    data () {
      return {
        stats: null,
        loading: false,
        modalLoading: false,
        loadError: null,
      }
    },
    components: {
      Loading
    },
    props: {
      statsId: String,
      statsType: String,
      title: String,
    },
    watch: {
      statsId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.bind(id)
        }
      },
      statsType: {
        // call it upon creation too
        immediate: true,
        handler: async function () {
          this.bind(this.accountId)
        }
      }
    },
    methods: {
      reset() {
        this.edited = false
        this.bind(this.statsId)
      },
      bind: async function(id) {
        this.loadError = null
        if (id === '$//newaccount') {
          this.isNew = true
          this.setAccount({})
          return
        }
        if (id) {
          if (this.loading) return
          this.loading = true
          this.stats = {}
          try {
            const operation = this.$operationTracker.registerOperation(this, `api/stats/get`)
            const { data } = await api.call('api/stats/get', { id: id, stats_type: this.statsType })
            if (operation.isCanceled()) return
            const stats = data.data
            this.stats = stats
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          }
          this.loading = false
        } else {
          //not bind
        }
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        return moment(value).format('DD.MM.YYYY HH:mm')
      }
    },
    computed: {
      generateFields() {
        const flat = flattenObject(this.stats?.stats)
        const fields = Object.entries(flat).map(([name, value]) => {
          if (typeof value === 'object' && value.group) {
            return {
              name,
              label: FIELDS_MAP[name] || name,
              value: value.key
            }
          }
          return {
            name,
            label: FIELDS_MAP[name] || name,
            value
          }
        })
        return fields
      }
    }
  }

  </script>