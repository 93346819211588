<template>
  <div class="home">
    <b-container class='mt-2' fluid>
      <b-jumbotron>
        <template v-if='isYeshiva' v-slot:header>DuvChat admin panel</template>
        <template v-else v-slot:header>Shmuzy admin panel</template>
        
        <template  v-if='isYeshiva' v-slot:lead>
          This is a managment panel for DuvChat App
        </template>
        <template  v-else v-slot:lead>
          This is a managment panel for Shmuzy App
        </template>

        <hr class="my-4">
        <b-button class='mr-2' variant="primary" to="/feeds">Feeds</b-button>
        <b-button class='mr-2' variant="primary" to="/forums">Forums</b-button>
        <b-button class='mr-2' variant="primary" to="/filters">Filters</b-button>
        <b-button class='mr-2' variant="warning" to="/report_messages">Check message reports</b-button>
        <b-button class='mr-2' variant="warning" to="/report_feeds">Check feed reports</b-button>
        <b-button class='mr-2' variant="secondary" to="/maintenance">Maintenance actions</b-button>
      </b-jumbotron>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import EventBus from '@/event-bus.js'
export default {
  name: 'home',
  data () {
    const TARGET_NAME = process.env.VUE_APP_TARGET_NAME
    const isYeshiva = TARGET_NAME === 'yeshiva'
    return {
      isYeshiva,
      token: EventBus.token
    }
  },
  mounted () {
    console.log('mounted', EventBus.token)
    if (EventBus.token === null) {
      this.$router.push('/login')
    }
  },
  //watch EventBus token and redirect to login if it's null
  watch: {
    token: function (newToken) {
      if (newToken === null) {
        this.$router.push('/login')
      }
    }
  },
}
</script>
