// event-bus.js
import Vue from 'vue';

const AUTH_LOCAL_STORAGE_KEY = 'auth'
const USER_LOCAL_STORAGE_KEY = 'user'

const EventBus = new Vue({
  data() {
    let user = window.localStorage.getItem(USER_LOCAL_STORAGE_KEY) || null
    let token = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || null
    
    try {
      user = JSON.parse(user)
      if (user === "null") user = null
    } catch (e) {
      user = null
    }
    try {
      token = JSON.parse(token)
      token = token && token.token || null
    } catch (e) {
      //ok it is string
    }
    if (user) {
      try {
        const groups = token.split('.')[1]
        //const groupsJson = JSON.parse(Buffer.from(groups, 'base64').toString('ascii'))
        console.log('groups', groups)
        const groupsJson = JSON.parse(atob(groups))
        console.log('groupsJson', groupsJson)
        user.groups = groupsJson?.data?.groups ? groupsJson.data.groups.split(',') : []
        console.log('user.groups', user.groups)
      } catch (e) {
        console.log('error parsing jwt token', e)
        user.groups = []
      }
    }
    if (user && user.superadmin && !user.groups.includes('superadmin')) {
      user.groups.push('superadmin')
    }
    console.log('user', JSON.stringify(user))
    console.log('token', token)
    return {
      user, token
    }
  },
  watch: {
    user () {
      //console.log('updated', this.user)
      this.$emit('USER', this.user)
      window.localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(this.user))
    },
    token () {
      this.$emit('TOKEN', this.token)
      window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, String(this.token))
    }
  },
  computed: {
    isAuthenticated() {
      return !!this.token
    },
    isSuperadmin() {
      if (!this.token) return false
      if (!this.user) return false
      if (!this.user.groups) return false
      return this.user.groups.includes('superadmin')
    },
    isFiltering() {
      if (!this.token) return false
      if (!this.user) return false
      if (!this.user.groups) return false
      return this.user.groups.includes('filtering')
    }
  }

});
export default EventBus;
