<template>
  <div class="maintenance">
    <!--
    <b-modal ref="transfer-messages" size="lg" hide-footer title="Transfer Messages">
      <div class="d-block text-center">
        <b-form-group
              label-cols="2"
              description="Identifier of source feed"
              label="Source feed ID">
              <b-form-input
              v-model="transferSrcFeed"
              placeholder="Source feed"></b-form-input>
            </b-form-group>
        <b-form-group
            label-cols="2"
            description="Identifier of destination feed"
            label="Destination feed ID">
            <b-form-input
            v-model="transferDstFeed"
            placeholder="Destination feed"></b-form-input>
          </b-form-group>
        <b-form-group
            label-cols="2"
            description="Count of recent messages to transfer"
            label="Count">
            <b-form-input
            :disabled='transferCountAll'
            v-model.number="transferCount"
            placeholder="Count (optional)"></b-form-input>
          </b-form-group>
          <b-form-checkbox
                v-model="transferCountAll"
                name="feed-claim-force"
                :value="true"
                :unchecked-value="false" inline>
                Transfer all messages
              </b-form-checkbox>  
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button :disabled='!transferSrcFeed || !transferDstFeed' class="mt-3" variant="success" @click="transferMessages">Transfer</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="$refs['transfer-messages'].hide()">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    -->
    <loading :active.sync="modalLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
    <b-container class='mt-2 ml-2' fluid>
      <b-row>
        Maintenance actions on the server
      </b-row>
      <b-row>
        <b-button class='mt-2'  @click='startOp("hide_all_parsers")'>Hide all parsers</b-button>
      </b-row>
      <b-row>
        <b-button class='mt-2'  @click='startOp("show_all_parsers")'>Show all parsers</b-button>
      </b-row>
      <!--fix_transactions-->
      <b-row>
        <b-button class='mt-2'  @click='startOp("fix_transactions")'>Fix transactions</b-button>
      </b-row>
      <!--fix_accounts-->
      <b-row>
        <b-button class='mt-2'  @click='startOp("fix_accounts")'>Fix accounts</b-button>
      </b-row>
      
  </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import api from '@/api.js'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'; 

export default {
  name: 'maintenance',
  data () {
    return {
      modalLoading: false
    }
  },
  methods: {
    startOp: async function(op) {
      this.modalLoading = true
      try {
        let res = await api.call(`api/system/operation`, {
          id: op,
        })
        let data = res.data || {}
        if (data.status === 'ok') {
          this.$bvModal.msgBoxOk(`Operation has completed`)
        } else {
          this.$bvModal.msgBoxOk(`Problem: ${JSON.stringify(data)}`)
        }
      } catch(e) {
        console.error(e)
        this.$bvModal.msgBoxOk(`Error happened`)
      }
      this.modalLoading = false
    }
  },
  components: {Loading}
}
</script>