<template>
  <div class="premiuminfo">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-card v-if='premiumId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ title }}</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button variant="secondary" class="ml-2" @click="reset">↻</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-container  v-if='premium && premium.id && !loading' fluid>
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>ID:</span></b-col>
          <b-col cols="8" align-self="end">
            <b-form-input v-model="premium.id" readonly></b-form-input>
          </b-col>
        </b-row>
        <!-- amount -->
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Amount:</span></b-col>
          <b-col cols="8" align-self="end">
            <b-form-input v-model="premium.amount" readonly></b-form-input>
          </b-col>
        </b-row>
        <!-- type -->
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Type:</span></b-col>
          <b-col cols="8" align-self="end">
            <b-form-input v-model="premium.type" readonly></b-form-input>
          </b-col>
        </b-row>
        <!-- description -->
        <b-row v-if="premium.metadata">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Description:</span></b-col>
          <b-col cols="8" align-self="end">
            <b-form-input v-model="premium.metadata.description" readonly></b-form-input>
          </b-col>
        </b-row>
        <!-- created_at -->
        <b-row v-if="premium.created_at">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Created at:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext">{{ premium.created_at | formatDate }}</div>
          </b-col>
        </b-row>
        <!-- updated_at -->
        <b-row v-if="premium.updated_at">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Updated at:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext">{{ premium.updated_at | formatDate }}</div>
          </b-col>
        </b-row>

      </b-container>
    </b-card>
    <b-card v-else no-body>
      <b-card-header>Premium Info</b-card-header>
      <b-card-body>
        <p class="card-text">Please select a premium to view and edit their info.</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  //import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  //import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  //import EventBus from '@/event-bus.js'
  //import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'UserTokenInfo',
    data () {
      return {
        premium: null,
        premiumOriginal: null,
        loading: false,
        modalLoading: false,
        loadError: null,
      }
    },
    components: {
      Loading
    },
    props: {
      premiumId: String,
      title: {
        type: String,
        default: 'Premium Info'
      }
    },
    watch: {
      premiumId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.bind(id)
        }
      }
    },
    methods: {
      reset() {
        this.edited = false
        this.bind(this.premiumId)
      },
      setPremium(premium) {
        this.premiumOriginal = premium
        this.premium = Object.assign({
          id: '',
        }, premium || {})
      },
      bind: async function(id) {
        this.loadError = null
        if (id === '$//newpremium') {
          this.isNew = true
          this.setAccount({})
          return
        }
        if (id) {
          if (this.loading) return
          this.loading = true
          try {
            const { data } = await api.call('api/token/get-premium', { id });
            const premium = data.data
            this.setPremium(premium)
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          }
          this.loading = false
        } else {
          //not bind
        }
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        const date = moment(value).format('DD.MM.YYYY HH:mm')
        const timeAgo = moment(value).fromNow()
        return `${date} (${timeAgo})`
      }
    },
    computed: {
    }
  }

  </script>