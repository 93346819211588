<template>
  <div class="report_messages">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal ref="report-delete-confirm" hide-footer title="Confirm deletion">
      <div class="d-block text-center">
        <h4>Are you sure to delete the report (this action cannot be undone)?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmDeleteReport(modalReport)">Delete</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="hideDeleteReport(modalReport)">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="report-close-confirm" hide-footer title="Confirm close report">
      <div class="d-block text-center">
        <h4>Are you sure to close the report (this action cannot be undone)?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="warning" @click="confirmCloseReport(modalReport)">Close</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="hideCloseReport(modalReport)">Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-container class='mt-2' fluid>
      <b-row>
        <b-col cols="4">
          <b-card no-body>
            <b-list-group>
              <b-list-group-item variant="primary">
                Message Reports
              </b-list-group-item>
              <div class="loader vld-parent text-center" v-if="loading">
                <loading :active.sync="loading"
                :height='64'
                :width='64'
                :is-full-page="false"></loading>
              </div>
              <b-list-group-item v-else :variant='report.admin === selfUser ? "success" : null' v-for="report in myReports" :key="report.id" :active='selectedId===report.id' @click='selectReport(report)' button>
                <b-row class='mb-2'>
                  <b-col cols="auto" class="mr-auto"><B>{{report.id}}</B></b-col>
                  <b-col cols="auto" align-self="end">
                    <b-badge>{{report.count}}</b-badge>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>
        <b-col cols="8">
          <b-card header-bg-variant="info" header-text-variant="white" no-body>
            <template v-slot:header>
              <b-row class='mb-2'>
                <b-col cols="auto" class="mr-auto"><B>Report info</B></b-col>
                <b-col v-if='selected' cols="auto" align-self="end">
                  <b-button v-if='!selected.admin' variant='success' size='sm'  @click='takeReport(selected)'>Take</b-button>
                  <b-button v-else-if='selected.admin===selfUser' variant='warning' size='sm'  @click='returnReport(selected)'>Return</b-button>
                </b-col>
              </b-row>
            </template>
            <div class="loader vld-parent text-center" v-if="messageLoading">
                <loading :active.sync="messageLoading"
                :height='64'
                :width='64'
                :is-full-page="false"></loading>
            </div>
            <Message v-else-if='message' :message='message' :base-ref='baseRef'/>
            <div v-else>
              <b-alert variant="danger" v-if='selected' show>Error loading message</b-alert>
              <b-alert v-else show>Please, select the report</b-alert>
            </div>
            <b-card-footer v-if='selected'>
              <b-row class='mb-2'>
                <b-col cols="auto" class="mr-auto">
                  <b-button class='ml-1' size='sm'  variant='danger' @click='closeReport(selected, "delete")'>Delete content</b-button>
                </b-col>
                <b-col v-if='selected' cols="auto" align-self="end">
                  <b-button variant='success' size='sm'  @click='closeReport(selected, "close")'>Close report</b-button>
                </b-col>
              </b-row>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>

  </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import mapping from '@/mapping.js'
import Message from '@/components/Message'
import Loading from 'vue-loading-overlay'

export default {
  name: 'report_messages',
  data () {
    return {
      reports: [],
      loading:false,
      messageLoading: false,
      ref:null,
      selected:null,
      pendingSelect:null,
      message: null,
      baseRef: null,
      modalReport: null,
      modalLoading: false
    }
  },
  watch: {
    reports() {
      if (!this.selected) return
      let found = false
      this.reports.forEach(r => {
        if (r.id === this.selected.id) {
          found = true
          if (!r.admin || r.admin === this.selfUser) {
            this.selected = r
          } else {
            this.selectReport(null)
          }
        }
      })
      if (!found) {
        this.selectReport(null)
      }
    }
  },
  methods: {
    selectReport: async function(report) {
      if (this.selected && report && this.selected.id === report.id) {
        return
      }
      this.selected = report
      this.baseRef = null
      try {
        await this.$rtdbUnbind('message', null)
      } catch(e) {
        //Ok
      }
      if (this.messageLoading) {
        this.pendingSelect = report
        return
      }
      this.pendingSelect = null
      this.messageLoading = false
      if (!report) return
      let channelId = report.channelId
      let chan = mapping.type_2_channel(report.channelType)
      if (!chan || !report.id || !channelId) return

      if (report.tweetId) {
        let comm = mapping.channel_2_comment(chan)
        if (!comm) return
        // this.baseRef = firebase.database().ref(`${comm}/${channelId}/${report.tweetId}`)
      } else {
        let conv = mapping.channel_2_conversation(chan)
        if (!conv) return
        // this.baseRef = firebase.database().ref(`${conv}/${channelId}`)
      }
      let ref = this.baseRef.child(`${report.id}`)
      this.messageLoading = true
      try {
        await this.$rtdbBind('message', ref)
      } catch(e) {
        //Ok
      }
      this.messageLoading = false
      if (this.pendingSelect) {
        this.selectReport(this.pendingSelect)
      }
    },
    takeReport: async function(report) {
      if (!report || !report.id) return
      this.modalLoading = true
      try {
        // await firebase.database().ref('report_message').child(report.id).update({admin: this.selfUser})
      } catch(e) {
        //Ok
      }
      this.modalLoading = false

    },
    returnReport: async function(report) {
      if (!report || !report.id) return
      this.modalLoading = true
      try {
        // await firebase.database().ref('report_message').child(report.id).update({admin: null})
      } catch(e) {
        //Ok
      }
      this.modalLoading = false
    },
    closeReport: async function(report, mode) {
      if (!report || !report.id || !this.baseRef) return
      if (mode === 'delete') {
        this.modalReport = report
        this.$refs['report-delete-confirm'].show()
      } else if (mode === 'close') {
        this.modalReport = report
        this.$refs['report-close-confirm'].show()
      }
    },
    confirmDeleteReport: async function(report) {
      this.$refs['report-delete-confirm'].hide()
      if (!report || !report.id || !this.baseRef) return
      this.modalLoading = true
      try {
        await this.baseRef.child(report.id).remove()
        // await firebase.database().ref('report_message').child(report.id).remove()
      } catch(e) {
        //Ok
      }
      this.modalLoading = false
    },
    hideDeleteReport: async function() {
      this.$refs['report-delete-confirm'].hide()
    },
    confirmCloseReport: async function(report) {
      this.$refs['report-close-confirm'].hide()
      if (!report || !report.id) return
      this.modalLoading = true
      try {
        // await firebase.database().ref('report_message').child(report.id).remove()
      } catch(e) {
        //Ok
      }
      this.modalLoading = false
    },
    hideCloseReport: async function() {
      this.$refs['report-close-confirm'].hide()
    }
  },
  mounted: async function (){
    // this.ref = firebase.database().ref('report_message').orderByChild('count')
    this.loading = true
    try {
      await this.$rtdbBind('reports', this.ref)
    } catch(e) {
      //Ok
    }
    this.loading = false
  },
  computed: {
    selectedId() {
      return this.selected && this.selected.id || null
    },
    myReports() {
      return this.reports.slice().reverse().filter(r => !r.admin || r.admin === this.selfUser)
    },
    selfUser() {
      return this.$eventBus.user && this.$eventBus.user.uid || null
    }
  },
  components: {Loading, Message}
}
</script>
