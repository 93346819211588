<template>
  <div class="home">
    <b-container class='mt-2 main-container' fluid>
      <b-row>
        <b-col cols="4" class="userlist-col">
          <UserList :userId.sync='selectedUser'/>
        </b-col>
        <b-col cols="8" class="user-details-col">
          <UserInfo :userId.sync='selectedUser'/>
          <StatsInfo :statsId.sync='selectedUser' class='mt-2' :statsType="'users'" :title="'Stats'"/>
          <TokenAccountInfo :accountId='selectedUser' accountTag="primary" accountType="user" class='mt-2 user-token-info' :title="'Primary Account'"/>
          <TokenAccountInfo :accountId="selectedUser" accountTag="funding" accountType="user" class='mt-2 user-token-info' :title="'Funding Account'"/>
          <TokenAccountTransactions :accountId='selectedUser' accountTag="primary" accountType="user" class='mt-2 user-token-transactions' :title="'Primary Account Transactions'"/>
          <TokenAccountTransactions :accountId="selectedUser" accountTag="funding" accountType="user" class='mt-2 user-token-transactions' :title="'Funding Account Transactions'"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
  .main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .userlist-col {
    padding-right: 0.5rem;
  }

  .user-details-col {
    padding-left: 0.5rem;
  }

  .user-token-info,
  .user-token-transactions {
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
</style>

<script>
// @ is an alias to /src
import StatsInfo from '@/components/StatsInfo.vue';
import UserInfo from '@/components/UserInfo.vue';
import UserList from '@/components/UserList.vue';
import TokenAccountInfo from '@/components/TokenAccountInfo.vue';
import TokenAccountTransactions from '@/components/TokenAccountTransactions.vue';
//event bus
//import EventBus from '@/event-bus.js';

export default {
  name: 'users',
  data () {
    return {
      selectedUser: ''
    }
  },
  watch: {
    selectedUser () {
      //do nothing for now
    },
    userId () {
      if (this.userId) {
        this.selectedUser = this.userId;
      }
    }
  },
  props: {
    userId: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.userId) {
      this.selectedUser = this.userId;
    }
  },
  methods: {
  },
  computed: {
  },
  components: {
    UserList,
    UserInfo,
    TokenAccountInfo,
    TokenAccountTransactions,
    StatsInfo
}
}
</script>
