<template>
  <div class="w-100">
    <Cropper class='w-100'
        ref="cropper"
        :defaultSize="defaultSize"
        :stencilComponent="getStencil"
        :crossOrigin="true"
        @change='changed'
        :src="image"
      />
    <div class="loader vld-parent text-center" v-if="loading">
      <loading :active.sync="loading"
      :height='64'
      :width='64'
      :is-full-page="false"/>
    </div>
    <b-form-file v-model="file" ref="file-input" @change="uploadImage($event)" accept="image/*" class="mt-2 mb-2"></b-form-file>
    <b-row>
      <b-col cols="auto" class="mr-auto">
        <b-button :disabled='!(!loading && edited && image)' size='sm' variant="success" @click.prevent='done'>Done</b-button>
      </b-col>
      <b-col cols="auto" align-self="end">
        <b-button size='sm' variant="warning" @click.prevent='cancel'>Cancel</b-button>
      </b-col>
    </b-row>

  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  import { Cropper, RectangleStencil, CircleStencil } from 'vue-advanced-cropper'
  import Loading from 'vue-loading-overlay'
  import ColorThief from 'colorthief'
  import CryptoJS from "crypto-js";
  import api from '@/api.js'

  const loadImage = async (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.addEventListener("load", () => resolve(img));
      img.addEventListener("error", err => reject(err));
      img.src = src;
    });
  }

  const rgb2xyz = (color) => {
    const [r,g,b] = color
    let sr = r / 255.0;
    sr = sr < 0.04045 ? sr / 12.92 : Math.pow((sr + 0.055) / 1.055, 2.4);
    let sg = g / 255.0;
    sg = sg < 0.04045 ? sg / 12.92 : Math.pow((sg + 0.055) / 1.055, 2.4);
    let sb = b / 255.0;
    sb = sb < 0.04045 ? sb / 12.92 : Math.pow((sb + 0.055) / 1.055, 2.4);

    return [100 * (sr * 0.4124 + sg * 0.3576 + sb * 0.1805),
            100 * (sr * 0.2126 + sg * 0.7152 + sb * 0.0722),
            100 * (sr * 0.0193 + sg * 0.1192 + sb * 0.9505)]
  }

  const resizeImage = (canvas, size) => {
    const oc = document.createElement('canvas');
    oc.width = size.width;
    oc.height = size.height;
    const ocx = oc.getContext('2d');
    // img.width and img.height will contain the original dimensions
    ocx.drawImage(canvas, 0, 0, size.width, size.height)
    return oc
  }
  const resizeToMax = (canvas, maxSize) => {
    let max = Math.max(canvas.width, canvas.height)
    let scale = maxSize/max
    if (scale < 1) {
      let newSize = {
        width: Math.ceil(Math.max(1, canvas.width*scale)),
        height: Math.ceil(Math.max(1, canvas.height*scale))
      }
      return resizeImage(canvas, newSize)
    } else {
      return canvas
    }
  }

  export default {
    name: 'ImageEdit',
    data() {
      return {
        coordinates: {},
        edited:false,
        image:null,
        file:null,
        md5Hash:null,
        loading: false
      }
    },
    props: {
      inputImage: String,
      stencil:String,
      color: {
        type: Boolean,
        default: false
      },
      thumb64: {
        type: Number,
        default: 0
      },
      quality: {
        type: Number,
        default: 0.80
      },
      maxSize: {
        type: Number,
        default: 1024
      },
    },
    watch: {
      inputImage: {
        // call it upon creation too
        immediate: true,
        handler: async function (img) {
          this.loading = true
          this.image = img
          this.reset()
        }
      },
    },
    methods: {
      reset() {
        //this.$refs['file-input'].reset()
      },
      setImage(image) {
        this.loading = true
        
        this.image = api.imageURL(image)
        //this.image = image
        this.reset()
      },
      changed(change) {
        const {coordinates, canvas} = change
        this.loading = false
        this.edited = this.edited || !canvas ||  (coordinates.width - coordinates.left) !== canvas.width || (coordinates.height - coordinates.top) !== canvas.height
      },
      defaultSize(cropper) {
        return {
          width: cropper.imageWidth,
          height: cropper.imageHeight,
        }
      },
      cancel() {
        this.$emit('cancel')
      },
      done: async function() {
        const {coordinates, canvas} = this.$refs.cropper.getResult()
        this.coordinates = coordinates
        //console.log(`${canvas.width}x${canvas.height}`)
        let output
        if (this.maxSize) {
          output = resizeToMax(canvas, this.maxSize)
        } else {
          output = canvas
        }
        let res = {
          image: output.toDataURL('image/jpeg', this.quality),
          original: {
            width: canvas.width,
            height: canvas.height
          },
          imageSize: {
            width: output.width,
            height: output.height
          },
          file: this.file,
          md5Hash: this.md5Hash
        }
        if (this.color) {
          try {
            const image = await loadImage(canvas.toDataURL())
            const colorThief = new ColorThief()
            res.rgb = await colorThief.getColor(image)
            res.xyz = rgb2xyz(res.rgb)
          } catch (e) {
            console.log(e)
            res.rgb = [0,0,0]
            res.xyz = [0,0,0]
          }
        }
        if (this.thumb64) {
          res.thumb64 = resizeToMax(canvas, this.thumb64).toDataURL('image/png')
        }
        this.$emit('done', res)
      },
      uploadImage(event) {
        // Reference to the DOM input element
        let input = event.target;
        // Ensure that you have a file before attempting to read it
        if (input.files && input.files[0]) {
            // create a new FileReader to read this image and convert to base64 format
            let reader = new FileReader();
            // Define a callback function to run, when FileReader finishes its job
            reader.onload = (e) => {
              this.image = e.target.result
              this.md5Hash = CryptoJS.MD5(e.target.result).toString()
              this.edited = true
            }
            this.file = input.files[0];
            // Start the reader job - read file as a data url (base64 format)
            reader.readAsDataURL(input.files[0]);
        }
      }
    },
    computed: {
      getStencil() {
        if (this.stencil === 'circle') {
          return CircleStencil
        }
        return RectangleStencil
      }
    },
    components:{
      Cropper, Loading
    }
  }
</script>
