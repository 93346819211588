<template>
  <div class="accounttokennfo">
    <b-modal ref="transfer-modal" hide-footer title="Transfer">
      <div class="d-block">
        <b-form-group
          label-cols="2"
          description="Identifier of source account"
          label="Source account ID">
          <b-form-input
            v-model="transfer.from"
            placeholder="Source account"></b-form-input> 
        </b-form-group>
        <b-form-group
          label-cols="2"
          description="Identifier of destination account"
          label="Destination account ID">
          <b-form-input
            v-model="transfer.to"
            placeholder="Destination account"></b-form-input>
        </b-form-group>
        <!--swap button, flip src and dst in-place-->
        <b-button class="mt-3" variant="primary" @click="swapTransferAccounts">Swap</b-button>
        <!--amount--> 
        <b-form-group
          label-cols="2"
          description="Amount of tokens to transfer"
          label="Amount">
          <b-form-input
            v-model.number="transfer.amount"
            placeholder="Amount"></b-form-input>
        </b-form-group>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
          <b-button :disabled='!transfer.amount' class="mt-3" variant="success" @click="transferTokens">Transfer</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="primary" @click="$refs['transfer-modal'].hide()">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-card v-if='accountId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ title }}</span></b-col>
          <b-col cols="auto" align-self="end" >
            <b-button-group v-if="hasControls">
              <b-button variant="primary" @click="showTransferModal(null, rawAccountId)">+</b-button>
              <b-button variant="danger" @click="showTransferModal(rawAccountId, null)">-</b-button>
            </b-button-group>
            <b-button variant="secondary" class="ml-2" @click="reset">↻</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-container  v-if='account && account.id && !loading' fluid>
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>ID:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext">{{ account.id }}</div>
          </b-col>
        </b-row>
        <!--balance -->
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Balance:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext">{{ account.balance }}</div>
          </b-col>
        </b-row>
        <!-- hold -->
        <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Hold:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext"> {{ account.hold }}</div>
          </b-col>
        </b-row>
        <!-- acc_tag if exists -->
        <b-row v-if="account.acc_tag">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Account Tag:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext"> {{ account.acc_tag }}</div>
          </b-col>
        </b-row>
        <!-- acc_type if exists -->
        <b-row v-if="account.acc_type">
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>Account Type:</span></b-col>
          <b-col cols="8" align-self="end">
            <div class="form-control-plaintext"> {{ account.acc_type }}</div>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card v-else no-body>
      <b-card-header>{{ title }}</b-card-header>
      <b-card-body>
        <p class="card-text">Please select a account to view and edit their info.</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  //import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  //import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  //import EventBus from '@/event-bus.js'
  //import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'TokenAccountInfo',
    data () {
      return {
        account: null,
        accountOriginal: null,
        loading: false,
        modalLoading: false,
        loadError: null,
        transfer: {
          from: '',
          to: '',
          amount: 0,
          metadata: {
            message: '',
          }
        },
      }
    },
    components: {
      Loading
    },
    props: {
      accountId: String,
      accountType: {
        type: String,
        default: 'user'
      },
      accountTag: {
        type: String,
        default: 'primary'
      },
      title: String,
      hasControls: {
        type: Boolean,
        default: true
      }
    },
    watch: {
      accountId: {
        // call it upon creation too
        immediate: true,
        handler: async function () {
          this.bind(this.rawAccountId)
        }
      },
      accountType: {
        // call it upon creation too
        immediate: true,
        handler: async function () {
          this.bind(this.rawAccountId)
        }
      },
      accountTag: {
        // call it upon creation too
        immediate: true,
        handler: async function () {
          this.bind(this.rawAccountId)
        }
      },
    },
    methods: {
      swapTransferAccounts() {
        const src = this.transfer.from
        this.transfer.from = this.transfer.to
        this.transfer.to = src
      },
      showTransferModal(srcAccount, dstAccount, amount) {
        this.transfer.from = srcAccount || ''
        this.transfer.to = dstAccount || ''
        this.transfer.amount = amount || 0
        this.$refs['transfer-modal'].show()
      },
      async transferTokens() {
        this.modalLoading = true
        try {
          const { data } = await api.call('api/token/transfer', this.transfer);
          console.log(data)
          const tx = data.tx
          if (tx?.from?.id === this.rawAccountId) {
            this.setAccount(tx.from)
          } else if (tx?.to?.id === this.rawAccountId) {
            this.setAccount(tx.to)
          }
          this.$refs['transfer-modal'].hide()
        } catch(e) {
          //Ok
          this.loadError = e
          console.error(e)
        }
        this.modalLoading = false
      },
      reset() {
        this.edited = false
        this.bind(this.rawAccountId)
      },
      setAccount(account) {
        this.accountOriginal = account
        this.account = Object.assign({
          balance: 0,
          hold: 0,
          id: '',
        }, account || {})
      },
      bind: async function(id) {
        this.loadError = null
        if (id === '$//newaccount') {
          this.isNew = true
          this.setAccount({})
          return
        }
        if (id) {
          const matches = id.match(/(user|system)\((primary|funding):(.*)\)/)
          if (matches) {
            if (!matches[3]) return
          } else {
            return
          }
          if (this.loading) return
          this.loading = true
          try {
            
            const operation = this.$operationTracker.registerOperation(this, `api/token/get-account`)
            const { data } = await api.call('api/token/get-account', { id, raw_account: true });
            if (operation.isCanceled()) return
            const account = data.data
            this.setAccount(account)
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          }
          this.loading = false
        } else {
          //not bind
        }
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        return moment(value).format('DD.MM.YYYY HH:mm')
      }
    },
    computed: {
      rawAccountId: function() {
        return `${this.accountType}(${this.accountTag}:${this.accountId})`
      }
    }
  }

  </script>