<template>
  <div class="userinfo">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal ref="confirm-modal" hide-footer :title="confirm.title">
      <div class="d-block text-center">
        <h4>{{ confirm.message }}</h4>
      </div>
      <b-row>
        <b-col cols="auto" :class="index === 0 ? 'mr-auto' : 'ml-auto'" v-for="button,index in confirm.buttons" :key="index">
          <b-button class="mt-3" :variant="button.variant" @click="$refs['confirm-modal'].hide(); button.click ? button.click($event) : ()=>{}">{{ button.text }}</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="image-editor-modal" size="lg" hide-footer title="Edit image">
      <ImageEdit ref='image-editor'
        :inputImage='imageEditor.image'
        :maxSize='imageEditor.maxSize'
        :thumb64='imageEditor.thumb64'
        :color='imageEditor.color'
        :stencil='imageEditor.stencil'
        @done='saveImage(imageEditor.tag, $event)'
        @cancel='$refs["image-editor-modal"].hide()'/>
    </b-modal>
    <b-card v-if='userId' class="userinfo-card"
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'> User</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button variant="secondary" class="ml-2" @click="reset">↻</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-tabs v-if='user && user.id && !loading' card>
        <b-tab title="General Info">
          <b-row>
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>ID:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.id" readonly></b-form-input>
            </b-col>
          </b-row>
          <b-row v-if="user.deleted_at" class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Deleted at:</span></b-col>
            <b-col cols="8" align-self="end">
              <!-- use "user.deleted_at | formatDate" to format the date, dont use b-form-input, use label instead -->
              <div>{{ user.deleted_at | formatDate }}</div>
            </b-col>
          </b-row>
          <b-row v-if="user.created_at"  class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Created at:</span></b-col>
            <b-col cols="8" align-self="end">
              <!-- use "user.created_at | formatDate" to format the date, dont use b-form-input, use label instead -->
              <div>{{ user.created_at | formatDate }}</div>
            </b-col>
          </b-row>
          <b-row v-if="user.updated_at"  class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Updated at:</span></b-col>
            <b-col cols="8" align-self="end">
              <!-- use "user.updated_at | formatDate" to format the date, dont use b-form-input, use label instead -->
              <div>{{ user.updated_at | formatDate }}</div>
            </b-col>
          </b-row>
          <b-row v-if="user.login_at" class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Last login at:</span></b-col>
            <b-col cols="8" align-self="end">
              <!-- use "user.login_at | formatDate" to format the date, dont use b-form-input, use label instead -->
              <div>{{ user.login_at | formatDate }}</div>
            </b-col>
          </b-row>
          <b-row v-if="user.last_active_at" class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Last active at:</span></b-col>
            <b-col cols="8" align-self="end">
              <!-- use "user.last_active_at | formatDate" to format the date, dont use b-form-input, use label instead -->
              <div>{{ user.last_active_at | formatDate }}</div>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Profile name:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.profile_name" @input='checkEdit("profile_name")'></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Forum name:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.forum_name" @input='checkEdit("forum_name")'></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Feed name:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.feed_name" @input='checkEdit("feed_name")'></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Email:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.email" @input='checkEdit("email")'></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Phone:</span></b-col>
            <b-col cols="8" align-self="end">
              <b-form-input v-model="user.phone" @input='checkEdit("phone")'></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="user.lastMdmData">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>MDM Department:</span></b-col>
            <b-col cols="8" align-self="end">
              <div>{{ user.lastMdmData.department }}</div>
            </b-col>
          </b-row>
          <!-- readonly flags, like from user.groups (superadmin, filtering) or none -->
          <b-row class="mt-3">
            <b-col cols="auto" class="mr-auto"><span class='align-middle'>Flags:</span></b-col>
            <b-col cols="8" align-self="end">
              <!--  create readonly checkboxes for each user.groups -->
              <b-form-checkbox v-for="group in userGroups" :key="group" :value="group" :checked="true" :disabled="true">{{ group }}</b-form-checkbox>
              <div v-if="!(userGroups || []).length">None</div>
            </b-col>
          </b-row>  
        </b-tab>
        <b-tab title="Images">
          <b-card
            title="Profile image"
            sub-title="Select an image to use as the user's profile image">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='getImage("profile_image")' blank-color="#eee" :blank='!getImage("profile_image")' blank-width='200' blank-height='200' width='200' height='200' rounded="circle" alt="Profile image"></b-img>
            </div>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("profile_image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='getImage("profile_image")' size='sm' variant="danger" @click.prevent='removeImage("profile_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
          <b-card
            class='mt-2'
            title="Forum image"
            sub-title="Select an image to use as the user's forum image">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='getImage("forum_image")' blank-color="#eee" :blank='!getImage("forum_image")' blank-width='200' blank-height='200' width='200' height='200' rounded="circle" alt="Forum image"></b-img>
            </div>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("forum_image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='getImage("forum_image")' size='sm' variant="danger" @click.prevent='removeImage("forum_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
          <b-card
            class='mt-2'
            title="Feed image"
            sub-title="Select an image to use as the user's feed image">
            <b-card-body>
            <div class='w-100 text-center'>
              <b-img :src='getImage("feed_image")' blank-color="#eee" :blank='!getImage("feed_image")' blank-width='200' blank-height='200' width='200' height='200' rounded="circle" alt="Feed image"></b-img>
            </div>
            <b-row class='mt-2'>
              <b-col cols="auto" class="mr-auto">
                <b-button size='sm' variant="success" @click.prevent='editImage("feed_image")'>Edit image</b-button>
              </b-col>
              <b-col cols="auto" align-self="end">
                <b-button v-show='getImage("feed_image")' size='sm' variant="danger" @click.prevent='removeImage("feed_image")'>Remove image</b-button>
              </b-col>
            </b-row>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab title="Stripe">
          <b-container v-if="user.stripe">
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe Account:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ user.stripe.accountId }} </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe Details Submitted:</span></b-col>
              <b-col cols="8" align-self="end">
                <div v-if="user.stripe.details_submitted">Yes</div>
                <div v-else>No</div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe Charges Enabled:</span></b-col>
              <b-col cols="8" align-self="end">
                <div v-if="user.stripe.charges_enabled">Yes</div>
                <div v-else>No</div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe State:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ user.stripe.state }} </div>
              </b-col>
            </b-row>
            <b-row class="mt-3" v-if="user.stripe.disabled_reason">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe Disabled Reason:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ user.stripe.disabled_reason }} </div>
              </b-col>
            </b-row>
            <!-- link to stripe account -->
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Stripe Account Link:</span></b-col>
              <b-col cols="8" align-self="end">
                <a :href="'https://dashboard.stripe.com/connect/accounts/' + user.stripe.accountId" target="_blank">Stripe Account</a>
              </b-col>
            </b-row>
          </b-container>
          <b-container v-else>
            <b-row class="mt-3">
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>No Stripe Account</span></b-col>
            </b-row>
          </b-container>
        </b-tab>
      </b-tabs>
      <b-container fluid>
        <b-row class="mt-2 mb-2">
          <b-col cols="auto" class="mr-auto">
            <b-button size='sm' variant="success" @click.prevent='showConfirmUserSave' :disabled="!edited">Save changes</b-button>
          </b-col>
          <b-col cols="auto" align-self="end">
            <b-button size='sm' variant="danger" @click.prevent='showConfirmUserDelete'>Delete user</b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card v-else no-body>
      <b-card-header>User Info</b-card-header>
      <b-card-body>
        <p class="card-text">Please select a user to view and edit their info.</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<style scoped>
  .userinfo-card {
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .header-text {
    font-weight: 600;
  }

  .info-row {
    margin-top: 1rem;
  }

  .label-col {
    font-weight: 600;
  }

  .input-col {
    padding-left: 0.5rem;
  }

  .input-field {
    border-radius: 0.5rem;
  }

</style>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  //import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  //import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  import ImageEdit from '@/components/ImageEdit.vue'
  //import EventBus from '@/event-bus.js'
  //import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  import { processUploads } from '@/utils';
  export default {
    name: 'UserInfo',
    data () {
      return {
        user: null,
        userOriginal: null,
        loading: false,
        modalLoading: false,
        loadError: null,
        uploads: {},
        edited: false,
        imageEditor: {
          tag:null,
          image:null,
          stencil:'rectangle',
          maxSize: 1024,
          thumb64: 0,
          color:false
        },

        //example of confirm modal
        confirm: {
          title: '',
          message: '',
          buttons: [
            {
              text: 'Cancel',
              variant: 'secondary',
            },
            {
              text: 'Confirm',
              variant: 'danger',
              click: () => {}
            }
          ]
        }
      }
    },
    components: {
      Loading,
      ImageEdit
    },
    props: {
      userId: String
    },
    watch: {
      userId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.bind(id)
        }
      }
    },
    methods: {
      showModal(title, message, buttons) {
        this.confirm.title = title
        this.confirm.message = message
        this.confirm.buttons = buttons
        this.$refs["confirm-modal"].show()
      },
      showModalWithCancel(title, message, buttons) {
        this.confirm.title = title
        this.confirm.message = message
        this.confirm.buttons = buttons.concat([
          {
            text: 'Cancel',
            variant: 'secondary',
          }
        ])
        this.$refs["confirm-modal"].show()
      },
      showConfirmUserDelete() {
        this.showModalWithCancel(
          'Delete user',
          'Are you sure you want to delete this user?',
          [
            {
              text: 'Confirm',
              variant: 'danger',
              click: () => {
                this.deleteUser()
              }
            }
          ]
        )
      },
      showConfirmUserSave() {
        this.showModalWithCancel(
          'Save user',
          'Are you sure you want to save this user?',
          [
            {
              text: 'Confirm',
              variant: 'primary',
              click: () => {
                this.saveUser()
              }
            }
          ]
        )
      },
      editImage(name) {
        //console.log('editImage', name, this.getImage(name))
        this.imageEditor.tag = name
        this.imageEditor.image = this.getImage(name)
        if (['profile_image', 'forum_image', 'feed_image'].includes(name)) {
          this.imageEditor.stencil = 'circle'
          this.imageEditor.maxSize = 512
          this.imageEditor.thumb64 = 15
          this.imageEditor.color = false
          this.$refs["image-editor-modal"].show()
        }
      },
      checkEdit(name) {
        if (['profile_image', 'forum_image', 'feed_image'].includes(name)) {
          this.edited = this.uploads[name] !== undefined
        } else {
          this.edited = this.user[name] !== this.userOriginal[name]
        }
      },
      async saveImage(tag, result) {
        console.log('saveImage', tag, result)
        if (['profile_image', 'forum_image', 'feed_image'].includes(tag)) {
          this.$set(this.uploads, tag, {
            image: result.image,
            updates: {
              //thumb64: result.thumb64 //need to make it like 'forum_image_thumb64'
              [tag + '_thumb64']: result.thumb64
            },
            info: {
              imageSize: result.imageSize,
              original: result.original,
              file: result.file,
              hash: result.md5Hash
            }
          })
          //this.user[tag] = result.image
          this.$set(this.user, tag, result.image)
          this.edited = true
          this.$refs["image-editor-modal"].hide()
        }
      },
      removeImage(tag) {
        if (['profile_image', 'forum_image', 'feed_image'].includes(tag)) {
          this.$set(this.uploads, tag, {
            image: null,
            updates: {
              image: null,
              image_thumb64: null
            },
            info: {
              imageSize: null,
              original: null,
              file: null,
              hash: null
            }
          })
          this.$set(this.user, tag, null)
          this.edited = true
        }
      },
      reset() {
        this.edited = false
        this.uploads = {}
        this.bind(this.userId)
      },
      setUser(user) {
        this.userOriginal = user
        this.user = Object.assign({
          profile_name: '',
          forum_name: '',
          feed_name: '',
        }, user || {})
      },
      bind: async function(id) {
        this.loadError = null
        if (id === '$//newuser') {
          this.isNew = true
          this.setUser({})
          return
        }
        try {
          if (id) {
            if (this.loading) return
            this.loading = true
            try {
              const operation = this.$operationTracker.registerOperation(this, `api/user/get`)
              const { data } = await api.call('api/user/get', { id });
              if (operation.isCanceled()) return
              const user = data.data
              this.setUser(user)
            } catch(e) {
              //Ok
              this.loadError = e
              console.error(e)
            }
            this.loading = false
          } else {
            //not bind
          }
        } catch(e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      },
      async saveUser() {
        let update_fields = {}
        let delete_fields = {}
        if (this.user.profile_name !== this.userOriginal.profile_name) {
          update_fields.profile_name = this.user.profile_name
        }
        if (this.user.forum_name !== this.userOriginal.forum_name) {
          update_fields.forum_name = this.user.forum_name
        }
        if (this.user.feed_name !== this.userOriginal.feed_name) {
          update_fields.feed_name = this.user.feed_name
        }
        if (this.user.email !== this.userOriginal.email) {
          update_fields.email = this.user.email
        }
        if (this.user.phone !== this.userOriginal.phone) {
          update_fields.phone = this.user.phone
        }
        try {
          this.modalLoading = true
          const photos = {};
          await processUploads(this.userId, this.uploads, photos)
          if (photos.profile_image !== undefined) {
            if (photos.profile_image) {
              update_fields.profile_image = photos.profile_image
            } else {
              delete_fields.profile_image = true
            }
          }  
          if (photos.forum_image !== undefined) {
            if (photos.forum_image) {
              update_fields.forum_image = photos.forum_image
            } else {
              delete_fields.forum_image = true
            }
          }
          if (photos.feed_image !== undefined) {
            if (photos.feed_image) {
              update_fields.feed_image = photos.feed_image
            } else {
              delete_fields.feed_image = true
            }
          }
          
          const { data } = await api.call('api/user/update', {
            id: this.userId,
            update_fields: update_fields,
            delete_fields: delete_fields
          })
          this.modalLoading = false
          this.edited = false
          this.uploads = {}
          const user = data.data
          this.setUser(user)
        } catch(e) {
          console.error(e)
        } finally {
          this.modalLoading = false
        }
      },
      async deleteUser() {
        try {
          this.modalLoading = true
          await api.call('api/user/delete', {
            id: this.userId
          })
          this.modalLoading = false
          this.$emit('update:userId', '')
        } catch(e) {
          console.error(e)
        } finally {
          this.modalLoading = false
        }
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        const date = moment(value).format('DD.MM.YYYY HH:mm')
        const timeAgo = moment(value).fromNow()
        return `${date} (${timeAgo})`
      }
    },
    computed: {
      userGroups() {
        if (!this.user) return []
        const groups = this.user.groups || []
        if (this.user.superadmin) {
          groups.push('superadmin')
        }
        return groups
      },
      getImage() {
        return (name) => {
          if (this.uploads[name]) {
            const res = this.uploads[name].image || null
            return res
          }
          if (this.user && this.user[name]) {
            return api.imageURL(this.user[name])
          }
          return null
        }
      }
    }
  }

  </script>