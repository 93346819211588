<template>
  <div class="message">
    <div v-if='message'>
      <div class='header w-100'>
        <b-row>
        <b-col cols="auto">
          <b-avatar :text="message.displayName | avatary" :src="message.displayThumb" class="ml-1 mt-1"></b-avatar>
        </b-col>
        <b-col class="pl-0 mr-auto">
          <div><span class="ml-1 mr-auto"><b>{{message.displayName}}</b></span></div>
          <div><span class="ml-1 mr-auto">{{(message.ts || message.timestamp) | timefy}}</span></div>
        </b-col>
        <b-col cols="auto" align-self="end" v-if="editable && !message.cipher">
          <b-button variant='danger' size='sm' class='mr-2 mb-2' @click="$emit('edit', message)">Edit</b-button>
        </b-col>
      </b-row>
      </div>
      <div>
        <div v-if='message.cipher'>
          <b>ENCRYPTED CONTENT</b>
        </div>
        <div v-else-if='message.type==="text"'>
          <div class='ml-1 mr-1'>{{message.content}}</div>
        </div>
        <div v-else-if='message.type==="link"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <div class='ml-1 mr-1' v-if='message.url_title'><small><b>{{message.url_title}}</b></small></div>
          <div class='ml-1 mr-1' v-if='message.url_description'><small>{{message.url_description}}</small></div>
          <div class='ml-1 mr-1' v-if='message.url'><a :href='message.url'><small>{{message.url}}</small></a></div>
          <img class="d-block img-fluid w-100" style='object-fit: contain;' v-if='message.url_image' :src='thumbnailUrl(message.url_image)'/>
        </div>
        <div v-else-if='message.type==="photo"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <img class="d-block img-fluid w-100" style='object-fit: contain;' :src='thumbnailUrl(message.content)'/>
        </div>
        <div v-else-if='message.type==="audioPhoto"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <img class="d-block img-fluid w-100" style='object-fit: contain;' :src='thumbnailUrl(message.content)'/>
          <div class='w-100'>
            <audio controls>
              <source :src="contentUrl(message.additionalContent)">
              Sorry, your browser doesn't support embedded audios.
            </audio>
          </div>
        </div>
        <div v-else-if='message.type==="podcast"'>
          <div class='ml-1 mr-1'><small><b>Podcast</b></small></div>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <img class="d-block img-fluid w-100" style='object-fit: contain;' :src='contentUrl(message.content)'/>
          <div class='w-100'>
            <audio controls>
              <source :src="contentUrl(message.additionalContent)">
              Sorry, your browser doesn't support embedded audios.
            </audio>
          </div>
        </div>
        <div v-else-if='message.type==="video"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <div class='w-100'>
            <b-embed type="video" aspect="4by3" controls :poster='thumbnailUrl(message.content)' class='w-100 video'>
                <source :src="originalUrl(message.content)" >
                Sorry, your browser doesn't support embedded videos.
            </b-embed>
          </div>
        </div>
        <div v-else-if='message.type==="audio"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <div class='w-100'>
            <audio controls>
              <source :src="originalUrl(message.content)" >
              Sorry, your browser doesn't support embedded audios.
            </audio>
          </div>
        </div>
        <div v-else-if='message.type==="merge"'>
          <div v-if="message.title" class='ml-1 mr-1'><b>{{message.title}}</b></div>
          <div class='ml-1 mr-1'>{{message.caption}}</div>
          <div class="loader vld-parent text-center" v-if="mergeLoading">
            <loading :active.sync="mergeLoading"
            :height='64'
            :width='64'
            :is-full-page="false"></loading>
          </div>
          <b-tabs v-else content-class="mt-3">
            <b-tab :title='`${i+1}`' v-for='(merge, i) in mergesSorted' :key='merge.id'
              :caption="merge.id">
                <img v-if='merge.type=="photo" || merge.type=="audioPhoto"'
                  class="d-block img-fluid w-100"
                  style='object-fit: contain;'
                  :src="thumbnailUrl(message.content)"
                  alt="image slot">
                <b-embed type="video" aspect="4by3" controls v-if='merge.type=="video"' :poster='thumbnailUrl(message.content)' class='w-100 video'>
                    <source :src="originalUrl(merge.content)" >
                    Sorry, your browser doesn't support embedded videos.
                </b-embed>
                <div v-if='merge.type=="audioPhoto"' class='w-100'>
                  <audio controls>
                    <source :src="originalUrl(merge.additionalContent)" >
                    Sorry, your browser doesn't support embedded videos.
                  </audio>
                </div>
            </b-tab>
          </b-tabs>
        </div>
        <div v-else>
          {{message}}
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
  .header
    background-color: #111
    color: #fff
  .video
    background-color: #111
</style>

<script>
  import moment from 'moment-timezone'
  import api from '@/api.js'
  import Loading from 'vue-loading-overlay'

  export default {
    name: 'Message',
    props: {
      message: Object,
      baseRef: Object,
      editable: {
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        mergeId: null,
        mergeLoading: false,
        mergeQuery: null,
        merges: [],
        aspect:'3:4'
      }
    },
    watch: {
      message: {
        handler() {
          if (this.message) {
            let w = this.message.mediaAttachmentWidth || 1
            let h = this.message.mediaAttachmentHeight || 1
            let a = w/h
            if (a < 1.5) {
              a = 1.5
            }
            a=6
            this.aspect = `${a}`
          }
          if (this.message && this.message.id && this.message.type === 'merge') {
            this.bindMerge(this.message.id)
          } else {
            this.bindMerge(null)
          }
        },
        deep: true,
        // call it upon creation too
        immediate: true,
      }
    },
    methods: {
      bindMerge: async function(id) {
        if (this.mergeId === id) return
        try {
          await this.$rtdbUnbind('merges', () => ([]))
        } catch(e) {
          //Ok
        }
        if (id && this.baseRef) {
          this.mergeId = id
          if (this.mergeLoading) {
            return
          }
          let query = this.baseRef.orderByChild('mergeId').equalTo(id)
          this.mergeLoading = true
          try {
            await this.$rtdbBind('merges', query)
          } catch(e) {
            //Ok
          }
          this.mergeLoading = false
          if (this.mergeId !== id) {
            this.bindMerge(this.mergeId)
          }
        } else {
          this.mergeId = null
        }
      },
      thumbnailUrl(data) {
        return data && api.buildURL(data, 'thumbnail')
      },
      originalUrl(data) {
        return data && api.buildURL(data, 'original')
      },
    },
    computed: {
      messageComp() {
        return this.message
      },
      mergesSorted() {
        return this.merges.slice().sort((a,b) => {
          let at = a.ts || 0
          let bt = b.ts || 0
          return at - bt
        })
      }
    },
    filters: {
      timefy(ts) {
        return moment(ts).format('dddd, MMMM Do YYYY, h:mm:ss a')
      },
      avatary(text) {
        if (!text) return ''
        const s = text.trim()
        const sp = s.split(' ')
        if (sp.length > 1) {
          const s1 = sp[0]
          const s2 = sp[1]
          const l1 = (s1.length > 2 ? s1.substring(0, 1) : s1).toUpperCase()
          const l2 = (s2.length > 2 ? s2.substring(0, 1) : s2).toUpperCase()
          return `${l1}${l2}`
        } else {
          return (s.length > 1 ? s.substring(0, 1) : s).toUpperCase()
        }
      }
    },
    components: {Loading}
  }
</script>
