<template>
  <div class="accounttokentransactions">
    <loading :active.sync="modalLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-card v-if='accountId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ title }}</span></b-col>
          <b-col cols="auto" align-self="end">
            <b-button variant="secondary" class="ml-2" @click="reset">↻</b-button>
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-container  v-if='accountId' fluid>
        <b-list-group>
          <b-list-group-item v-for="transaction in transactionLimited" :key="transaction.id" :class="classForType(transaction)">
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'><b>ID:</b></span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ transaction.id  }}</div>
              </b-col>
            </b-row>
            <!-- type -->
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Type:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ transactionType(transaction)  }}</div>
              </b-col>
            </b-row>
            <!--amount -->
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Amount:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> <b>{{ transaction.amount  }}</b></div>
              </b-col>
            </b-row>
            <!-- from -->
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>From:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ `${transaction.from ? transaction.from : 'N/A'}` }}</div>
              </b-col>
            </b-row>
            <!-- to -->
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>To:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ `${transaction.to ? transaction.to : 'N/A'}` }}</div>
              </b-col>
            </b-row>
            <!-- date -->
            <b-row>
              <b-col cols="auto" class="mr-auto"><span class='align-middle'>Date:</span></b-col>
              <b-col cols="8" align-self="end">
                <div> {{ transaction.timestamp | formatDate  }}</div>
              </b-col>
            </b-row>
          </b-list-group-item>
          <!-- b-list-group-item show more, it must be a wide button with text in the middle -->
          <b-list-group-item v-if="transactions.length > transactionLimit" class="text-center">
            <b-button variant="outline-primary" @click="showMore">Show More</b-button>
          </b-list-group-item>
          <!-- no transaction item -->
          <b-list-group-item v-else-if="transactions.length === 0" class="text-center">
            <div class="text-muted">No transactions</div>
          </b-list-group-item>
        </b-list-group>
      </b-container>
    </b-card>
    <b-card v-else no-body>
      <b-card-header>{{ title }}</b-card-header>
      <b-card-body>
        <p class="card-text">Please select a account to view and edit their info.</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)

  .transaction-bg-income
    background-color: #d4edda !important
  .transaction-bg-expense
    background-color: #f8d7da !important
</style>

<script>
  //import uuid from 'uuid/v4'
  import moment from 'moment-timezone'
  import api from '@/api.js'
  //import links from '@/links.js'
  import Loading from 'vue-loading-overlay'
  //import EventBus from '@/event-bus.js'
  //import { Buffer } from 'buffer'
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'TokenAccountTransactions',
    data () {
      return {
        transactions: [],
        loading: false,
        modalLoading: false,
        loadError: null,
        transactionLimit: 2,
        transactionLimitIncrement: 5,
      }
    },
    components: {
      Loading
    },
    props: {
      accountId: String,
      accountType: {
        type: String,
        default: 'user'
      },
      accountTag: {
        type: String,
        default: 'primary'
      },
      title: String,
    },
    watch: {
      accountId: {
        // call it upon creation too
        immediate: true,
        handler: async function () {
          this.bind(this.rawAccountId)
        }
      }
    },
    methods: {
      showMore() {
        this.transactionLimit += this.transactionLimitIncrement
      },
      reset() {
        this.edited = false
        this.bind(this.rawAccountId)
      },
      bind: async function(id) {
        this.loadError = null
        if (id === '$//newaccount') {
          this.isNew = true
          this.transactions = []
          return
        }
        if (id) {
          const matches = id.match(/(user|system)\((primary|funding):(.*)\)/)
          if (matches) {
            if (!matches[3]) return
          } else {
            return
          }
          if (this.loading) return
          this.loading = true
          try {
          
            const operation = this.$operationTracker.registerOperation(this, `api/token/list-transaction`)
            const { data } = await api.call('api/token/list-transaction', { id, raw_account: true });
            if (operation.isCanceled()) return

            if (Array.isArray(data)) {
              this.transactions = data.map(txo => {
                //use txo.data
                return {
                  ...txo.data,
                  ...txo.id
                }
              }).filter(tx => tx)
              this.transactions.sort((a, b) => {
                return b.timestamp - a.timestamp
              })
            } else {
              throw new Error('Invalid response')
            }
          } catch(e) {
            //Ok
            this.loadError = e
            console.error(e)
          } finally {
            this.loading = false
          }
        } else {
          //not bind
        }
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        return moment(value).format('DD.MM.YYYY HH:mm')
      }
    },
    computed: {
      transactionType() {
        return transaction => {
          if (transaction.from === transaction.account_id) {
            return 'OUT'
          } else if (transaction.to === transaction.account_id) {
            return 'IN'
          } else {
            return 'UNKNOWN'
          }
        }
      },

      //light background for transaction list group item
      classForType() {
        return transaction => {
          if (transaction.from === transaction.account_id) {
            return 'transaction-bg-expense'
          } else if (transaction.to === transaction.account_id) {
            return 'transaction-bg-income'
          } else {
            return ''
          }
        }
      },
      transactionLimited() {
        return this.transactions.slice(0, this.transactionLimit)
      },
      rawAccountId: function() {
        return `${this.accountType}(${this.accountTag}:${this.accountId})`
      },
    }
  }

  </script>