import axios from 'axios'
import api from './api'


const TARGET_NAME = process.env.VUE_APP_TARGET_NAME || 'shmuzy';
const linksConfig = require(`./configs/${TARGET_NAME}.js`).default.links

const longLink = (link, social) => {
  const linkStart = encodeURIComponent(`${linksConfig.linkBase}/${link}`)
  const androidStuff = `&apn=${encodeURIComponent(linksConfig.androidAppId)}`
  const appleStuff = `&ibi=${encodeURIComponent(linksConfig.iOSBundleId)}&isi=${encodeURIComponent(linksConfig.iOSStoreId)}`
  let socialStuff = ''
  if (social) {
    if (social.title) {
      socialStuff += `&st=${encodeURIComponent(social.title)}`
    }
    if (social.description) {
      socialStuff += `&sd=${encodeURIComponent(social.description)}`
    }
    if (social.image) {
      socialStuff += `&si=${encodeURIComponent(social.image)}`
    }
  }
  return `${linksConfig.linkPrefix}/?link=${linkStart}${androidStuff}${appleStuff}${socialStuff}`
}

const shortLinkFirebase = async (link, social, short) => {
  const URL = ''; // `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`
  let longDynamicLink = longLink(link, social)
  let resp = await axios.post(URL, JSON.stringify({
    'longDynamicLink': longDynamicLink,
    'suffix': {
       'option': short && 'SHORT'|| 'UNGUESSABLE'
     }
  }), {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  console.log(resp)
  return String(resp.data.shortLink)
}



let _branchSecret = null
const getBranchSecret = async () => {
  if (!_branchSecret) {
    let res = await api.call('links/get_secret')
    _branchSecret = res.data.branch_secret
  }
  return _branchSecret
}

const fillBranchSocial = (params, social) => {
  if (social.title) {
    params.data["$og_title"] = social.title
    params.data["$marketing_title"] = social.title
  }

  if (social.description) {
    params.data["$og_description"] = social.description
  }
  if (social.image) {
    params.data["$og_image_url"] = social.image
  } else {
    params.data["$og_image_url"] = "https://shmuzy.com/assets/img/playstore.png"
    params.data["no_group_image"] = true
  }
  if (social.background) {
    params.data["background_image"] = social.background
  }
  if (social.header_background) {
    params.data["header_background"] = social.header_background
  }
  if (social.channel_type) {
    params.data["channel_type"] = social.channel_type
  }
}

const updateLinkBranch = async (url, link, social, short) => {
  const URL = `https://api2.branch.io/v1/url?url=${encodeURIComponent(url)}`
  let branch_secret = await getBranchSecret()

  const params = {
    "branch_key": linksConfig.branch_key,
    "branch_secret": branch_secret,
    "data": {
      "$canonical_identifier": link,
      "$deeplink_path": link,
      "$publicly_indexable": short && true || false,
      "$ios_deepview": "shmuzy_deepview_custom",
      "$android_deepview": "false",
      "$desktop_deepview": "shmuzy_deepview_custom",
      "$ios_passive_deepview": "shmuzy_deepview_custom",
      "$android_passive_deepview": "shmuzy_deepview_custom"
    }
  }
  if (social) {
    fillBranchSocial(params, social)
  }
  let resp = await axios.put(URL, JSON.stringify(params), {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return resp.data.data && resp.data.data.url
}

const checkLinkBranch = async (url) => {
  const URL = `https://api2.branch.io/v1/url?url=${encodeURIComponent(url)}&branch_key=${linksConfig.branch_key}`
  try {
    let resp = await axios.get(URL)
    return resp.data.data.url === url
  } catch(err) {
    if (err.response.status === 404) {
      return false
    }
  }
}

const shortLinkBranch = async (link, social, short, alias) => {
  const URL = 'https://api2.branch.io/v1/url'
  const params = {
    "branch_key": linksConfig.branch_key,
    "data": {
      "$canonical_identifier": link,
      "$deeplink_path": link,
      "$publicly_indexable": short && true || false,
      "$ios_deepview": "false",
      "$android_deepview": "shmuzy_deepview_custom",
      "$desktop_deepview": "shmuzy_deepview_custom",
      "$ios_passive_deepview": "shmuzy_deepview_custom",
      "$android_passive_deepview": "shmuzy_deepview_custom"
    },
  }
  if (alias) {
    params['alias'] = alias
    params['type'] = 2
  }
  if (social) {
    fillBranchSocial(params, social)
  }
  let resp = await axios.post(URL, JSON.stringify(params), {
    headers: {
      'Content-Type': 'application/json',
    }
  })
  return resp.data.url
}

const shortLink = shortLinkBranch

export default {
  longLink: longLink,
  shortLink: shortLink,
  shortLinkFirebase: shortLinkFirebase,
  shortLinkBranch: shortLinkBranch,
  updateLinkBranch: updateLinkBranch,
  checkLinkBranch:checkLinkBranch
}
