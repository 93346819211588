<template>
  <div class="forumlist w-100">
    <div class="loader vld-parent text-center" v-if="loading">
      <loading :active="loading"
      :height='64'
      :width='64'
      :is-full-page="false"></loading>
    </div>
    <b-card v-else no-body>
      <b-list-group>
        <b-list-group-item class='text-center' v-for="forum in forums" :key="forum.id" :active='forumId===forum.id' @click='selectForum(forum.id)' button>
          <div>{{forum.name}}</div>
        </b-list-group-item>
        <b-list-group-item v-if='forums.length===0' class='text-center'>
          <div>No forums</div>
        </b-list-group-item>  
      </b-list-group>
    </b-card>
  </div>
</template>


<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)

</style>



<script>
import api from '@/api.js' 
import Loading from 'vue-loading-overlay'
export default {
    name: 'ForumSimpleList',
    data() {
      return {
      }
    },
    props: {
      forums: {
        type: Array,
        default: () => []
      },
      forumId: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    mounted() {
      
    },
    beforeDestoy() {
    
    },
    watch: {
    
    },
    methods: {
      selectForum(forumId) {
       this.$emit('update:forumId', forumId)
      }
    },
    computed: {
    },
    components: {
      Loading
    },
    filters: {
      avatary(text) {
        if (!text) return ''
        const s = text.trim()
        const sp = s.split(' ')
        if (sp.length > 1) {
          const s1 = sp[0]
          const s2 = sp[1]
          const l1 = (s1.length > 2 ? s1.substring(0, 1) : s1).toUpperCase()
          const l2 = (s2.length > 2 ? s2.substring(0, 1) : s2).toUpperCase()
          return `${l1}${l2}`
        } else {
          return (s.length > 1 ? s.substring(0, 1) : s).toUpperCase()
        }
      },
      getImage(item) {
        return api.imageURL(item.image)
      }
    }
  }
</script>
