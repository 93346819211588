<template>
  <div class="stats">
    <loading
      :active.sync="modalLoading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <b-container class="mt-2" v-if="loading" fluid>
      <div class="loader vld-parent text-center" >
        <loading :active.sync="loading" :height="64" :width="64" :is-full-page="false"></loading>
      </div>
    </b-container>
    <b-container class="mt-2" v-else fluid>
      <div class="top-controls-card mb-3">
        <div class="card">
          <div class="card-body">
            <b-button @click="loadStats" class="mb-2 mr-sm-2">Reload</b-button>
            <!-- Add additional controls here -->
          </div>
        </div>
      </div>
      <div class="mb-2" v-if="result">
        <b-card border-variant="primary" class="mb-3">
          <template #header>
            <b>Users</b>
          </template>
          <b-table :items="result.allUsers" :fields="userFields" :tbody-tr-class="rowBgClass"></b-table>
          <!--total-->
          <div class="mt-2">
            <b>Total MDM Users: {{ totalMdmUsers }}</b>
          </div>
        </b-card>

        <!-- Filters Card -->
        <b-card border-variant="secondary" class="mb-3">
          <template #header>
            <b>Filters</b>
          </template>
          <b-table :items="result.allFiltering" :fields="filterFields" :tbody-tr-class="rowBgClass"></b-table>
        </b-card>
      </div>
    </b-container>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
  .used-row
    background-color: #d4edda

  .not-used-row
    background-color: #f8d7da
</style>



<script>
// @ is an alias to /src


import Loading from 'vue-loading-overlay'

import api from '@/api.js'
//import moment from 'moment-timezone'



export default {
  name: 'MdmReport',
  data() {
    return {
      modalLoading: false,
      loading: false,
      result: {},
      userFields: [
        {
          key: '_id',
          label: 'Department Name',
          formatter: value => value || 'Unknown Department'
        },
        { key: 'count', label: 'Count' },
        {
          key: 'used',
          label: 'Used',
          formatter: value => value ? 'Have Filter' : 'No Filter'
        }
      ],
      // Define the fields for the Filters table
      filterFields: [
        {
          key: '_id',
          label: 'Department Name',
          formatter: value => value || 'Unknown Department'
        },
        { key: 'count', label: 'Count', formatter: value => `${value}${value > 1 ? ' (❗)' : ''}` },
        {
          key: 'used',
          label: 'Used',
          formatter: value => value ? 'Have Users' : 'No users'
        }
      ]
    }
  },
  methods: {
    async loadStats() {
      this.loading = true
      try {
        const { data } = await api.call('api/stats/mdm_report', {})
        this.result = data.result
        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    rowBgClass(item) {
      return item.used ? 'used-row' : 'not-used-row';
    }
  },
  mounted() {
    this.loadStats()
  },
  computed: {
    totalMdmUsers() {
      return this.result.allUsers.reduce((acc, cur) => acc + cur.count, 0)
    }
  },
  components: {
    Loading
  },
}
</script>