export default {
    algolia: {
        appId: 'CCBGJA0ZU3',
        appKey: 'e9ab4a3de3cf0a7047aa3a76a75448ec'
    },
    links: {
        'iOSBundleId': 'com.shmuzy.inc.app.dev',
        'iOSStoreId': '1493026474',
        'androidAppId': 'com.shmuzy.core.dev',
        'linkPrefix': 'https://shmuzy.page.link',
        'linkBase': 'https://shmuzy.com',
        'branch_key': 'key_test_emV7HQ53OrZ4HFOReA6bObhizCmYTpZc'
    },
    backend: {
        apiUrl: "https://ecmi4ixupd.execute-api.us-west-2.amazonaws.com/v1/",
        s3Prefix: "https://shmuzy-stage-media.s3.us-west-2.amazonaws.com/",
    }
}
