<template>
  <div class="feedparser">
    <loading :active.sync="modalAction"
        :can-cancel="false"
        :is-full-page="true"></loading>
    <b-modal size='lg' ref="parser-editor-modal" hide-footer title="Setup parser">
      <div class="d-block text-center">
         <b-input-group class='mb-2'>
            <b-input-group-prepend is-text>
              Parser:
            </b-input-group-prepend>
            <b-form-select v-model="editingParser.parser" @input="update(editingParser, 'parser', $event)" :options="availParsersOptions">
              <template v-slot:first>
                <option :value="null" disabled>-- Please select a parser --</option>
                <optgroup label="RSS">
                  <option :value="'user_rss'"> User RSS parser</option>
                  <option :value="'universe_rss'"> Universal RSS parser</option>
                  <option :value="'listennotes'"> ListenNotes parser</option>
                  <option :value="'libsyn'"> Libsyn parser</option>
                </optgroup>
              </template>
            </b-form-select>
          </b-input-group>
          <div v-if='Object.keys(currentParserArgs).length !== 0'>
            <div><span class="text-muted">Arguments</span></div>
            <div v-if='currentParserArgs.url_single'>
              <b-form-group
                v-if="currentParserArgs.url"
                label-cols="2"
                description="Url to use"
                label="URL">
                <b-form-input
                  v-model="editingParser.args.url[0]"
                  placeholder="URL">
                </b-form-input>
              </b-form-group>
            </div>
            <div v-else>
              <b-form-group
                v-if="currentParserArgs.url"
                label-cols="2"
                description="List of urls to use"
                label="URL">
                <b-input-group v-for='(value, index) in editingParser.args.url' v-bind:key="index">
                  <b-form-input
                    v-model="editingParser.args.url[index]"
                  placeholder="URL"></b-form-input>
                  <b-input-group-append>
                    <b-button v-if='index === editingParser.args.url.length - 1' variant="success" @click.prevent='editingParser.args.url.push("")'>+</b-button>
                    <b-button v-if='index !== 0' variant="danger" @click.prevent='editingParser.args.url.splice(index, 1)'>-</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <b-form-group
              v-if="currentParserArgs.defaultImage"
              label-cols="2"
              description="Parser will use this image if post image is not found (optional)"
              label="Default image">
              <b-form-input
              v-model="editingParser.args.defaultImage"
              placeholder="Default image URL"></b-form-input>
            </b-form-group>
            <b-form-group
              v-if="currentParserArgs.useOriginalDate"
              label-cols="2"
              description="Use date from parser"
              label="Use Original Date">
              <b-form-checkbox
                v-model="editingParser.args.useOriginalDate"
                :value="true"
                :unchecked-value="false" inline>
                Original Date
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              v-if="currentParserArgs.startDate"
              label-cols="2"
              description="Parser will get posts with publication date newer then selected only (optional)"
              label="Start since">
              <b-input-group>
                <datetime v-model="argsParserStartDate" type='datetime'></datetime>
                <b-input-group-append>
                  <b-button size='sm' v-if='argsParserStartDate' variant="danger" @click.prevent='argsParserStartDate = null'>X</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
      </div>

      <b-row>
        <b-col cols="auto" class="mr-auto">
           <div>
            <b-button :disabled='!isParserValid' v-if='isParserChanged' class="mt-3" variant="warning" @click="saveEditParser">Swap</b-button>
            <b-button :disabled='!isParserValid' v-else class="mt-3" variant="success" @click="saveEditParser">Save</b-button>
           </div>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click='$refs["parser-editor-modal"].hide()'>Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-modal ref="parser-delete-confirm" hide-footer title="Confirm deletion">
      <div class="d-block text-center">
        <h4>Are you sure to delete the parser?</h4>
      </div>
      <b-row>
        <b-col cols="auto" class="mr-auto">
           <b-button class="mt-3" variant="danger" @click="confirmDeleteParser">Delete</b-button>
        </b-col>
        <b-col cols="auto" align-self="end">
          <b-button class="mt-2" variant="secondary" @click='$refs["parser-delete-confirm"].hide()'>Cancel</b-button>
        </b-col>
       </b-row>
    </b-modal>
    <b-card v-if='!isNew && feedId'
        header-bg-variant="info"
        header-text-variant="white" no-body>
      <template v-slot:header>
         <b-row>
          <b-col cols="auto" class="mr-auto"><span class='align-middle'>{{ 'Feed parser' }}</span></b-col>
          <b-col cols="auto" align-self="end">
          </b-col>
         </b-row>
      </template>
      <div class="loader vld-parent text-center" v-if="loading">
        <loading :active.sync="loading"
        :height='64'
        :width='64'
        :is-full-page="false"></loading>
      </div>
      <b-card-body v-else>
        <b-form>
          <b-input-group class='mb-2' v-if='parser && parser.parser'>
            <b-input-group-prepend is-text>
              Current errors:
            </b-input-group-prepend>
            <b-form-input
              v-model="currentErrors"
              :readonly='true'
              placeholder="Errors"></b-form-input>
            <b-input-group-append v-if='isError' is-text>
              <small class='text-danger'>Parser disabled!</small>
            </b-input-group-append>
            <b-input-group-append>
              <b-button :disabled='!isError && currentErrors <= 0' variant="primary" @click.prevent='resetParserError'>Reset</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class='mb-2' v-if='parser && parser.parser'>
            <b-input-group-prepend is-text>
              Total errors:
            </b-input-group-prepend>
            <b-form-input
              v-model="totalErrors"
              :readonly='true'
              placeholder="Errors"></b-form-input>
          </b-input-group>
          <b-input-group class='mb-2' v-if='parser && parser.parser'>
            <b-input-group-prepend is-text>
              Time group:
            </b-input-group-prepend>
            <b-form-select v-model="parserTimegroup" :options="timegroupOptions"></b-form-select>
          </b-input-group>
          <b-input-group class='mb-2' v-if='parser && parser.parser'>
            <b-input-group-prepend is-text>
              Last success:
            </b-input-group-prepend>
            <b-form-input
              v-model="lastSuccess"
              :readonly='true'
              placeholder="Last success"></b-form-input>
          </b-input-group>
          <b-input-group class='mb-2' v-if='parser && parser.parser'>
            <b-input-group-prepend is-text>
              Status:
            </b-input-group-prepend>
            <b-form-select :disabled='true' v-model="isDisabled" :options="[{value: false, text:'Enabled'}, {value: true, text:'Disabled'}]"></b-form-select>
            <b-input-group-append>
              <b-button :variant="isDisabled ? 'success' : 'danger'" @click.prevent='enableParser(isDisabled)'>{{isDisabled ? 'Enable' : 'Disable'}}</b-button>
            </b-input-group-append>
          </b-input-group>
          <b-input-group class='mb-2'>
            <b-input-group-prepend is-text>
              Parser:
            </b-input-group-prepend>
            <b-form-input
              v-model="parserName"
              :disabled='true'
              placeholder="Parser name"></b-form-input>
            <b-input-group-append>
              <b-button :variant="parser && parser.parser ? 'warning' : 'success'" @click.prevent='editParser()'>{{parser && parser.parser ? 'Edit' : 'Set'}}</b-button>
            </b-input-group-append>
            <b-input-group-append v-if='parser && parser.parser'>
              <b-button variant="danger" @click.prevent='removeParser()'>Remove</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form>
        <!-- {{ parser }} -->
      </b-card-body>
    </b-card>
  </div>
</template>

<style lang="sass">
  .loader
    margin: 6px 6px 6px 6px
    width: 64px
    height: 64px
    left: 50%
    transform: translateX(-50%)
</style>

<script>
  import { Datetime } from 'vue-datetime'
  // You need a specific loader for CSS files
  import 'vue-datetime/dist/vue-datetime.css'

  import moment from 'moment-timezone'
  import Loading from 'vue-loading-overlay'
  import api from '@/api.js'

  export default {
    name: 'FeedParser',
    props: {
      feedId: String
    },
    data() {
      return {
        loading: false,
        modalAction: false,
        isNew: false,
        ref: null,
        parser: {},
        editingParser: {
          parser: null,
          args: {
            url: [''],
            defaultImage: '',
            startDate: null
          }
        },
        timegroupOptions: [
          {text: "Every 30 min", value: "every_30"},
          {text: "Every 10 min", value: "every_10"},
          {text: "Every 5 min", value: "every_5"},
          {text: "Every 1 min", value: "every_1"}
        ],
        availParsersOptions: {},
        availParsers: {}
      }
    },
    watch: {
      feedId: {
        // call it upon creation too
        immediate: true,
        handler: async function (id) {
          this.isNew = false
          this.parser = {}
          this.bind(id)
        }
      }
    },
    methods: {
      bind: async function(id) {
        if (id === '$//newFeed') {
          try {
            await this.$rtdbUnbind('parser', () => ({}))
          } catch(e) {
            //Ok
          }
          this.ref = null
          this.isNew = true
          this.parser = {}
          return
        }
        if (id) {
          // this.ref = firebase.database().ref('feed_parsers').child(id)
          this.loading = true
          try {
            await this.$rtdbBind('parser', this.ref)
          } catch(e) {
            //Ok
          }
          this.loading = false
        } else {
          try {
            await this.$rtdbUnbind('parser', () => ({}))
          } catch(e) {
            //Ok
          }
        }
      },
      resetParserError: async function () {
        this.modalAction = true
        try {
          await this.ref.update({
            currentErrors: 0,
            error: false
          })
        } catch(e) {
          //ignore
        }
        this.modalAction = false
      },
      updateTimeGroup: async function (group) {
        this.modalAction = true
        try {
          await this.ref.update({
            group: group
          })
        } catch(e) {
          //ignore
        }
        this.modalAction = false
      },
      enableParser: async function (state) {
        this.modalAction = true
        try {
          await this.ref.update({
            disabled: !state
          })
        } catch(e) {
          //ignore
        }
        this.modalAction = false
      },
      editParser:  async function () {
        this.modalAction = true
        try {
          let res = await api.call('parser/available')
          this.modalAction = false
          let p = this.parser || {}
          let pa = p.args || {}
          Object.assign(this.editingParser, {
            parser: p.parser || null,
            args: {
              url: pa.url || [''],
              defaultImage: pa.defaultImage || '',
              startDate: pa.startDate || null,
              useOriginalDate: pa.useOriginalDate || true,
            }
          })
          if (!Array.isArray(this.editingParser.args.url)) {
            this.editingParser.args.url = [ this.editingParser.args.url ]
          }
          this.availParsersOptions = res.data.map((parser) => {
            return Object.assign(parser, {text: parser.name, value: parser.name});
          })
          this.availParsers = {}
          res.data.forEach(parser => {
            this.$set(this.availParsers, parser.name, parser)
          })
          this.$refs["parser-editor-modal"].show()
        } catch(e) {
          console.error(e)
          //ignore
        }
        this.modalAction = false

      },
      update: function(obj, prop, event) {
        this.$set(obj, prop, event);
      },
      removeParser: async function () {
        this.$refs["parser-delete-confirm"].show()
      },
      saveEditParser: async function () {
        this.$refs["parser-editor-modal"].hide()
        const p = this.parser && this.parser.parser
        const reset = !p || this.isParserChanged
        let updates = {
          parser: this.editingParser.parser,
          args: {}
        }
        const cp = this.currentParserArgs
        if (cp.url_single) {
          if (cp.url) {
            updates.args.url = this.editingParser.args.url[0]
          }
        } else {
          if (cp.url) {
            updates.args.url = this.editingParser.args.url
          }
        }
        if (cp.useOriginalDate) {
          updates.args.useOriginalDate = this.editingParser.args.useOriginalDate || false
        }
        if (cp.defaultImage) {
          updates.args.defaultImage = this.editingParser.args.defaultImage
        }
        if (cp.startDate) {
          updates.args.startDate = this.editingParser.args.startDate
        }
        updates.disabled = p && p.disabled || false
        this.modalAction = true
        if (reset) {
          updates.type = updates.parser === 'user_rss' ? "user" : "system"
          updates.totalErrors = 0
          updates.currentErrors = 0
          updates.error = false
          updates.created = moment().utc().valueOf()
          try {
            await this.ref.set(updates)
          } catch(e) {
            //ignore
          }
        } else {
          try {
            await this.ref.update(updates)
          } catch(e) {
            //ignore
          }
        }
        this.modalAction = false
        //console.log("call")
      },
      confirmDeleteParser: async function () {
        this.$refs["parser-delete-confirm"].hide()
        this.modalAction = true
        try {
          await this.ref.set(null)
        } catch(e) {
          //ignore
        }
        this.modalAction = false
      }
    },
    computed: {
      currentParserArgs () {
        return this.editingParser.parser && this.availParsers[this.editingParser.parser] && this.availParsers[this.editingParser.parser].args || {}
      },

      currentErrors (){
          return this.parser && this.parser.currentErrors || 0;
      },
      parserName (){
          return this.parser && this.parser.parser || "Not set";
      },
      lastSuccess (){
          let d = this.parser && this.parser.lastSuccess
          return d && moment(d).format('dddd, MMMM Do YYYY, h:mm:ss a') || 'Never'
      },
      isError (){
          return this.parser && this.parser.error || false;
      },
      parserTimegroup: {
        get() {
          return this.parser && this.parser.group || "every_30";
        },
        set(value) {
          this.updateTimeGroup(value)
        }
      },
      isDisabled: {
        get() {
          return this.parser && this.parser.disabled || false;
        },
        set() {
          //this.enable(!val)
        }
      },
      isParserChanged () {
        const p = this.parser && this.parser.parser
        return p && p !== (this.editingParser && this.editingParser.parser)
      },
      isParserValid () {
        if (!(this.editingParser && this.editingParser.parser)) {
          return false
        }
        const cp = this.currentParserArgs
        const ep = this.editingParser && this.editingParser.args || {}
        if (cp.url === 'required') {
          return ep.url && ep.url.length > 0 && ep.url.filter(r => r.trim().length === 0).length === 0
        }
        return true
      },
      argsParserStartDate: {
        get() {
          let d = this.editingParser && this.editingParser.args && this.editingParser.args.startDate
          return d && moment(d).utc().toISOString() || null
        },
        set(v) {
          this.editingParser.args.startDate = v && moment(v).valueOf() || null
        }
      },
      totalErrors (){
          return this.parser && this.parser.totalErrors || 0;
      }
    },
    components: {
      Loading, Datetime
    }
  }
</script>
