let uidCounter = 0;

const OperationTracker = {
  install(Vue) {
    Vue.mixin({
      beforeCreate() {
        this.uidCounter = uidCounter.toString();
        uidCounter += 1;
      },
    });

    Vue.prototype.$id = function(id) {
      return `uid-${this.uidCounter}-${id}`;
    };

    Vue.prototype.$operationTracker = {
      operations: new Map(),
      registerOperation(component, id) {
        const compositeId = component.$id(id);
        const counter = this.operations.get(compositeId) || 0;
        this.operations.set(compositeId, counter + 1);
        return {
          id: compositeId,
          counter: counter + 1,
          isCanceled: () => {
            return this.counter !== this.operations.get(this.id);
          },
        };
      },
      validateOperation(operation) {
        return operation.counter === this.operations.get(operation.id);
      },
    };
  },
};

export default OperationTracker;
