<template>
  <div id="app">
    <b-navbar type="dark" variant="dark" v-if="loggedIn" class="navbar">
      <b-navbar-nav v-if="isSuperadmin" class="nav-items">
        <b-nav-item to="/" class="nav-item">Home</b-nav-item>
        <b-nav-item to="/finance" class="nav-item">Finance</b-nav-item>
        <b-nav-item to="/users" class="nav-item">Users</b-nav-item>
        <b-nav-item to="/stats" class="nav-item">Stats</b-nav-item>
        <b-nav-item to="/mdm-report" class="nav-item">MDM-Report</b-nav-item>
        <b-nav-item to="/events" class="nav-item">Events</b-nav-item>
        <b-nav-item to="/feeds" class="nav-item">Feeds</b-nav-item>
        <b-nav-item to="/forums" class="nav-item">Forums</b-nav-item>
        <b-nav-item to="/forums-ext" class="nav-item">Forums Ext</b-nav-item>
        <b-nav-item to="/filters" class="nav-item">Filters</b-nav-item>
        <b-nav-item to="/advanced-filters" class="nav-item">Adv Filters</b-nav-item>
        <b-nav-item to="/report_messages" class="nav-item">Message Reports</b-nav-item>
        <b-nav-item to="/report_feeds" class="nav-item">Feeds Report</b-nav-item>
        <b-nav-item to="/maintenance" class="nav-item">Maintenance</b-nav-item>
        <b-nav-item to="/about" class="nav-item">About</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isFiltering" class="nav-items">
        <b-nav-item to="/filters" class="nav-item">Filters</b-nav-item>
        <b-nav-item to="/advanced-filters" class="nav-item">Adv Filters</b-nav-item> 
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item right>
          <b-button variant='danger' size='sm' @click='logout' class="logout-btn">Log out</b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <router-view/>
  </div>
</template>

<style scoped>
  .navbar {
    font-size: 1rem;
    font-weight: 600;
  }

  .nav-items {
    margin-left: 1rem;
  }

  .nav-item {
    margin-right: 1rem;
  }

  .logout-btn {
    border-radius: 0.5rem;
    background-color: #dc3545;
    border-color: #dc3545;
  }
</style>

<script>
// @ is an alias to /src
import EventBus from '@/event-bus.js'
import api from "./api";

export default {
  name: 'App',
  data () {
    return {}
  },
  mounted () {
    if (EventBus.token === null) {
      this.$router.push('/login')
    }
  },
  methods: {
    logout() {
      api.logout()
    }
  },
  computed: {
    loggedIn() {
      return EventBus.token !== null
    },
    isSuperadmin() {
      return EventBus.isSuperadmin
    },
    isFiltering() {
      return EventBus.isFiltering
    },
  }
}
</script>
