<template>
  <div class="home">
    <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"></loading>
     <b-container>
        <b-jumbotron class='text-center jumbotron' :header="isYeshiva ? 'Duvchat' : 'Shmuzy'" lead="It is admin page">
          <p>To login use our App</p>
          <b-btn variant="primary" :href="isYeshiva ? 'https://duvchat.com' : 'https://shmuzy.com'" target="_blank">More Info</b-btn>
        </b-jumbotron>
        <b-alert dismissible @dismissed="alert=null" variant="danger" :show="alert">{{alert}}</b-alert>
        <b-form class='form-wrapper'>
          <b-form-group
            horizontal
            :label-cols="4"
            description="Provide email for login"
            label="Email">
            <b-form-input autocomplete="username" type='email' v-model.trim="email" class="form-input"></b-form-input>
          </b-form-group>
          <b-form-group
            horizontal
            :label-cols="4"
            description="Enter your password"
            label="Password">
            <b-form-input autocomplete="current-password" type='password' v-model.trim="password" class="form-input"></b-form-input>
          </b-form-group>
          <b-form-group class='text-right'>
            <b-button :disabled='!email || !password' variant="primary" @click.prevent='login' class="login-btn">Login</b-button>
          </b-form-group>
        </b-form>
      </b-container>
  </div>
</template>

<style scoped>
  .jumbotron {
    background-color: #f8f9fa;
    border-radius: 1rem;
    padding: 2rem 1rem;
    margin-bottom: 2rem;
  }

  .form-wrapper {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .form-input {
    border-radius: 0.5rem;
  }

  .login-btn {
    border-radius: 0.5rem;
    background-color: #007bff;
    border-color: #007bff;
    font-weight: 600;
  }
</style>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import api from '@/api.js'

export default {
  name: 'login',
  data () {
    const TARGET_NAME = process.env.VUE_APP_TARGET_NAME
    const isYeshiva = TARGET_NAME === 'yeshiva'
    return {
      isYeshiva,
      email:'',
      password: '',
      alert:null,
      isLoading: false
    }
  },
  methods: {
    login: async function () {
      this.isLoading = true
      try {
        await api.login(this.email, this.password)
      } catch(e) {
        console.error(e)
        this.alert = e.message
      }
      this.isLoading = false
    }
  },
  components: {
      Loading
  },
}
</script>
