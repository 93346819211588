export default {
  type_2_channel: (type) => {
    switch(type) {
      case 'feed': return 'feed'
      case 'forum': return 'fourm'
      case 'group': return 'channel'
      default: return ''
    }
  },
  channel_2_conversation: (chan) => {
    switch(chan) {
      case 'feed': return 'feed_tweet'
      case 'fourm': return 'fourmconver'
      case 'channel': return 'conversations'
      default: return ''
    }
  },
  channel_2_comment: (chan) => {
    switch(chan) {
      case 'feed': return 'feed_tweet_comments'
      case 'fourm': return 'forum_comments'
      default: return ''
    }
  }
}